<div class="pane-list" [class.details]="!router.isActive('/customers', true)">
    <dr-spinner [isRunning]="loading"></dr-spinner>
    <div class="list-container list">
        <table class="item-table">
            <thead>
                <tr>
                    <th>Navn</th>
                    <th>E-mail</th>
                    <th>Loginnavn</th>
                    <th>Adresse</th>
                    <th>Telefon/Mobil</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let customer of customers"
                    [class.selected]="router.isActive('/customers/' + customer.id, true)"
                    (click)="onSelect(customer)">
                    <td class="item-header" data-label="Navn">
                        {{customer.fullName}}<br/>
                        <small>{{customer.customerCompanyName}}</small>
                    </td>
                    <td data-label="E-mail">
                        {{customer.email}}
                    </td>
                    <td data-label="Loginnavn">
                        {{customer.userName}}
                    </td>
                    <td data-label="Adresse">
                        {{customer.address.street}}
                        <br />
                        {{customer.address.zip}} {{customer.address.city}}
                    </td>
                    <td data-label="Telefon/Mobil">
                        {{customer.cellphoneNumber}}
                        <br *ngIf="customer.phoneNumber" />
                        {{customer.phoneNumber}}
                    </td>
                </tr>
            </tbody>
        </table>



    </div>

    <div class="detail-container">
        <div class="detailpanel">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
