import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, catchError, of, switchMap, throwError } from 'rxjs';
import { AuthenticationService } from '../../services/auth/authentication.service';

@Component({
  selector: 'app-forgotten-password',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './forgotten-password.component.html',
  styleUrl: './forgotten-password.component.scss'
})
export class ForgottenPasswordComponent implements OnInit {
  email!: string;
  error!: string;
  submitted!: boolean;
  loading!: boolean;

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private authenticationService: AuthenticationService) { }

  ngOnInit() {
  }

  
  submit() {
    this.loading = true;

    this.resetPassword().subscribe({
      next: (submitted: boolean) => {
        this.submitted = submitted;
        this.loading = false;
      },
      error: (err: any) => {
        this.error = 'Fejl ved nulstilling af adgangskode. Prøv igen senere.';
        this.loading = false;
      }
    });
    // this.authenticationService.requestPasswordReset(this.email).then(r => { this.submitted = r; this.loading = false; });
  }

  private resetPassword(): Observable<boolean> {
    console.log("this.email: ", this.email);
    
    return this.authenticationService.requestPasswordReset(this.email).pipe(
      switchMap((submitted: boolean) => {
        return submitted ? of(submitted) : throwError(new Error('Password reset request failed.'));
      }),
      catchError((error: any) => {
        this.error = 'Fejl ved nulstilling af adgangskode. Prøv igen senere.';
        return of(false);
      })
    );
  }  
}
