import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ConnectionState } from '../enums/connection-state';
import { EventNotification } from '../interfaces/event-notification';
import { HubConfig } from '../models/hub-config';
import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private hubConnection!: HubConnection;

  private notificationSubject: Subject<EventNotification<any, any>> = new Subject<EventNotification<any, any>>();
  public notifications = this.notificationSubject.asObservable();
  
  private connectionStateSubject = new Subject<ConnectionState>();
  public connectionState$ = this.connectionStateSubject.asObservable();

  constructor(
    @Inject('hub.config') private hubConfig: HubConfig) {
    this.connectionStateSubject.next(ConnectionState.Disconnected);
  }

  startSignalRConnection() {
    // Retrieve the token
    const token: string | null = localStorage.getItem('currentUserToken');
  
    if (!token) {
      console.log("No access token found, SignalR connection will not start.");
      return;
    }

    // Initialize the SignalR connection
    console.log("Building Connection");  
    if (!environment.production && !environment.staging) {
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(this.hubConfig.url, {
          transport: HttpTransportType.LongPolling, 
          accessTokenFactory: () => token 
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();
    } else {
      this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.hubConfig.url, {
        accessTokenFactory: () => token 
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();    
    }
  
    // Start the SignalR connection
    console.log("Starting....");
    this.connectionStateSubject.next(ConnectionState.Connecting); 
    this.hubConnection
      .start()
      .then(() => {
        console.log('SignalR Connected');
        this.connectionStateSubject.next(ConnectionState.Connected);
      })
      .catch(err => {
        console.error('SignalR Connection Error:', err);
        this.connectionStateSubject.next(ConnectionState.Disconnected);
      });
  
    // Handle reconnecting event
    this.hubConnection.onreconnecting(err => {
      console.log('SignalR Reconnecting...', err);
      this.connectionStateSubject.next(ConnectionState.Disconnected);
    });
  
    // Handle reconnected event
    this.hubConnection.onreconnected(() => {
      console.log('SignalR Reconnected');
      this.connectionStateSubject.next(ConnectionState.Connected);
    });
  
    // Listen for notifications (assuming `Publish` is the correct event name)
    this.hubConnection.on('notify', (notification: EventNotification<any, any>) => {
      this.notificationSubject.next(notification);
    });
  }
  
  public closeSignalRConnection() {
    if (this.hubConnection) {
      this.hubConnection.stop()
        .then(() => {
          console.log('disconnected');
          this.connectionStateSubject.next(ConnectionState.Disconnected);
        });
    }
  }

  test(): void {
    this.hubConnection.invoke('test').catch(err => console.error(err));
  }

}
