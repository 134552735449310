
<div class="newcasedetails">
    <div class="panelyellow">
        <h1>Rediger assistancerapport</h1>
    </div>
    <report-assistance [case]="case" mode="edit" *ngIf="reportTypeAlias == 'assistance'"
                       [reportId]="reportId"
                       (saved)="onReportSaved($event)" (cancelled)="onReportCancelled($event)"></report-assistance>
    <report-storm [case]="case" mode="edit" *ngIf="reportTypeAlias == 'storm'"
                  [reportId]="reportId"
                  (saved)="onReportSaved($event)" (cancelled)="onReportCancelled($event)"></report-storm>
    <report-cloudburst [case]="case" mode="edit" *ngIf="reportTypeAlias == 'cloudburst'"
                       [reportId]="reportId"
                       (saved)="onReportSaved($event)" (cancelled)="onReportCancelled($event)"></report-cloudburst>

    <div *ngIf="report" class="user-details">
        <div class="created-by">
            <avatar [user]="report.createdBy" size="small" title="Oprettet af"></avatar>
            <span *ngIf="!report.createdBy" class="label">Oprettet</span>
            <span class="date">{{ report.created.toString() | moment: 'DD-MM-YY HH:mm' }}</span>
        </div>
        <div class="updated-by" *ngIf="report.created !== report.updated">
            <avatar [user]="report.updatedBy" size="small" title="Opdateret af"></avatar>
            <span *ngIf="!report.updatedBy" class="label">Opdateret</span>
            <span class="date">{{ report.updated.toString() | moment: 'DD-MM-YY HH:mm' }}</span>
        </div>       
    </div>
</div>
