import { EventEmitter, Injectable } from '@angular/core';
import { Customer } from '../models/customer';
import { Employee } from '../models/employee';
import { ApiResult } from '../models/api-result';
import { ServiceBase } from './base/service-base.service';
import { AlertService } from './alert.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService extends ServiceBase {
  private listurl = '/api/companyusers/';
  private createcustomerurl = 'api/admin/customer/create';
  private updatecustomerurl = 'api/admin/customer/update';

  customerUpdated: EventEmitter<Customer>;

  constructor(authhttp: HttpClient, alertService: AlertService) {
      super(authhttp);
      this.customerUpdated = new EventEmitter<Customer>();
  }

  getCustomers(): Observable<Customer[]> {
    return this.getJson(this.listurl + 'customers')
    //   return this.http.get(this.listurl + 'customers')
    //       .toPromise()
    //       .then(response => response.json() as Customer[])
    //       .catch(this.handleError);
  }

  getEmployees(): Observable<Employee[]> {
    return this.getJson(this.listurl + 'employees');
    //   return this.http.get(this.listurl + 'employees')
    //       .toPromise()
    //       .then(response => response.json() as Employee[])
    //       .catch(this.handleError);
  }

  getCustomer(customerId: string | number | boolean): Observable<Customer> {
    return this.getJson(this.listurl + 'getcustomer/' + encodeURIComponent(customerId));
    //   return this.http.get(this.listurl + 'getcustomer/' + encodeURIComponent(customerId))
    //       .toPromise()
    //       .then(response => response.json() as Customer)
    //       .catch(this.handleError);
  }

  create(customer: Customer): Observable<ApiResult<Customer>> {
    console.log("Create Customer API Call", customer);

    return this.postJson(this.createcustomerurl, customer)

    //   let jsonData = JSON.stringify(customer);
    //   return this.http
    //       .post(this.createcustomerurl, jsonData)
    //       .toPromise()
    //       .then(res => res.json() as ApiResult<Customer>)
    //       .then(c => { this.customerUpdated.emit(c.result); return c; })
    //       .catch(this.handleError);
  }

  //registerCustomer(customer: Customer): Promise<Customer> {
  //    return this.postJson("/api/register/customer", customer);
  //}

  update(customer: Customer): Observable<ApiResult<Customer>> {
    console.log("Customer to UPDATE: ", customer);
    return this.postJson(this.updatecustomerurl, customer);
    //   let jsonData = JSON.stringify(customer);
    //   return this.http
    //       .post(this.updatecustomerurl, jsonData)
    //       .toPromise()
    //       .then(res => res.json() as Customer)
    //       .then(c => { this.customerUpdated.emit(c); return c; })
    //       .catch(this.handleError);
  }

  //isUsernameAvailable(username: string): Observable<boolean> {
  //    let data = {
  //        username: username
  //    };
  //    return this.postJsonObservable<boolean>("/api/register/checkusername", data);
  //}
}
