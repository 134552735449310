import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AuthenticationService } from '../../services/auth/authentication.service';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent implements OnInit {
  token!: string;
  userId!: string;

  password!: string;
  confirmpassword!: string;

  submitted!: boolean;
error: any;

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.route
    .queryParams
    .subscribe(params => {
       this.token = params['token'] || null;
       this.userId = params['id'] || null;
    });
  }

  submit() {
    this.authenticationService.resetPassword(this.userId, this.password, this.token)
    .subscribe(() => {
        this.submitted = true;
    });    

    // this.authenticationService.resetPassword(this.userId, this.password, this.token).then(r => this.submitted = true);
  }
}
