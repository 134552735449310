import { Component, ContentChildren, ElementRef, HostListener, Inject, Input, QueryList } from '@angular/core';
import { slideUpDown } from '../../../animations/animations';
import { DropdownItemComponent } from '../dropdown-item/dropdown-item.component';
import { CommonModule, DOCUMENT } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'menu-dropdown',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './menu-dropdown.component.html',
  styleUrl: './menu-dropdown.component.scss',
  animations: [slideUpDown]
})
export class MenuDropdownComponent {
  @ContentChildren(DropdownItemComponent) items!: QueryList<DropdownItemComponent>;
  @Input() dropdownText: string = "Menu";

  opened: boolean = false;

  constructor(
    private el: ElementRef,
    @Inject(DOCUMENT) private document: Document) {
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.opened || !event.target) {
      return;
    }
    if (this.el.nativeElement !== event.target && !this.el.nativeElement.contains(event.target)) {
      this.close();
    }
  }

  open() {
    this.opened = true;
  }

  close() {
    this.opened = false;
  }

  toggle() {
    this.opened = !this.opened;
  }

  invokeItemClick(item: DropdownItemComponent, event: MouseEvent) {
    event.preventDefault();
    item.onMenuItemClick.emit();
    this.close();
  }
}
