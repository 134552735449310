import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SampleItemRoom } from '../interfaces/sample-item-room';
import { SampleItem } from '../interfaces/sample-item';
import { ServiceBase } from './base/service-base.service';
import { AlertService } from './alert.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SampleItemsService  extends ServiceBase {
  private rooturl = '/api/sampleitem/';
  private roomurl = '/api/sampleitemroom/';
  //private roomurl = this.rooturl + 'room';

  constructor(authhttp: HttpClient, alert: AlertService) {
      super(authhttp);
  }

  listRooms(): Observable<SampleItemRoom[]> {
      return this.getJson<SampleItemRoom[]>(this.roomurl);
  }

  createRoom(name: string): Observable<SampleItemRoom> {
      return this.putJson<SampleItemRoom>(this.roomurl, { name: name, items: [] });
  }

  updateRoom(room: SampleItemRoom): Observable<SampleItemRoom> {
      return this.patchJson<SampleItemRoom>(this.roomurl, { id: room.id, name: room.name });
  }

  deleteRoom(room: SampleItemRoom): Observable<SampleItemRoom> {
      return this.deleteJson(this.roomurl + room.id);
  }

  create(name: string, roomId: number): Observable<SampleItem> {
      return this.postJson<SampleItem>(this.rooturl, { name: name, roomId: roomId });
  }

  update(item: SampleItem): Observable<SampleItem> {
      return this.patchJson<SampleItem>(this.rooturl, item);
  }

  delete(item: SampleItem): Observable<SampleItem> {
      return this.deleteJson(this.rooturl + item.id);
  }
}
