import { Component, OnDestroy, OnInit } from '@angular/core';
import { EquipmentType } from '../../../interfaces/equipment/equipment-type';
import { Equipment } from '../../../interfaces/equipment/equipment';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription, debounceTime, distinctUntilChanged, from, map, switchMap } from 'rxjs';
import { EquipmentService } from '../../../services/equipment/equipment.service';
import { NotificationService } from '../../../services/notification.service';
import { EventNotification } from '../../../interfaces/event-notification';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-place-equipment',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatDialogModule],
  templateUrl: './place-equipment.component.html',
  styleUrl: './place-equipment.component.scss'
})
export class PlaceEquipmentComponent implements OnInit, OnDestroy {

  types!: EquipmentType[];
  selectedType: EquipmentType | null = null;

  equipment!: Equipment[];
  selectedEquipment!: Equipment;

  search = new FormControl();
  private ns: Subscription;

  constructor(
    public dialogRef: MatDialogRef<PlaceEquipmentComponent>,
    private equipmentService: EquipmentService,
    private notificationService: NotificationService) {
        this.ns = notificationService.notifications
            .pipe(
                map(n => <EventNotification<Equipment, EquipmentType>>n),
                // You can uncomment this line if you want to filter notifications
                //filter(n => n.context && n.context.id === this.typeid)
            )
            .subscribe(n => {
                if (n.Type === "equipmentStatusChanged") {
                    const item: Equipment | undefined = this.equipment.find(x => x.id === n.Data.id);

                    if (item) {
                        Object.assign(item, n.Data);
                    }
                }
            });

        this.search.valueChanges
            .pipe(
                debounceTime(400),
                distinctUntilChanged(),
                switchMap(q => {
                    if (this.selectedType) {
                        return from(this.equipmentService.getForType(this.selectedType.id!, q));
                    } else {
                        return from(this.equipmentService.getAll(q));
                    }
                })
            )
            .subscribe(r => {
                this.equipment = r;
            });
  }

  ngOnInit(): void {
      this.equipmentService.getTypes()
        .subscribe(r => {
          this.types = r.filter(x => x.trackIndividually);
        });

      this.loadEquipment();
  }

  ngOnDestroy(): void {
      if (this.ns) {
          this.ns.unsubscribe();
      }
  }

  loadEquipment() {
      if (this.selectedType) {
          // this.selectedEquipment = null;
          this.equipmentService.getForType(this.selectedType.id!, this.search.value)
            .subscribe((r:Equipment[]) => {
              this.equipment = r;
            });
      }
      else {
        this.equipmentService.getAll(this.search.value)
        .subscribe((r: Equipment[]) => {
            this.equipment = r;
        });
      }
  }

  submit() {
    this.dialogRef.close(this.selectedEquipment);
  }

  select(e: Equipment) {
    if (e.failed || e.isActive) {
        return;
    }

    this.selectedEquipment = e;
  }
}
