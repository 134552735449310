import { Component, Input, OnInit } from '@angular/core';
import { EquipmentType } from '../../../interfaces/equipment/equipment-type';
import { UntrackedEquipmentCount } from '../../../interfaces/equipment/untracked-equipment-count';
import { DamageCase } from '../../../models/damage-case';
import { EquipmentService } from '../../../services/equipment/equipment.service';
import { NotificationService } from '../../../services/notification.service';
import { EventNotification } from '../../../interfaces/event-notification';
import { filter } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AlertDialogComponent } from '../../ui/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'untracked-equipment',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './untracked-equipment.component.html',
  styleUrl: './untracked-equipment.component.scss'
})
export class UntrackedEquipmentComponent implements OnInit {
  @Input() case!: DamageCase;

  type!: EquipmentType;
  count!: UntrackedEquipmentCount;
  newAmount!: number;

  constructor(
      private equipmentService: EquipmentService,
      public dialog: MatDialog,
      private notificationService: NotificationService
  ) {
    notificationService.notifications
    .pipe(
      filter(n => n.Type === "untrackedEquipmentChanged" || n.Type === "equipmentCountUpdated")
    )
    .subscribe(n => {
      if (n.Type === "untrackedEquipmentChanged") {
        let v = <EventNotification<UntrackedEquipmentCount[], DamageCase>>n;
        if (v.Context.id === this.case.id) {
          this.setData(v.Data);
        }
      }
      if (n.Type === "equipmentCountUpdated") {
        let v = <EventNotification<EquipmentType[], any>>n;
        // Find the matching type
        var t = v.Data.find(x => x.id == this.type.id);
        if (t) {
          Object.assign(this.type, t);
        }
      }
    });

      // notificationService.notifications
      //     .subscribe(n => {
      //         if (n.type === "untrackedEquipmentChanged") {
      //             let v = <EventNotification<UntrackedEquipmentCount[], DamageCase>>n;
      //             if (v.context.id === this.case.id) {
      //                 this.setData(v.data);
      //             }
      //         }
      //         if (n.type === "equipmentCountUpdated") {
      //             let v = <EventNotification<EquipmentType[], any>>n;
      //             //Find the matching type
      //             var t = v.data.find(x => x.id == this.type.id)
      //             if (t) {
      //                 Object.assign(this.type, t);
      //             }
      //         }
      //     });
  }

  ngOnInit(): void {
    this.loadData();
    
    // this.equipmentService.getTypes()
    // .subscribe((equipmentTypeArray: EquipmentType[]) => {
    //     const untracked = equipmentTypeArray.filter(x => !x.trackIndividually);
    //     console.log("untracked: ", untracked);
    //     if (untracked && untracked.length > 0) {
    //         this.type = untracked[0];
    //     }
    // });
  }

//   ngOnChanges(changes: SimpleChanges): void {
//     console.log("changes: ", changes)
//       for (let c in changes) {
//           if (c === "case") {
//               this.loadData();
//           }
//       }
//   }

  private loadData() {
    this.equipmentService.getUntrackedForCase(this.case.id)
        .pipe(filter(result => result != null))
        .subscribe((t: any) => {
            this.setData(t.result);

            this.equipmentService.getTypes()
            .subscribe((equipmentTypeArray: EquipmentType[]) => {
                const untracked = equipmentTypeArray.filter(x => !x.trackIndividually);
                if (untracked && untracked.length > 0) {
                    this.type = untracked[0];
                }
            });            
        });
  }

  handout() {
      if (this.newAmount > this.type.countAvailable!) {
        this.dialog.open(AlertDialogComponent, {
            width: "80%",
            maxWidth: "460px",
            disableClose: true,
            hasBackdrop: false,
            panelClass: 'transparent-dialog',
            data: {
              type: "error",
              text: `Du prøver at udlevere ${this.newAmount}, men der er kun ${this.type.countAvailable} tilgængelige lige nu.`,
            }
          });

          return;
      }

      this.equipmentService.registerUntrackedForCase(this.case.id, this.type.id!, this.newAmount)
        .subscribe((r: any) => {
          this.setData(r.result);
          this.newAmount = 0;

          this.loadData();
        });
  }

  return() {
      if (this.newAmount > this.count.total!) {
        this.dialog.open(AlertDialogComponent, {
            width: "80%",
            maxWidth: "460px",
            disableClose: true,
            hasBackdrop: false,
            panelClass: 'transparent-dialog',
            data: {
              type: "error",
              text: `Du prøver at aflevere ${this.newAmount}, men der er kun udleveret ${this.count.total}.`,
            }
          });

          return;
      }

      this.equipmentService.registerUntrackedForCase(this.case.id, this.type.id!, this.newAmount * -1)
        .subscribe((r: any) => {
          this.setData(r.result);
          this.newAmount = 0;

          this.loadData();
        });
  }

  private setData = (items: UntrackedEquipmentCount[]) => {
      //This is set up to handle multiple types of untracked equipment on the backend, but right now it is limited to 1 per the spec
      if (items.length > 0) {
        this.count = items[0];
      }
      else {
        this.count =  {};
      }
  }
}
