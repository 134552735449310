<form (ngSubmit)="f.form.valid && submit()" #f="ngForm">
    <h1>Fejlmeld udstyr</h1>
    <!--<p>Når du godkender erstatningslisten, accepterer du den angivne erstatning. Du vil herefter ikke kunne gøre indsigelser.</p>-->
    <label for="comments">Beskrivelse af fejlen</label>
    <textarea class="comments" id="comments" name="comments" [(ngModel)]="comments" #fcomments="ngModel" rows="5"></textarea>

    <div *ngIf="fcomments.invalid && (fcomments.dirty || fcomments.touched)"
         class="alert alert-danger">

        <div *ngIf="fcomments.errors!['required']">
            Du skal angive en beskrivelse
        </div>
    </div>
</form>

<div class="dialog-buttons">
    <button type="submit" class="primary" (click)="onConfirm()" [disabled]="comments.length > 0 ? false : true">{{data.yesButton}}</button>&nbsp;
    <button (click)="onDismiss()">{{data.noButton}}</button>
</div>