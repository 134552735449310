import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceBase {
    protected http: HttpClient;

    constructor(http: HttpClient) {
        this.http = http;
    }
  
      protected getJson<T>(url: string): Observable<T> {
        return this.http.get<T>(url).pipe(
          switchMap(res => this.getObservableValue<T>(res)),
          catchError(res => this.handleObservableError(res))
        );
      }
    
      protected postJson<T>(url: string, data: any, ignoreErrorTypes: string[]): Observable<T>
      protected postJson<T>(url: string, data: any): Observable<T>
      protected postJson<T>(url: string, data: any, ignoreErrorTypes: string[] = []): Observable<T> {
        return this.http.post<T>(url, data).pipe(
          switchMap(res => this.getObservableValue<T>(res)),
          catchError(res => this.handleObservableError(res, ignoreErrorTypes))
        );
      }
    
      protected putJson<T>(url: string, data: any, ignoreErrorTypes: string[]): Observable<T>
      protected putJson<T>(url: string, data: any): Observable<T>
      protected putJson<T>(url: string, data: any, ignoreErrorTypes: string[] = []): Observable<T> {
        return this.http.put<T>(url, data).pipe(
          switchMap(res => this.getObservableValue<T>(res)),
          catchError(res => this.handleObservableError(res, ignoreErrorTypes))
        );
      }
    
      protected patchJson<T>(url: string, data: any, ignoreErrorTypes: string[]): Observable<T>
      protected patchJson<T>(url: string, data: any): Observable<T>
      protected patchJson<T>(url: string, data: any, ignoreErrorTypes: string[] = []): Observable<T> {
        return this.http.patch<T>(url, data).pipe(
          switchMap(res => this.getObservableValue<T>(res)),
          catchError(res => this.handleObservableError(res, ignoreErrorTypes))
        );
      }
    
      protected patchJsonText(url: string, data: any, ignoreErrorTypes: string[]): Observable<string>
      protected patchJsonText(url: string, data: any): Observable<string>
      protected patchJsonText(url: string, data: any, ignoreErrorTypes: string[] = []): Observable<string> {
        return this.http.patch(url, data, { responseType: "text" }).pipe(
          switchMap(res => this.getObservableValue<string>(res)),
          catchError(res => this.handleObservableError(res, ignoreErrorTypes))
        );
      }
  
      protected deleteJson<T>(url: string, ignoreErrorTypes: string[] = []): Observable<T> {
        console.log("URL: ", url);
        return this.http.delete<T>(url).pipe(
          switchMap((res: any) => this.getObservableValue<T>(res)),
          catchError((error: any) => this.handleObservableError(error, ignoreErrorTypes))
        );
      }     
  
      private getObservableValue<T>(value: T): Observable<T> {
          return of(value);
      }
  
      private handleObservableError(error: HttpErrorResponse | any, ignoreErrorTypes: string[] = []): Observable<never> {
        let errMsg: string;
        console.log("error.error: ", error.error);        
        if (ignoreErrorTypes.some(type => error instanceof HttpErrorResponse)) {
            console.error('An error occurred:', error.error.message);
            throw error;
        } else {
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
                throw error;
        }
      }
}
