import { Injectable } from '@angular/core';
import { AuthenticationService } from './auth/authentication.service';
import { SafetyDocument } from '../models/safety-document';
import { HttpClient } from '@angular/common/http';
import { AlertService } from './alert.service';
import { Observable } from 'rxjs';
import { ServiceBase } from './base/service-base.service';

@Injectable({
  providedIn: 'root'
})
export class SafetyDocsService  extends ServiceBase {
  private baseurl = '/api/safetydocs/';
  private downloadUrl: string = '/api/safetydocs/download/';

  constructor(
    private authhttp: HttpClient,
    private authServ: AuthenticationService, 
    private alert: AlertService) {
    super(authhttp);
  }

  getSafetyDocs(): Observable<SafetyDocument[]> {
      return this.getJson<SafetyDocument[]>(this.baseurl + 'list');
  }

  downloadFile(fileName: string): Observable<Blob> {
    return this.http.get(this.downloadUrl + fileName, {
      responseType: 'blob',
    });
  }
}
