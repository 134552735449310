import { Component, Input, OnChanges, OnInit, SimpleChanges, LOCALE_ID } from '@angular/core';
import { DamageCase } from '../../../models/damage-case';
import { ReportBase } from '../../../models/report-base';
import { ReportType } from '../../../interfaces/report-type';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { ActionService } from '../../../services/action.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportsService } from '../../../services/reports-service.service';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { reportTypes } from '../../../constants/report.const';
import { BehaviorSubject, catchError, finalize, of, tap } from 'rxjs';

import localeDa from '@angular/common/locales/da';

registerLocaleData(localeDa, 'da-DK');

@Component({
  selector: 'app-case-details-reports',
  standalone: true,
  templateUrl: './case-details-reports.component.html',
  styleUrls: ['./case-details-reports.component.scss'],
  imports: [CommonModule],
  providers: [
    DatePipe,
    { provide: LOCALE_ID, useValue: 'da-DK' }
  ]
})
export class CaseDetailsReportsComponent implements OnInit, OnChanges {
  @Input() case!: DamageCase;
  report$ = new BehaviorSubject<ReportBase | null>(null);
  reportType$ = new BehaviorSubject<ReportType | null>(null);
  loaded$ = new BehaviorSubject<boolean>(false);
  error$ = new BehaviorSubject<string | null>(null);

  constructor(
    public authService: AuthenticationService,
    private reportsService: ReportsService,
    private actionService: ActionService,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.loadData();
  }

  createReport(event: MouseEvent) {
    this.router.navigate(["case", this.case.id, "createreport"]);
  }

  editReport(event: MouseEvent) {
    this.router.navigate(["case", this.case.id, "report"]);
  }

  formatDate(date: string | Date | null): string {
    if (!date) return 'N/A';
    const parsedDate = typeof date === 'string' ? new Date(date) : date;
    return this.datePipe.transform(parsedDate, 'dd-MM-yy HH:mm', 'da-DK') || 'N/A';
  }

  private loadData(): void {
    this.loaded$.next(false);
    this.error$.next(null);
    this.reportsService.getReportForCase(this.case.id).pipe(
      tap(report => {
        // console.log('Received report:', report);
        this.report$.next(report);
        if (report) {
          const foundReportType = reportTypes.find(x => x.alias === report.type);
          // console.log('Found report type:', foundReportType);
          this.reportType$.next(foundReportType || null);
          this.case.hasReport = true;
        } else {
          this.case.hasReport = false;
        }
      }),
      catchError(err => {
        console.error('Error loading report:', err);
        this.error$.next('Failed to load report. Please try again.');
        return of(null);
      }),
      finalize(() => {
        this.loaded$.next(true);
        console.log('Loading completed. Loaded state:', this.loaded$.value);
      })
    ).subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['case']) {
      this.loadData();
    }
  }
}
