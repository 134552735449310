<div class="pane-list">
    <dr-spinner [isRunning]="loading"></dr-spinner>
    <div class="list-container list">
        <table class="item-table">
            <thead>
                <tr>
                    <th>Navn</th>
                    <th>Stilling</th>
                    <th>Telefonnummer</th>
                    <th>Mobil</th>
                    <th>Aktive sager</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let employee of employees"
                    [class.selected]="router.isActive('/employees/' + employee.id, true)"
                    (click)="onSelect(employee)">
                    <td class="item-header" data-label="Navn">
                        <avatar [user]="employee" size="small"></avatar> {{employee.fullName}}
                    </td>
                    <td data-label="Stilling">
                        {{employee.occupation}}
                    </td>
                    <td data-label="Telefonnummer">
                        {{employee.phoneNumber}}
                    </td>
                    <td data-label="Mobil">
                        {{employee.cellphoneNumber}}
                    </td>
                    <td data-label="Antal sager">
                        {{employee.caseCount}}
                    </td>
                    <td class="actions">
                        <span *ngIf="employee.disabled" class="disabled" title="Konto deaktiveret"><i class="fa fa-user fa-lg"></i></span>
                        <!--<button class="link" (click)="delete(h)" title="Slet"><i class="fa fa-trash"></i><span class="visuallyhidden">Slet</span></button>-->
                    </td>
                </tr>
            </tbody>
        </table>

    </div>

    <div class="detail-container">
        <div class="detailpanel">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>