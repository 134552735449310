import { Injectable } from '@angular/core';
import { CloudBurstReport } from '../models/report/cloud-burst-report';
import { ServiceBase } from './base/service-base.service';
import { AlertService } from './alert.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResult } from '../models/api-result';

@Injectable({
  providedIn: 'root'
})
export class CloudBurstReportService extends ServiceBase {
  private baseUrl = '/api/report/cloudburst/';

  constructor(authhttp: HttpClient, alert: AlertService) {
      super(authhttp);
  }

  create(report: CloudBurstReport): Observable<CloudBurstReport> {
      return this.postJson<CloudBurstReport>(this.baseUrl + "create", report);
  }

  get(reportId: number): Observable<CloudBurstReport> {
      return this.getJson<CloudBurstReport>(this.baseUrl + "get/" + reportId);
  }

  update(report: CloudBurstReport): Observable<CloudBurstReport> {
      return this.postJson<CloudBurstReport>(this.baseUrl + "update", report);
  }
}
