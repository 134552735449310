<div class="container">
    <h1 mat-dialog-title class="title">Maskinjournal</h1>
    <mat-dialog-content>
        <div class="casenumber">
            Sagsnr. {{case.caseNumber}}
        </div>

        <ng-container *ngIf="logs.length > 0">
            <div class="header">
                <div class="equipment">Maskine</div>
                <div class="placed">
                    <div class="group-header">Opsat</div>
                    <!-- <div class="date">Dato</div> -->
                    <div class="counter">Tæller</div>
                </div>
                <div class="takendown">
                    <div class="group-header">Nedtaget</div>
                    <!-- <div class="date">Dato</div> -->
                    <div class="counter">Tæller</div>
                </div>
                <div class="usage">Forbrug</div>
                <div class="days">Dage</div>
                <div class="actions half">&nbsp;</div>
            </div>
            <div class="logs">
                <equipment-log-item style="width:100%"
                    *ngFor="let row of logs; trackBy: trackByLog"
                    [row]="row"
                    [case]="case"
                    (cancelAdd)="cancelAdd($event)">
                </equipment-log-item>
            </div>
        </ng-container>

        <div class="buttons" *ngIf="case.damageStatusId == 2 || case.damageStatusId == 4">
            <button (click)="placeEquipment()">Opsæt nyt udstyr</button>
        </div>
        <div class="comments">
            Yderligere materialer / Kommentar:
            <form (ngSubmit)="saveMaterials()" #f="ngForm">
                <textarea class="textarea_comments" type="number" name="eqlogMaterials" [(ngModel)]="case.equipmentLogMaterials"></textarea>
                <button class="buttons" [disabled]="case.damageStatusId != 2 && case.damageStatusId != 4" type="submit()">Gem kommentar</button>
            </form>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="dialog-buttons" align="end">
        <button class="primary" mat-dialog-close=>Luk</button>
    </mat-dialog-actions>
</div>