import { Injectable } from '@angular/core';
import { SearchCriteria } from '../interfaces/search-criteria';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  enabled!: boolean;

  private criteriaSubject: Subject<SearchCriteria> = new Subject<SearchCriteria>();
  criteria: Observable<SearchCriteria>;

  constructor() {
      this.criteria = this.criteriaSubject.asObservable();
  }

  public changeCriteria(criteria: SearchCriteria) {
    if (criteria.fromDate instanceof Date && criteria.toDate instanceof Date) {
      criteria.fromDate = new Date(criteria.fromDate);
      criteria.toDate = new Date(criteria.toDate);
    }
    this.criteriaSubject.next(criteria);
  }
}
