import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MeasurementTransaction } from '../../../models/measurement/measurement-transaction';
import { MeasurementLog } from '../../../models/measurement/measurement-log';
import { MeasuringPoint } from '../../../models/measurement/measuring-point';
import { MeasurementValue } from '../../../models/measurement/measurement-value';
import { slideLeftRightList } from '../../../animations/animations';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Mode } from '../../../types/mode.type';
import { MeasurementTransactionService } from '../../../services/measurement/measurement-transaction.service';

@Component({
  selector: 'measurement-transaction',
  standalone: true,
  imports: [
    CommonModule, 
    FormsModule, 
  ],
  templateUrl: './measurement-transaction.component.html',
  styleUrl: './measurement-transaction.component.scss',
  animations: [slideLeftRightList]
})
export class MeasurementTransactionComponent  implements OnInit {
  @Input() transaction!: MeasurementTransaction;
  @Input() log!: MeasurementLog;
  @Input() points!: MeasuringPoint[] | null;
  @Input() mode!: Mode;

  @Output() created: EventEmitter<MeasurementTransaction>;
  @Output() deleted: EventEmitter<MeasurementTransaction>;


  constructor(
    private measurementTransactionService: MeasurementTransactionService) {
      this.mode = "create";
      this.created = new EventEmitter<MeasurementTransaction>();
      this.deleted = new EventEmitter<MeasurementTransaction>();
    }

  ngOnInit(): void {
      if (this.mode == "create") {
          this.transaction = new MeasurementTransaction();
          this.transaction.date = new Date();
      }

      this.updatePoints();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let p in changes) {
      if (p == "log") {
          if ((changes["log"].previousValue && changes["log"].currentValue) &&
              (changes["log"].previousValue.id && changes["log"].currentValue.id) &&
              changes["log"].previousValue.id != changes["log"].currentValue.id) {
              if (this.transaction && this.mode == "create") {
                  this.transaction.values = [];
              }
          }
      }

      if (p == "points") {
          if (this.transaction) {
              this.updatePoints();
          }
      }

      if (p == "transaction") {
          if (this.transaction) {
              this.updatePoints();
          }
      }
    }
  }


  private updatePoints() {
      if (this.points) {
          //Loop through the measurement transaction and add any missing ones
          for (let point of this.points) {
            let v: MeasurementValue = new MeasurementValue();

            if (this.transaction.values.find(x => x.pointId == point.id) == undefined) {
                //The transaction does not have this value. Insert it
                v.pointId = point.id;
                v.state = point.state;
                this.transaction.values.push(v);
            } else {
              v = this.transaction.values.find(x => x.pointId == point.id)!
              v.pointName = point.name;
            }
          }

          //Then remove any that are no longer in the list of points (they have been deleted)
          for (let v of this.transaction.values) {
              if (this.points.find(x => x.id == v.pointId) == undefined) {
                  //we have a value that references a point that is no longer in the list of points
                  //Remove it here as well
                  v.state = "remove";
                  this.transaction.values.splice(this.transaction.values.indexOf(v), 1);
              }
          }
      }
      else {
      }
  }


  onSubmitCreate(): void {
      this.created.emit(this.transaction);
      this.transaction = new MeasurementTransaction();
      this.transaction.date = new Date();
      this.updatePoints();
  }

  delete(): void {
      this.deleted.emit(this.transaction);
  }
}
