import { Injectable } from '@angular/core';
import { StormReport } from '../models/storm-report';
import { ServiceBase } from './base/service-base.service';
import { AlertService } from './alert.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StormReportService extends ServiceBase {
  private baseUrl = '/api/report/storm/';

  constructor(authhttp: HttpClient, alert: AlertService) {
      super(authhttp);
  }

  create(report: StormReport): Observable<StormReport> {
      return this.postJson<StormReport>(this.baseUrl + "create", report);
  }

  get(reportId: number): Observable<StormReport> {
      return this.getJson<StormReport>(this.baseUrl + "get/" + reportId);
  }

  update(report: StormReport): Observable<StormReport> {
      return this.postJson<StormReport>(this.baseUrl + "update", report);
  }
}
