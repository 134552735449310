<div class="pane">
    <div class="rooms">
        <ul>
            <li *ngFor="let room of rooms" (click)="selectRoom(room)" [ngClass]="{active: this.selectedRoom && this.selectedRoom.id === room.id}" [@slideUpDownList]="room.state">
                <ng-template *ngIf="room.editing; then edit; else show"></ng-template>
                <ng-template #show>
                    {{room.name}}
                    <div class="actions">
                        <button (click)="editRoom(room)" title="Rediger"><i class="fa fa-pencil"></i><span class="visuallyhidden"></span></button>
                        <button (click)="deleteRoom(room)" title="Slet"><i class="fa fa-trash"></i><span class="visuallyhidden"></span></button>
                    </div>
                </ng-template>
                <ng-template #edit>
                    <form (ngSubmit)="f.form.valid && saveRoom(room)" #f="ngForm">
                        <input type="text" name="name" required [(ngModel)]="room.name" />
                        <div class="actions">
                            <button type="button" (click)="cancelEditRoom(room)" title="Annuller"><i class="fa fa-times"></i><span class="visuallyhidden"></span></button>
                            <button type="submit" title="Gem"><i class="fa fa-save"></i><span class="visuallyhidden"></span></button>
                        </div>
                    </form>
                </ng-template>
            </li>
        </ul>

        <div class="create">
            <form>
                <input [(ngModel)]="newRoomName" (keyup.enter)="createRoom()" name="newRoomName" />
                <br>
                <button (click)="createRoom()">Opret rum</button>
            </form>
        </div>
    </div>
    <div class="items">
        <div *ngIf="selectedRoom; else empty">
            <ul>
                <li *ngFor="let item of selectedRoom.items"  [@slideUpDownList]="item.state">
                    {{item.name}}
                    <div class="actions">
                        <button (click)="deleteItem(item)" title="Slet"><i class="fa fa-trash"></i><span class="visuallyhidden"></span></button>
                    </div>
                </li>
            </ul>
            <div class="create">
                <form>
                    <input [(ngModel)]="newItemName" (keyup.enter)="createItem()" name="newItemName" />
                    <button (click)="createItem()">Opret genstand</button>
                </form>
            </div>
        </div>
        <ng-template #empty><div class="empty-placeholder">Vælg eller opret et rum til venstre</div></ng-template>
    </div>
</div>