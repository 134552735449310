import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/auth/authentication.service';

@Component({
  selector: 'app-component-base',
  standalone: true,
  imports: [],
  templateUrl: './component-base.component.html',
  styleUrl: './component-base.component.scss'
})
export class ComponentBaseComponent implements OnInit {
  public authService: AuthenticationService;

  constructor(auth: AuthenticationService)
  {
      this.authService = auth;
  }

  ngOnInit() {
      //alert("test from super");
  }
}
