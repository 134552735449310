import { Injectable } from '@angular/core';
import { HouseholdEffect } from '../models/household-effect';
import { Observable } from 'rxjs';
import { ServiceBase } from './base/service-base.service';
import { HttpClient } from '@angular/common/http';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class HouseholdEffectService  extends ServiceBase {
  private householdurl = '/api/householdeffect/';

  constructor(authhttp: HttpClient, alert: AlertService) {
      super(authhttp);
  }

  list(userid: string): Observable<HouseholdEffect[]> {
      return this.getJson<HouseholdEffect[]>(this.householdurl + "list/" + userid);
  }

  search(userid: string, query: string): Observable<HouseholdEffect[]> {
      return this.getJson<HouseholdEffect[]>(this.householdurl + "list/" + userid + "?query=" + query);
  }

  create(effect: HouseholdEffect): Observable<HouseholdEffect> {
      return this.postJson<HouseholdEffect>(this.householdurl + "create", effect);
  }

  update(effect: HouseholdEffect): Observable<HouseholdEffect> {
      return this.postJson<HouseholdEffect>(this.householdurl + "update", effect);
  }

  delete(effect: HouseholdEffect): Observable<HouseholdEffect> {
      return this.postJson<HouseholdEffect>(this.householdurl + "delete", effect);
  }


  //TODO: Create, Update, Delete HouseholdEffect
}
