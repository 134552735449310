<header class="main">
    <span class="icon"><i class="fa fa-wifi" [title]="getTitle(connectionState)" [style.color]="getColor(connectionState)"></i></span>

    <a routerLink="/" class="logo"><img src="assets/images/dr_logo_white.svg" />
        <div *ngIf="!isProduction">test</div>
    </a>

    <div class="current-user" *ngIf="authService.loggedin">
      <div class="company">{{authService.companyName}}</div>
        <div class="username">{{authService.userFullName}} | <a [routerLink]="['/login']">Log ud</a></div>
    </div>

    <div class="toolbar" *ngIf="authService.loggedin">
        <div class="header-buttons" *ngIf="authService.loggedin">
            <button (click)="btnClick(btn)" *ngFor="let btn of _actionService.headerButtons$ | async">
                <i *ngIf="btn.iconClass" class="fa {{btn.iconClass}}"></i> {{btn.text}}
            </button>
        </div>

        <div class="inline-item-group right">
            <menu-dropdown dropdownText="Menu">
                <dropdown-item [route]="['/']" text="Sager"></dropdown-item>
                <dropdown-item *ngIf="authService.userRole=='1' ||authService.userRole=='2'" [route]="['/employees']" text="Medarbejdere"></dropdown-item>
                <dropdown-item *ngIf="authService.isAdmin ||authService.isEmployee" [route]="['/customers']" text="Kunder"></dropdown-item>
                <dropdown-item *ngIf="authService.userRole=='1' ||authService.userRole=='2'" [route]="['/equipment']" text="Udstyr"></dropdown-item>
                <dropdown-item *ngIf="authService.userRole=='1' ||authService.userRole=='2'" [route]="['/vehicles']" text=" Biler"></dropdown-item>
                <dropdown-item *ngIf="authService.userRole=='1' ||authService.userRole=='2'" [route]="['/settings']" text="Indstillinger"></dropdown-item>
                <dropdown-item *ngIf="authService.userRole!='3'" (onMenuItemClick)="openSafetyDocs()" text="Sikkerhedsmappe"></dropdown-item>
                <dropdown-item *ngIf="authService.userRole=='3'" [route]="['/householdeffects']" text="Indbo"></dropdown-item>
            </menu-dropdown>
        </div>
    </div>
    <!-- <ng-container *ngIf="notificationService.connectionState$ | async as state">
        <div class="connection-alert" *ngIf="state !== 'CONNECTED' && state !== 'CONNECTING'">
            Kommunikationsproblemer med serveren {{state}}
        </div>
    </ng-container> -->
</header>
