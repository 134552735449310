import { Component, OnInit } from '@angular/core';
import { Vehicle } from '../../../interfaces/vehicles/vehicle';
import { VehicleService } from '../../../services/vehicles/vehicle.service';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-vehicles',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './vehicles.component.html',
  styleUrl: './vehicles.component.scss'
})
export class VehiclesComponent implements OnInit {
  public vehicle!: Vehicle | any;
  selectedVehicle!: Vehicle | null;
  vehicleId!: string;

  constructor(
      private vehicleService: VehicleService,
      private authService: AuthenticationService,
      private router: Router,
      private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
  }

  onSelect(vehicle: Vehicle): void {
      if (this.selectedVehicle === vehicle) {
          this.selectedVehicle = null;
      }
      else {
          this.selectedVehicle = vehicle;
          this.router.navigate([vehicle.id], { relativeTo: this.route });
      }
  }
}
