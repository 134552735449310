import { Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { EmbedService } from '../../../services/embed.service';
import { CommonModule } from '@angular/common';
import { Embed } from '../../../interfaces/embed';
import { filter, take } from 'rxjs';

@Component({
  selector: 'embed-viewer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './embed-viewer.component.html',
  styleUrl: './embed-viewer.component.scss'
})
export class EmbedViewerComponent implements OnInit, OnChanges {
    @HostBinding("innerHTML")
    public embedHtml!: SafeHtml;
  
    @Input() public url!: string;
  
    constructor(
        private embed: EmbedService,
        private sanitizer: DomSanitizer,
    ) {}
  
    ngOnInit(): void {
        this.load();
    }
  
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['url']) {
            this.load();
        }
    }
  
    private load() {
        if (this.url) {
            this.embed.get(this.url)
            .pipe(filter(x => x != null), take(1))
            .subscribe((x: Embed) => {
                if (x) {
                    //Mark HTML as trusted, otherwise Angular will strip out the iframes
                    this.embedHtml = this.sanitizer.bypassSecurityTrustHtml(x.html);
                }
            });
        }
    }
  }
  
// export class EmbedViewerComponent implements OnInit, OnChanges {
//   @HostBinding("innerHTML")
//   public embedHtml!: SafeHtml;

//   @Input() public url!: string;

//   constructor(
//       private embed: EmbedService,
//       private sanitizer: DomSanitizer
//   ) {}

//   ngOnInit(): void {
//       this.load();
//   }

//   ngOnChanges(changes: SimpleChanges): void {
//       for (var p in changes) {
//           if (p === "url") {
//               this.load();
//           }
//       }
//   }

//   private load() {
//       if (this.url) {
//           this.embed.get(this.url).subscribe(x => {
//               if (x) {
//                   //Mark HTML as trusted, otherwise Angular will strip out the iframes
//                   this.embedHtml = this.sanitizer.bypassSecurityTrustHtml(x.html);
//               }
//           });
//       }
//   }
// }
