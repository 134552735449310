<h2>Skybrudsrapport</h2>
<form (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" class="report" name="cloudburst">
    <fieldset>
        <legend>Beskrivelse af skadeomfang</legend>
        <div class="input-group">
            <div class="field-container">
                <label for="damagedFloorArea">Ødelagt gulvareal (ca.)</label>
                <div class="input-wrapper">
                    <input type="number" [(ngModel)]="formModel.damagedFloorArea" name="damagedFloorArea" id="damagedFloorArea" [disabled]="isdisabled" required />
                    <span class="unit">m<sup>2</sup></span>
                </div>
            </div>
        
            <div class="field-container">
                <label for="damagedWallArea">Ødelagt vægareal (ca.)</label>
                <div class="input-wrapper">
                    <input type="number" [(ngModel)]="formModel.damagedWallArea" name="damagedWallArea" id="damagedWallArea" [disabled]="isdisabled" required />
                    <span class="unit">m<sup>2</sup></span>
                </div>
            </div>
        </div>        
        <div class="input-group">
            <div class="field-container">
                <label for="waterHeight">Hvor højt har vandet stået</label>
                <div class="input-group">
                    <input type="number" [(ngModel)]="formModel.waterHeight" name="waterHeight" id="waterHeight" [disabled]="isdisabled" required />
                    <span class="unit">cm</span>
                </div>
            </div>
        </div>
        <div class="input-group">
            <div class="field-container">
                <label for="waterIngressRoute">Hvordan kom vandet ind</label>
                <textarea class="text-area" [(ngModel)]="formModel.waterIngressRoute" name="waterIngressRoute" id="waterIngressRoute" [disabled]="isdisabled" required></textarea>
            </div>
        </div>
        <div class="input-group">
            <div class="field-container">
                <label for="damageNotes">Bemærkninger</label>
                <textarea class="text-area" [(ngModel)]="formModel.damageNotes" name="damageNotes" id="damageNotes" [disabled]="isdisabled" required></textarea>
            </div>
        </div>
    </fieldset>

    <fieldset>
        <legend>Hvad er der foretaget på udkald</legend>
        <div class="checkbox-list">
            <div class="checkbox-item">           
                <div class="checkbox-wrapper">     
                    <input type="checkbox" [(ngModel)]="formModel.actionWaterPumped" name="actionWaterPumped" [disabled]="isdisabled" />
                </div>
                <label>Opsuget vand</label>
            </div>
    
            <div class="checkbox-item">
                <div class="checkbox-wrapper">     
                    <input type="checkbox" [(ngModel)]="formModel.actionDisinfected" name="actionDisinfected" [disabled]="isdisabled" />
                </div>
                <label>Desinficeret</label>
            </div>
    
            <div class="checkbox-item">
                <div class="checkbox-wrapper">     
                    <input type="checkbox" [(ngModel)]="formModel.actionWoodFloorRemoved" name="actionWoodFloorRemoved" [disabled]="isdisabled" />
                    <label>Skåret trægulv op</label>

                    <div class="input-group" *ngIf="formModel.actionWoodFloorRemoved">
                        <input type="number" [(ngModel)]="formModel.woodFloorRemovedArea" name="woodFloorRemovedArea" id="woodFloorRemovedArea" [disabled]="isdisabled" required />
                        <span class="input-group-addon supplement">m<sup>2</sup></span>
                    </div>
                </div>
            </div>

            <div class="checkbox-item">
                <div class="checkbox-wrapper">     
                    <input type="checkbox" [(ngModel)]="formModel.actionCarpetRemoved" name="actionCarpetRemoved" [disabled]="isdisabled" />
                    <label>Fjernet tæppe</label>

                    <div class="input-group" *ngIf="formModel.actionCarpetRemoved">
                        <input type="number" [(ngModel)]="formModel.carpetRemovedArea" name="carpetRemovedArea" id="carpetRemovedArea" [disabled]="isdisabled" required />
                        <span class="input-group-addon supplement">m<sup>2</sup></span>
                    </div>
                </div>
            </div>

            <div class="checkbox-item">
                <div class="checkbox-wrapper">     
                    <input type="checkbox" [(ngModel)]="formModel.actionEmergencyCleaning" name="actionEmergencyCleaning" [disabled]="isdisabled" />
                </div>
                <label>Nødrengøring</label>
            </div>

            <div class="checkbox-item">
                <div class="checkbox-wrapper">     
                    <input type="checkbox" [(ngModel)]="formModel.actionPhoto" name="actionPhoto" [disabled]="isdisabled" />
                </div>
                <label>Taget foto</label>
            </div>

            <div class="checkbox-item">
                <div class="checkbox-wrapper">     
                    <input type="checkbox" [(ngModel)]="formModel.actionFloorSampleTaken" name="actionFloorSampleTaken" [disabled]="isdisabled" />
                </div>
                <label><span class="content-group">Tæppe-/gulvprøve taget<small>aflev. på kontor</small></span></label>
            </div>            
        </div>

        <div class="checkbox-item">
            <label for="actionNotes">Bemærkninger</label>
            <div class="checkbox-wrapper">     
                <textarea class="text-area" [(ngModel)]="formModel.actionNotes" name="actionNotes" id="actionNotes" [disabled]="isdisabled" required></textarea>
            </div>
        </div>
    </fieldset>

    <fieldset>
        <legend>Hvad skal der foretages efter udkald</legend>
        <div class="checkbox-list">
            <div class="checkbox-item">
                <div class="checkbox-wrapper">     
                    <input type="checkbox" [(ngModel)]="formModel.todoClean" name="todoClean" [disabled]="isdisabled" />
                </div>
                <label>Gøres rent</label>
            </div> 

            <div class="checkbox-item">
                <div class="checkbox-wrapper">     
                    <input type="checkbox" [(ngModel)]="formModel.todoDisinfect" name="todoDisinfect" [disabled]="isdisabled" />
                </div>
                <label>Desinficeres</label>
            </div>  
            
            <div class="checkbox-item">
                <div class="checkbox-wrapper">     
                    <input type="checkbox" [(ngModel)]="formModel.todoDehumidify" name="todoDehumidify" [disabled]="isdisabled" />
                </div>
                <label>Affugtes</label>
            </div>
            
            <div class="checkbox-item">
                <div class="checkbox-wrapper">     
                    <input type="checkbox" [(ngModel)]="formModel.todoMeasureHumidity" name="todoMeasureHumidity" [disabled]="isdisabled" />
                </div>
                <label>Fugtmåles</label>
            </div>     

            <div class="checkbox-item">
                <div class="checkbox-wrapper">     
                    <input type="checkbox" [(ngModel)]="formModel.todoRemoveFloor" name="todoRemoveFloor" [disabled]="isdisabled" />
                </div>
                <label>Brækkes gulv op</label>
            </div>  
            
            <div class="checkbox-item">
                <div class="checkbox-wrapper">     
                    <input type="checkbox" [(ngModel)]="formModel.todoRemoveWall" name="todoRemoveWall" [disabled]="isdisabled" />
                </div>
                <label>Fjernes dele af vægge</label>
            </div>  
            
            <div class="checkbox-item">
                <div class="checkbox-wrapper">     
                    <input type="checkbox" [(ngModel)]="formModel.todoFinalCleaning" name="todoFinalCleaning" [disabled]="isdisabled" />
                </div>
                <label>Endelig rengøring</label>
            </div>     

            <div class="checkbox-item">
                <div class="checkbox-wrapper">     
                    <input type="checkbox" [(ngModel)]="formModel.todoHouseholdEffectList" name="todoHouseholdEffectList" [disabled]="isdisabled" />
                </div>
                <label>Løsøreliste udfyldes</label>
            </div>     
        </div>

        <div class="checkbox-item">
            <label for="todoNotes">Bemærkninger</label>
            <div class="checkbox-wrapper">     
                <textarea class="text-area" [(ngModel)]="formModel.todoNotes" name="todoNotes" id="todoNotes" [disabled]="isdisabled" required></textarea>
            </div>
        </div>
    </fieldset>

    <div *ngIf="!f.form.valid">
        Bemærk! Der er felter der mangler at blive udfyldt.
    </div>
    
    <div class="buttons">
        <div *ngIf="mode == 'create'">
            <button type="submit" class="primary" [disabled]="isdisabled || !f.form.valid">Opret rapport</button>
            <button (click)="cancel($event)">Annuller</button>
        </div>
        <div *ngIf="mode == 'edit'">
            <button type="submit" class="primary" [disabled]="isdisabled || !f.form.valid">Gem og luk</button>
            <button (click)="cancel($event)">Annuller</button>
        </div>
    </div>
</form>