import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector, Type } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DomService {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) { }

  createComponentRef<T>(component: Type<T>): ComponentRef<T> {
      const componentRef = this.componentFactoryResolver
          .resolveComponentFactory(component)
          .create(this.injector);
      this.appRef.attachView(componentRef.hostView);
      return componentRef;
  }

  getDomElementFromComponentRef(componentRef: ComponentRef<any>): HTMLElement {
      return (componentRef.hostView as EmbeddedViewRef<any>)
          .rootNodes[0] as HTMLElement;
  }

  addChild(child: HTMLElement, parent: HTMLElement = document.body) {
      parent.appendChild(child);
  }

  destroyRef(componentRef: ComponentRef<any>) {
      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
  }
}
