import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { MeasurementLog } from '../../../models/measurement/measurement-log';
import { UploadComponent } from '../../upload/upload.component';
import { MeasurementLogService } from '../../../services/measurement/measurement-log.service';
import { Attachment } from '../../../models/attachment';
import { CommonModule } from '@angular/common';
import { FileUploadedArgsComponent } from '../../file-uploaded-args/file-uploaded-args.component';

@Component({
  selector: 'measurementlog-image',
  standalone: true,
  imports: [CommonModule, UploadComponent],
  templateUrl: './measurement-log-image.component.html',
  styleUrl: './measurement-log-image.component.scss'
})
export class MeasurementLogImageComponent {
  @Input() log!: MeasurementLog;
  @ViewChild(UploadComponent)

  public upload!: UploadComponent;
  uploadUrl!: string;
  imageSrcList: string[] = [];
  
  constructor(
    private mlService: MeasurementLogService) {
  }

  onUpload(event: MouseEvent): void {
      this.upload.upload();
  }

  onFileUploaded(event: FileUploadedArgsComponent) {
    const value = event.getValue<Attachment>();
    if (value) {
        if (!this.log.attachments) {
            this.log.attachments = [];
        }
        this.log.attachments.push(value);
        this.mlService.update(this.log);
        this.refreshImages();
    }
  }


  ngOnChanges(changes: SimpleChanges) {
      for (let p in changes) {
        if (p === "log") {
            if (this.log) {
                this.uploadUrl = "/api/measurement/log/" + this.log.id + "/uploadattachment";
            }

            if (this.upload) {
                //If the case changes, we need to clear the upload widget
                this.upload.reset();
            }

            if (this.log) {
                this.refreshImages();
            }
          }
      }
  }

  deleteImage(a: number) {
    const attachment: Attachment = this.log.attachments[a];
    const idx: number = this.log.attachments.findIndex(x => x.id == attachment.id);
    this.imageSrcList.splice(a, 1);
    this.log.attachments.splice(idx, 1);

    this.mlService.deleteAttachment(attachment.id, this.log.id.toString())
        .subscribe(()=> {
            // console.log("Attachment Deleted: ", attachment);
        });
  }

  openImageInNewWindow(base64Image: string): void {
    // Create a new window
    const newWindow = window.open("", "_blank");
  
    if (newWindow) {
      // Write HTML to display the image with original size
      newWindow.document.write(`
        <html>
          <head>
            <title>Image</title>
            <style>
              body { margin: 0; display: flex; justify-content: center; align-items: center; height: 100vh; }
              img { display: block; }
            </style>
          </head>
          <body>
            <img src="${base64Image}" alt="Image"/>
          </body>
        </html>
      `);
      newWindow.document.close();
    } else {
      console.error("Failed to open new window.");
    }
  }

  private refreshImages(): void {
    this.log.attachments.forEach((attachment: Attachment) => {
        this.imageSrcList = [];
        this.mlService.getImage(attachment.companyId, this.log.id.toString(), attachment.fileName)
            .subscribe((blob: Blob) => {
                const reader = new FileReader();
                reader.onload = () => {
                // The result of FileReader will be a Base64 encoded data URL
                this.imageSrcList.push(reader.result as string);
            };

            reader.readAsDataURL(blob); // Convert blob to Base64
        });
    });
  }
}
