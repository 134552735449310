import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MultiSelectDropdownComponent } from "../ui/multi-select-dropdown/multi-select-dropdown.component";
import { SearchCriteria } from '../../interfaces/search-criteria';
import { SelectItem } from '../../interfaces/ui/iselect-item';
import { SearchService } from '../../services/search.service';
import { DamageCaseService } from '../../services/damage-case.service';

@Component({
    selector: 'search',
    standalone: true,
    templateUrl: './search.component.html',
    styleUrl: './search.component.scss',
    imports: [CommonModule, FormsModule, MultiSelectDropdownComponent]
})
export class SearchComponent implements OnInit {
  fromDate: Date | null = null;
  toDate: Date | null = null;

  private criteria!: SearchCriteria;

  types: SelectItem[] = [
      { title: "Vand", value: "1", selected: true },
      { title: "Storm", value: "2", selected: true },
      { title: "Skybrud", value: "3", selected: true },
      { title: "Brand", value: "4", selected: true }
  ];

  constructor(
    private damagecaseService: DamageCaseService,
    private searchService: SearchService) {
  }

  ngOnInit() {
  }

  modelChange(): void {
    if (this.fromDate && this.toDate) {
        this.refreshCriteria();
    }
  }

  selectionChanged(selection: SelectItem[]): void {
    if (selection.length === 0) {
      this.damagecaseService.clearDamageCaseItems();
    } else {
      this.refreshCriteria();
    }
  }

  disableTyping(event: KeyboardEvent): void {
    event.preventDefault();  // Prevents keyboard input
  }

  private refreshCriteria() {
    let selected: number[] = [];

    this.types.forEach((t: SelectItem) => {
        if (t.selected) {
            selected = [...selected, t.value];
        }
    });

    const newCriteria: SearchCriteria = {
        fromDate: this.fromDate,
        toDate: this.toDate,
        caseTypes: selected
    };

    //Don't fire change, if all data are the same
    if (this.criteria &&
      newCriteria.fromDate === this.criteria.fromDate &&
      newCriteria.toDate === this.criteria.toDate &&
      this.arrayEqual(newCriteria.caseTypes as number[], this.criteria.caseTypes as number[])) {
        return;
    }

    this.criteria = newCriteria;
    this.searchService.changeCriteria(this.criteria);
  }

  openDatePicker(datePickerId: string) {
    const datePicker = document.querySelector<HTMLInputElement>(`#${datePickerId}`);
    if (datePicker) {
      datePicker.style.display = 'block'; // Optionally make it visible if desired
      datePicker.focus(); // Opens the date picker
    }
  }
  

  private arrayEqual<T>(ar1: T[], ar2: T[]): boolean {
      if (ar1.length !== ar2.length) {
          return false;
      }
      for (var i = 0; i < ar1.length; i++) {
          if (ar1[i] !== ar2[i]) {
              return false;
          }
      }
      return true;
  }
}
