import { Injectable } from '@angular/core';
import { ServiceBase } from './base/service-base.service';
import { AlertService } from './alert.service';
import { Observable } from 'rxjs';
import { Embed } from '../interfaces/embed';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmbedService extends ServiceBase {
  private baseurl = '/api/embed/';

  constructor(http: HttpClient, alert: AlertService) {
      super(http);
  }

  get(url: string): Observable<Embed> {
    return this.postJson<Embed>(this.baseurl, { url: url });
  }
}
