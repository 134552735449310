<div class="header">
    <div *ngIf="logoUrl; else nologo" class="logo">
        <img [src]="logoUrl" [alt]="data.companyName" />
    </div>
    <ng-template #nologo>
        <h2 class="logo">{{data.companyName}}</h2>
    </ng-template>
    <img class="dr-logo" src="/assets/images/dr_logo_blue.svg" />
</div>
<h1>Velkommen til DamageReporter</h1>
<p class="intro">
    I DamageReporter kan du nemt følge din skade, indtaste oplysninger om erstatninger og meget mere. Det hele direkte fra din computer.
</p>
<a class="cta" routerLink="/login">
    Log ind nu og kom i gang
</a>
<div class="get-started-video" *ngIf="data?.videoUrl">
    <h2>Få en rundtur</h2>
    <p>Videoen her giver dig et hurtigt overblik over, hvad du kan i DamageReporter.</p>
    <div class="video">
        <embed-viewer [url]="data.videoUrl"></embed-viewer>
    </div>
</div>
