export function orderByComparator(a: any, b: any): number {
    if (!a || !b) {
        return 0;
    }

    const isNumberA = !isNaN(parseFloat(a)) && isFinite(a);
    const isNumberB = !isNaN(parseFloat(b)) && isFinite(b);

    if (!isNumberA || !isNumberB) {
        const aStr = a?.toString().toLowerCase() || '';
        const bStr = b?.toString().toLowerCase() || '';
        return aStr < bStr ? -1 : aStr > bStr ? 1 : 0;
    } else {
        return parseFloat(a) < parseFloat(b) ? -1 : parseFloat(a) > parseFloat(b) ? 1 : 0;
    }
}

/**
 * Sort an array by the given property or properties
 * @param input The array to sort
 * @param keys The key (or array of keys) to sort by. Prefix a key with - to sort descending
 */
export function sortArray<T>(input: T[], keys: keyof T | (keyof T)[]): T[] {
    const keyArray = Array.isArray(keys) ? keys : [keys];

    return input.sort((a, b) => {
        for (const key of keyArray) {
            const isDesc = key.toString().startsWith('-');
            const property = key.toString().replace(/^[+-]/, '') as keyof T;

            const comparison = isDesc
                ? -orderByComparator(a[property], b[property])
                : orderByComparator(a[property], b[property]);

            if (comparison !== 0) return comparison;
        }

        return 0; // Items are equal
    });
}
