<div class="category-list list">
    <div class="categories types">
        <ul>
            <li (click)="selectAll()" [ngClass]="{active: router.isActive('/equipment', true)}">
                Alle
            </li>
            <li *ngFor="let t of types" (click)="selectType(t.id!)" [ngClass]="{active: router.isActive('/equipment/' + t.id, false)}">
                {{t.name}}
                <div class="counts">
                    <span class="total" title="Totalt antal"><span class="visuallyhidden"></span>{{t.countTotal}}</span>
                    <span class="available" title="Tilgængeligt antal"><span class="visuallyhidden"></span>{{t.countAvailable}}</span>
                    <span class="failed" *ngIf="t.countFailed! > 0" title="Antal fejlmeldte"><span class="visuallyhidden"></span>{{t.countFailed}}</span>
                </div>
            </li>
        </ul>
    </div>
    <div class="list-container">
        <app-equipment-list></app-equipment-list>
        <!-- <router-outlet></router-outlet> -->
    </div>
</div>
