import { Component, OnInit } from '@angular/core';
import { Customer } from '../../../models/customer';
import { UserService } from '../../../services/user.service';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { ActionService } from '../../../services/action.service';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { HeaderButton } from '../../../interfaces/header-button';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SpinnerComponent } from "../../ui/spinner/spinner.component";

@Component({
  selector: 'app-customers',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, SpinnerComponent],
  templateUrl: './customers.component.html',
  styleUrl: './customers.component.scss'
})
export class CustomersComponent implements OnInit {
  customers!: Customer[];
  selectedCustomer!: Customer | null;
  loading: boolean = true;

  constructor(
    private custService: UserService,
    public authService: AuthenticationService,
    private actionService: ActionService,
    public router: Router,
    private route: ActivatedRoute) {
        this.actionService.onButtonClick.subscribe(a => this.onHeaderButtonClick(a));
        this.custService.customerUpdated.subscribe(c => this.customerUpdated(c));
  }

  private onHeaderButtonClick(btn: HeaderButton) {
    if (btn.alias === "newCustomer") {
        this.router.navigate(["create"], { relativeTo: this.route });
    }
  }

  private customerUpdated(c: Customer) {
    this.getCustomers();
  }

  getCustomers(): void {
      this.custService.getCustomers()
        .subscribe(dcresult => {
            this.customers = dcresult;
            this.loading = false;
        });
  }

  ngOnInit(): void {
      this.getCustomers();

      this.actionService.addHeaderButton({
          alias: "newCustomer",
          text: "Opret kunde",
          iconClass: "fa-plus"
      });
  }


  ngOnDestroy(): void {
      this.actionService.removeHeaderButton("newCustomer");
  }

  onSelect(customer: Customer): void {
    if (this.selectedCustomer === customer) {
        this.selectedCustomer = null;
    }
    else {
        this.selectedCustomer = customer;        
        this.router.navigate([customer.id], { relativeTo: this.route });
    }
  }
}
