<form class="info-container" (ngSubmit)="saveInfo()" #infoForm="ngForm">
    <div class="company-info">
        <h2>Oplysninger</h2>
        <div class="fields">
            <div class="field">
                <label for="companyname">Firmanavn</label>
                <input id="companyname" name="companyname" required [(ngModel)]="settings.companyName" />
            </div>
            <div class="field">
                <label for="managername">Ansvarlig</label>
                <input id="managername" name="managername" required [(ngModel)]="settings.managerName" />
            </div>
        </div>
    </div>
    <div class="logo">
        <h2>Logo</h2>
        <div *ngIf="hasLogo && !changeLogo" class="preview">
            <img [src]="logoUrl" />
            <button class="link" (click)="onChangeLogo($event)">Skift</button>
        </div>
        <file-upload *ngIf="!hasLogo || changeLogo" [uploadUrl]="uploadUrl" (fileUploaded)="onFileUploaded($event)" [autoUpload]="true"></file-upload>
    </div>
    <div class="address">
        <div class="fields">
            <div class="field">
                <label for="base-street">Addresse</label>
                <input id="base-street" name="base-street" required [(ngModel)]="settings.baseAddress.street" />
            </div>
            <div class="field">
                <label for="base-zip">Postnr.</label>
                <input id="base-zip" name="base-zip" required [(ngModel)]="settings.baseAddress.zip" />
            </div>
            <div class="field">
                <label for="base-city">By</label>
                <input id="base-city" name="base-city" required [(ngModel)]="settings.baseAddress.city" />
            </div>
        </div>
    </div>
    <button type="submit" class="btn btn-default blue" [disabled]="!infoForm.form.valid">Gem oplysninger</button>
</form>

<div #videoContainer class="intro">
    <h2>Introvideo</h2>
    <p class="intro">
        Her kan du angive adressen på en video på YouTube eller Vimeo. Et link til videoen inkluderes i den første mail, som nye brugere får tilsendt.
    </p>
    <div class="video">
        <div class="preview">
            <input type="url" name="introvideourl" [(ngModel)]="settings.introVideoUrl" (ngModelChange)="videoUrlChanged()" placeholder="F.eks. https://www.youtube.com/watch?v=XXXXXXXX" />
            <button class="" (click)="saveVideo()" [disabled]="!videoChanged">Gem video</button>
            <embed-viewer [url]="settings.introVideoUrl" *ngIf="settings.introVideoUrl.length > 0"></embed-viewer>
        </div>

        <div class="help">
            <h3>Opret en video</h3>
            <p>Du skal oprette en konto hos <a href="https://youtube.com" target="_blank">YouTube</a> eller <a href="https://vimeo.com" target="_blank">Vimeo</a> for at komme i gang.</p>
            <p>Herefter uploader du videoen til YouTube eller Vimeo. Husk at markere den som skjult, så den ikke kan findes.</p>
            <p>Kopier herefter adressen til videoen og sæt den ind her.</p>
        </div>
    </div>
</div>