<div class="casedetails addemployee">

    <div *ngIf="authService.isAdmin">
        <div class="casedetailpanel">
            <form (ngSubmit)="onSubmitUpdateEmployee()" #updateEmpForm="ngForm">

                <div class="form-group">
                    <label for="employeeUserName">Brugernavn</label>
                    <input type="text" class="form-control" id="employeeUserName" [(ngModel)]="newEmployee.userName" name="employeeUserName" required>
                </div>

                <div class="form-group">
                    <label for="employeePassword">Kodeord</label>
                    <input type="text" class="form-control" id="employeePassword" [(ngModel)]="newEmployee.password" name="employeePassword" required>
                </div>

                <div class="form-group">
                    <label for="employeeEmail">Email</label>
                    <input type="text" class="form-control" id="employeeEmail" [(ngModel)]="newEmployee.email" name="employeeEmail" required>
                </div>

                <div class="form-group">
                    <label for="employeeName">Navn</label>
                    <input type="text" class="form-control" id="employeeName" [(ngModel)]="newEmployee.fullName" name="employeeName" required>
                </div>

                <div class="form-group">
                    <label for="employeeDisplayName">Initialer</label>
                    <input type="text" class="form-control" id="employeeDisplayName" [(ngModel)]="newEmployee.displayName" name="employeeDisplayName" required maxlength="3" #employeeDisplayName="ngModel">
                    <div *ngIf="employeeDisplayName.invalid && (employeeDisplayName.dirty || employeeDisplayName.touched)"
                         class="validation-error">
                        <div *ngIf="employeeDisplayName.errors!['maxlength']">
                            Initialer kan maks. være 3 tegn
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="employeeOccupation">Stilling</label>
                    <input type="text" class="form-control" id="employeeOccupation" [(ngModel)]="newEmployee.occupation" name="employeeOccupation">
                </div>

                <div class="form-group">
                    <label for="employeePhone">Telefon</label>
                    <input type="text" class="form-control" id="employeePhone" [(ngModel)]="newEmployee.phoneNumber" name="employeePhone">
                </div>

                <div class="form-group">
                    <label for="employeeCellphone">Mobil</label>
                    <input type="text" class="form-control" id="employeeCellphone" [(ngModel)]="newEmployee.cellphoneNumber" name="employeeCellphone">
                </div>

                <div class="buttons">
                    <button type="submit" class="btn btn-default">Opret</button>
                    <button type="button" class="btn" (click)="onCancel()">Annuller</button>
                </div>
            </form>
        </div>
    </div>
</div>
