<div class="category-list">
    <div class="list">
        <ul *ngIf="vehicles && vehicles.length > 0; else empty">
            <li *ngFor="let v of vehicles" [ngClass]="{editing: v.editing}" [@slideUpDownList]="v.state">
                <ng-container *ngIf="!v.editing; else edit">
                    <a [routerLink]="['/vehicles', v.id]">{{v.registrationNumber}} {{v.shortName}} </a>
                    <div class="actions">
                        <button (click)="editItem(v)" title="Rediger"><i class="fa fa-pencil fa-lg"></i><span class="visuallyhidden"></span></button>
                        <button (click)="delete(v)" title="Slet"><i class="fa fa-trash fa-lg"></i><span class="visuallyhidden"></span></button>
                    </div>
                </ng-container>
                <ng-template #edit>
                    <form (ngSubmit)="editform.form.valid && save(v)" #editform="ngForm">
                        <div class="field">
                            <label for="registrationNumber">Registreringsnummer</label>
                            <input type="text" id="registrationNumber" name="registrationNumber" [(ngModel)]="v.registrationNumber" required />
                        </div>
                        <div class="field">
                            <label for="shortName">Kort navn</label>
                            <input type="text" id="shortName" name="shortName" [(ngModel)]="v.shortName" required />
                        </div>
                        <div class="field">
                            <label for="longName">Langt navn</label>
                            <input type="text" id="longName" name="longName" [(ngModel)]="v.longName" required />
                        </div>
                        <div class="actions last">
                            <button (click)="cancelEdit(v)" title="Annuller"><i class="fa fa-times fa-lg"></i><span class="visuallyhidden"></span></button>
                            <button type="submit" title="Gem"><i class="fa fa-save fa-lg"></i><span class="visuallyhidden"></span></button>
                        </div>
                    </form>
                </ng-template>
            </li>
        </ul>

        <ng-template #empty>
            <div class="empty-placeholder">
                Ingen biler
            </div>
        </ng-template>

        <div class="buttons">
            <button (click)="create()"><i class="fa fa-plus"></i> Opret bil</button>
        </div>
    </div>
</div>
