import { Component, Inject, OnInit } from '@angular/core';
import { Equipment } from '../../../interfaces/equipment/equipment';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogData } from '../../../interfaces/ui/confirm-dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-report-failure',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './report-failure.component.html',
  styleUrl: './report-failure.component.scss'
})
export class ReportFailureComponent  implements OnInit {
  equipment!: Equipment;
  dialogData!: ConfirmDialogData;
  title!:string;
  message!:string;
  comments: string = "";

  constructor(
    public dialogRef: MatDialogRef<ReportFailureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
    private authService: AuthenticationService) {

      // this.equipment = dialog.context.equipment;
  }

  ngOnInit(): void {
  }

  onConfirm(): void {
    this.dialogRef.close(this.comments);
  }

  onDismiss(): void {
    this.dialogRef.close(false);  
  }   

  // cancel() {
  //     // this.dialog.dismiss();
  // }

  submit() {
      // this.dialog.close(this.comments);
  }
}
