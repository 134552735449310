import { Injectable } from '@angular/core';
import { ServiceBase } from '../base/service-base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EquipmentLogService extends ServiceBase {
  private baseurl = '/api/equipmentlog/';

  constructor(
    private authhttp: HttpClient) {
    super(authhttp);
  }

  saveMaterials(caseId: number, materialsText: string): Observable<boolean> {
      return this.postJson(this.baseurl + 'updatematerials', { DamageCaseId: caseId, Materials: materialsText });
  }
}
