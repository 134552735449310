export function getScrollParent(element: HTMLElement, includeHidden = false): HTMLElement | null {
    let style: CSSStyleDeclaration = getComputedStyle(element);
    const excludeStaticParent: boolean = style.position === "absolute";
    const overflowRegex: RegExp = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

    if (style.position === "fixed") return document.body;
    for (var parent = element; (parent = parent.parentElement as HTMLElement);) {
        style = getComputedStyle(parent);
        if (excludeStaticParent && style.position === "static") {
            continue;
        }
        if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
            return parent;
        }
    }

    return document.body;
}