import { Injectable } from '@angular/core';
import { AuthenticationService } from './auth/authentication.service';
import { BuilderType } from '../models/builder-type';
import { Builder } from '../models/builder';
import { ServiceBase } from './base/service-base.service';
import { AlertService } from './alert.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BuilderService extends ServiceBase {
  private baseurl = '/api/builder/';

  constructor(
    private authhttp: HttpClient,
    private authServ: AuthenticationService, alert: AlertService) {
    super(authhttp);
  }

  list(): Observable<BuilderType[]> {
      return this.getJson<BuilderType[]>(this.baseurl + "list");
  }

  addToCase(caseId: number, builderId: number): Observable<Builder> {
      return this.postJson<Builder>(this.baseurl + "addtocase", { caseId: caseId, builderId: builderId });
  }

  removeFromCase(caseId: number, builderId: number): Observable<Builder> {
      return this.postJson<Builder>(this.baseurl + "removefromcase", { caseId: caseId, builderId: builderId });
  }
}
