import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertService } from '../../services/alert.service';
import { AlertMessage } from '../../interfaces/alert-message';
import { fadeUpDown } from '../../animations/animations';

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
  animations: [fadeUpDown]
})
export class AlertComponent {
  message: AlertMessage = {
      type: 'error',
      text: ''
  };
  defaultTimeout = 5000;

  constructor(private alertService: AlertService) { }

  ngOnInit() {
      this.alertService.getMessage()
          .subscribe(message => {
              if (message) {
                  this.message = message;
                  if ((message.timeout && message.timeout > 0) || this.defaultTimeout > 0) {
                      setTimeout(() => {
                          this.message.text = '';
                      }, message.timeout ? message.timeout : this.defaultTimeout);
                  }
              }
          });
  }

  onDismiss() {
      this.message.text = '';
  }
}
