import { Component, OnInit } from '@angular/core';
import { EquipmentService } from '../../../services/equipment/equipment.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { EquipmentOverviewComponent } from "../equipment-overview/equipment-overview.component";
import { EquipmentDetailsComponent } from "../equipment-details/equipment-details.component";

@Component({
  selector: 'app-equipment',
  standalone: true,
  imports: [CommonModule, RouterModule, EquipmentOverviewComponent, EquipmentDetailsComponent],
  templateUrl: './equipment.component.html',
  styleUrl: './equipment.component.scss'
})
export class EquipmentComponent implements OnInit {
  constructor(
      private equipmentService: EquipmentService,
      private router: Router,
      private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
  }
}
