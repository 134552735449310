import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { MenuDropdownComponent } from "../ui/menu-dropdown/menu-dropdown.component";
import { DropdownItemComponent } from "../ui/dropdown-item/dropdown-item.component";
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ActionService } from '../../services/action.service';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { SearchService } from '../../services/search.service';
import { NotificationService } from '../../services/notification.service';
import { HeaderButton } from '../../interfaces/header-button';
import { Action } from '../../models/action';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { SaftyDocsComponent } from '../safty/safty-docs/safty-docs.component';
import { environment } from '../../../environments/environment';
import { ConnectionState } from '../../enums/connection-state';

@Component({
    selector: 'dr-header',
    standalone: true,
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
    imports: [CommonModule, RouterModule, MenuDropdownComponent, DropdownItemComponent, MatButtonModule, MatDialogModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  _actionService: ActionService;
  readonly dialog = inject(MatDialog);
  connectionState: ConnectionState = ConnectionState.Disconnected;

  isProduction = environment.production;
  iconColor: string = "black";
  titleText: string = "";

  // Map connection states to colors
  private readonly stateColors: { [key in ConnectionState]: string } = {
    [ConnectionState.Connecting]: 'orange',
    [ConnectionState.Connected]: 'green',
    [ConnectionState.Reconnecting]: 'yellow',
    [ConnectionState.Disconnected]: 'red',
  };

  // Map connection states to titles
  private readonly stateTitles: { [key in ConnectionState]: string } = {
    [ConnectionState.Connecting]: 'Connecting to hub...',
    [ConnectionState.Connected]: 'Connected successfully to hub!',
    [ConnectionState.Reconnecting]: 'Attempting to reconnect to hub...',
    [ConnectionState.Disconnected]: 'Disconnected from hub.',
  };

  constructor(
    public authService: AuthenticationService,
    private actionService: ActionService,
    private searchService: SearchService,
    private cdRef: ChangeDetectorRef,
    public notificationService: NotificationService,
  ) {
    this._actionService = actionService;

    this.notificationService.connectionState$
    .subscribe((state) => {
      this.connectionState = state;
      this.cdRef.detectChanges();
    });
  }

  getColor(state: ConnectionState): string {
    return this.stateColors[state];
  }

  getTitle(state: ConnectionState): string {
    return this.stateTitles[state];
  }

  action(action: string): boolean{
    this.actionService.broadcastAction(new Action(action));
    return false;
  }

  btnClick(btn: HeaderButton) {
    this.actionService.onButtonClick.emit(btn);
  }

  openSafetyDocs() {
    this.dialog.open(SaftyDocsComponent, {
      width: '90%',
      height: '65%',
      disableClose: true,
    });
  }
}
