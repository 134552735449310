<div class="measurement-log-list">
    <div class="measurement--case-summary" *ngIf="case">
        Sag nr. {{case.id}} ({{case.customer.fullName}})
    </div>
    <!-- <div *ngIf="case" class="category-list" [class.details]="!_router.isActive(_router.createUrlTree(['/measurement', case.id]), true)"> -->
    <div *ngIf="caseid" class="category-list">
        <div class="categories">
            <ul class="measurement-log-list" *ngIf="case">
                <li *ngFor="let m of case.measurementLogs">
                    <a [routerLink]="['/measurement', caseid, m.id]" routerLinkActive="active">
                    <!-- <a [routerLink]="[m.id]"> -->
                        {{m.name}}
                    </a>
                </li>
            </ul>
            <div class="create-block">
                <h2>Tilføj journal</h2>
                <form (ngSubmit)="f.form.valid && onSubmitCreate()" #f="ngForm" class="create">
                    <div class="field-group">
                        <label for="room">Rum</label>
                        <div class="input-group">
                            <input type="text" name="room" id="room" [(ngModel)]="newLog.name" required />
                            <button type="submit">Opret</button>
                        </div>
                    </div>
                </form>                             
            </div>
        </div>
        <div class="list-container">
            <measuring-point></measuring-point>
        </div>
    </div>
</div>