import { ReportBase } from "../report-base";

export class AssistanceReport extends ReportBase {
    cause!: string;
    damageDescription!: string;
    actionsPerformed!: string;
    furtherActions!: string;
    homeTotalArea!: number;
    damagedArea!: number;
}
