import { AfterViewInit, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ComponentBaseComponent } from '../../component-base/component-base.component';
import { DatePickerComponent } from "../../ui/date-picker/date-picker.component";
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CustomerSatisfactionAvg } from '../../../models/customer-satisfaction-avg';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { DamageCaseService } from '../../../services/damage-case.service';

import moment from 'moment';

@Component({
    selector: 'customer-satisfaction-graph',
    standalone: true,
    templateUrl: './customer-satisfaction-graph.component.html',
    styleUrl: './customer-satisfaction-graph.component.scss',
    imports: [DatePickerComponent, CommonModule, FormsModule]
})
export class CustomerSatisfactionGraphComponent extends ComponentBaseComponent implements OnInit, OnChanges, AfterViewInit {
  dateto!: Date;
  datefrom!: Date;

  // lineChart
  public lineChartData: Array<any> = [
      { data: [], label: 'Gennemsnit' }
  ];
  public lineChartLabels: Array<any> = ['','', '', '', '', '', ''];
  public lineChartOptions: any = {
      responsive: true,
      scales: {
          yAxes: [{
              ticks: {
                  max: 5,
                  min: 0,
                  stepSize: 1
              }
          }]
      }

  };
  public lineChartColors: Array<any> = [
      { // dr-green
          backgroundColor: 'rgba(0,180,120,0.2)',
          borderColor: 'rgba(0,180,120,1)',
          pointBackgroundColor: 'rgba(0,180,120,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)'
      }
  ];
  public lineChartLegend: boolean = false;
  public lineChartType: string = 'line';


  // events
  public chartClicked(e: any): void {
      //console.log(e);
  }

  public chartHovered(e: any): void {
      //console.log(e);
  }

  public updateChartData(data: CustomerSatisfactionAvg[]): void {
      let newLabels = [];
      let newData = [];
      for (let item of data) {
          newLabels.push(moment(item.date).format("D-MM"));
          newData.push(item.averageRating);
      }
      this.lineChartData = [{ data: newData, label: 'Gennemsnit' }];
      this.lineChartLabels = newLabels.slice();

  }

  constructor(auth: AuthenticationService,
      private damCaseService: DamageCaseService
  ) {
      super(auth);
  }

  override ngOnInit() {
    this.dateto = new Date();
    this.datefrom = moment(this.dateto).subtract(6, 'days').toDate();

    this.damCaseService.getRatings(this.datefrom, this.dateto)
      .subscribe({
        next: (result) => {
          this.updateChartData(result);
        },
        error: (err) => {
          // Handle errors if needed
        },
        complete: () => {
          // Handle completion if needed
        }
      });

    // this.damCaseService.getRatings(this.datefrom, this.dateto).then(result => this.updateChartData(result));
  }

  dateFromChange() {
    this.dateto = moment(this.datefrom).add(6, 'days').toDate();
    
    this.damCaseService.getRatings(this.datefrom, this.dateto)
      .subscribe({
        next: (result) => {
          this.updateChartData(result);
        },
        error: (err) => {
          // Handle errors if needed
        },
        complete: () => {
          // Handle completion if needed
        }
      });
    
    // this.damCaseService.getRatings(this.datefrom, this.dateto).then(result => this.updateChartData(result));
  }

  dateToChange() {
    this.datefrom = moment(this.dateto).subtract(6, 'days').toDate();

    this.damCaseService.getRatings(this.datefrom, this.dateto)
      .subscribe({
        next: (result) => {
          this.updateChartData(result);
        },
        error: (err) => {
          // Handle errors if needed
        },
        complete: () => {
          // Handle completion if needed
        }
      });    
    // this.damCaseService.getRatings(this.datefrom, this.dateto).then(result => this.updateChartData(result));
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngAfterViewInit() {

  }
}
