import { Address } from "./address";

export class Customer {
    userName!: string;
    password!: string | null;
    fullName!: string;
    id!: string;
    address: Address;
    phoneNumber!: string;
    cellphoneNumber!: string;
    email!: string;
    customerIsIndustry!: boolean;
    customerCompanyName!: string;
    companyId!: number;
    secondEmail!: string;

    constructor() {
        this.address = new Address();
    }
}
