import { IDatePickerOptions } from "../../interfaces/ui/idate-picker-options";

export class DatePickerOptions {
    autoApply!: boolean;
    style!: 'normal' | 'big' | 'bold';
    locale!: string;
    minDate!: Date | undefined;
    maxDate!: Date | undefined;
    initialDate!: Date | undefined;
    firstWeekdaySunday!: boolean;
    format!: string;

    constructor(obj?: IDatePickerOptions) {
        this.autoApply = (obj && obj.autoApply === true) ? true : true;
        this.style = obj && obj.style ? obj.style : 'normal';
        this.locale = obj && obj.locale ? obj.locale : 'da';
        this.minDate = obj && obj.minDate ? obj.minDate : undefined;
        this.maxDate = obj && obj.maxDate ? obj.maxDate : undefined;
        this.initialDate = obj && obj.initialDate ? obj.initialDate : undefined;
        this.format = obj && obj.format ? obj.format : 'YYYY-MM-DD';
    }
}
