import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SampleItemRoom } from '../../../../interfaces/sample-item-room';
import { SampleItem } from '../../../../interfaces/sample-item';
import { AuthenticationService } from '../../../../services/auth/authentication.service';
import { SampleItemsService } from '../../../../services/sample-items.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'samplelist',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sample-list.component.html',
  styleUrl: './sample-list.component.scss'
})
export class SampleListComponent  implements OnInit {
  public rooms: SampleItemRoom[] = [];

  @Output()  public add: EventEmitter<SampleItem> = new EventEmitter<SampleItem>();

  constructor(
    private authService: AuthenticationService, 
    private sampleItemService: SampleItemsService) {
  }

  ngOnInit(): void {
    this.sampleItemService.listRooms()
      .subscribe(x => {
        this.rooms = x.filter(x => x.items!.length > 0);        
        //this.addRandomSamples(50);
      });
  }

  public itemClick(item: SampleItem) {
    console.log("itemClick");
    this.add.emit(item);
  }

  addRandomSamples(count: number): void {
    const startingId = this.rooms.length ? this.rooms[this.rooms.length - 1].id + 1 : 1;

    for (let i = 0; i < count; i++) {
      this.rooms.push({
        name: `Sample ${startingId + i}`, // Generate unique names
        id: startingId + i, // Generate unique IDs
        created: new Date(new Date().getTime() - Math.floor(Math.random() * 1e10)), // Random past date
      });
    }
  }
}
