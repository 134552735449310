import { Injectable } from '@angular/core';
import { ReportBase } from '../models/report-base';
import { ServiceBase } from './base/service-base.service';
import { AlertService } from './alert.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReportType } from '../interfaces/report-type';

@Injectable({
  providedIn: 'root'
})
export class ReportsService extends ServiceBase {
  private baseUrl = '/api/report/';
  private baseUrlDamageCaseReport = '/api/damagecase/generatepdfreport';
  private baseUrlReplacementReport = '/api/compensationclaim/report';

  constructor(authhttp: HttpClient, alert: AlertService) {
      super(authhttp);
  }


  getReportForCase(caseId: number): Observable<ReportBase> {
      return this.getJson<ReportBase>(this.baseUrl + "casereport/" + caseId);
  }

  getReplacementReportForCase(caseId: number): Observable<Blob> {
      return this.http.get(`${this.baseUrlReplacementReport}/${caseId}`, { responseType: 'blob' });
  }

  generatePdfReport(id: number): Observable<Blob> {
    return this.http.get(`${this.baseUrlDamageCaseReport}/${id}`, { responseType: 'blob' });
  }
}
