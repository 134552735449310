<div [ngClass]="{ 'alert': data, 'alert-success': data.type === 'success', 'alert-danger': data.type === 'error' }">
<mat-card class="alert" [ngClass]="{ 'alert-success': data.type === 'success', 'alert-danger': data.type === 'error' }">
    <mat-card-title>Advarsel</mat-card-title>
    <mat-card-content>
        <div *ngIf="data" [ngClass]="{ 'alert-success': data.type === 'success', 'alert-danger': data.type === 'error' }">
            {{data.text}}
        </div>       
    </mat-card-content>
</mat-card>
</div>
