import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { UploadComponent } from '../../upload/upload.component';
import { Settings } from '../../../interfaces/settings/settings';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { SettingsService } from '../../../services/settings/settings.service';
import { ApiResult } from '../../../models/api-result';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EmbedViewerComponent } from '../../ui/embed-viewer/embed-viewer.component';
import { FileUploadedArgsComponent } from '../../file-uploaded-args/file-uploaded-args.component';
import { FileUploadModule } from 'ng2-file-upload';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'companysettings',
  standalone: true,
  imports: [CommonModule, FormsModule, UploadComponent, EmbedViewerComponent, FileUploadModule],
  templateUrl: './company-settings.component.html',
  styleUrl: './company-settings.component.scss'
})
export class CompanySettingsComponent implements OnInit, OnChanges, AfterViewInit  {
  @ViewChild(UploadComponent) public upload!: UploadComponent;
  @ViewChild(EmbedViewerComponent, { read: ElementRef }) childComponentRef!: ElementRef;
  @ViewChild('videoContainer') videoContainer!: ElementRef;
  
  uploadUrl!: string;

  public logoUrl: string = "";
  public hasLogo: boolean = false;
  public changeLogo: boolean = false;
  public videoChanged: boolean = false;

  @Input() public settings!: Settings;
  @ViewChild('tabHeader', { static: true }) tabHeader!: ElementRef;

  constructor(
    private authService: AuthenticationService, 
    private adminSetService: SettingsService,
    private renderer: Renderer2) { }
    
  ngOnInit(): void {
    this.uploadUrl = "/api/admin/settings/" + this.authService.companyId + "/uploadlogo";
    this.setLogoUrl();
    
  }
    
  ngAfterViewInit(): void {
    const childHeight = this.childComponentRef.nativeElement.offsetHeight;
    const videoContainerHeight = this.videoContainer.nativeElement.offsetHeight;

    // Dynamically set the height of the parent container based on the child's height
    this.renderer.setStyle(this.videoContainer.nativeElement, 'height', `${childHeight+videoContainerHeight}px`);
  }

  ngOnChanges(changes: SimpleChanges): void {
      //We need to set the logo URL here, since settings are loaded async and won't be loaded in ngOnInit

      for (let c in changes) {
          if (c == "settings") {
              this.setLogoUrl();
          }
      }
  }

  onFileSelected(event: FileUploadedArgsComponent) {
    console.log("event: ", event);
  }

  onFileUploaded(event: FileUploadedArgsComponent) {
      const result: ApiResult<string> = event.getValue<ApiResult<string>>();
      console.log("result: ", result);
      const logofilename: string = result.result;


      this.settings.logoFileName = logofilename;
      this.changeLogo = false;
      this.setLogoUrl();
  }

  onChangeLogo(event: MouseEvent) {
      event.preventDefault();
      this.changeLogo = true;
  }

  private setLogoUrl() {
    // console.log("logoFileName: ", this.settings.logoFileName);

    if (this.settings.logoFileName != undefined && this.settings.logoFileName != "") {
        this.logoUrl =  environment.config.basePathLogo + "/upload/graphics/" + this.authService.companyId + "/" + this.settings.logoFileName;
        this.hasLogo = true;
    }
    else {
        this.logoUrl = "";
        this.hasLogo = false;
    }
  }


  public saveVideo() {
      this.adminSetService.saveAdminSettings(this.settings)
        .subscribe(x => {
          this.videoChanged = false;
        })
  }

  public videoUrlChanged() {
      this.videoChanged = true;
  }

  public saveInfo() {
      this.adminSetService.saveAdminSettings(this.settings);
  }


}
