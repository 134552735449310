import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICaseComponent } from '../../../interfaces/icase-component';
import { DamageCase } from '../../../models/damage-case';
import { ReportType } from '../../../interfaces/report-type';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { ReportsService } from '../../../services/reports-service.service';
import { DamageCaseService } from '../../../services/damage-case.service';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CloudBurstReportFormComponent } from '../cloud-burst-report-form/cloud-burst-report-form.component';
import { AssistanceReportFormComponent } from '../assistance-report-form/assistance-report-form.component';
import { ReportBase } from '../../../models/report-base';
import { StormReportFormComponent } from '../storm-report-form/storm-report-form.component';
import { reportTypes } from '../../../constants/report.const';

@Component({
  selector: 'create-report',
  standalone: true,
  imports: [CommonModule, FormsModule, CloudBurstReportFormComponent, AssistanceReportFormComponent, StormReportFormComponent],
  templateUrl: './create-report.component.html',
  styleUrl: './create-report.component.scss'
})
export class CreateReportComponent implements OnInit, ICaseComponent {
  @Input() case!: DamageCase;
  @Output() isCreateAssistancerapport = new EventEmitter<boolean>();

  // case!: DamageCase;
  reportTypes!: ReportType[];
  currentReportType!: ReportType;
  caseId!: number;

  constructor(public authService: AuthenticationService,
      private reportsService: ReportsService,
      private dcService: DamageCaseService,
      private route: ActivatedRoute,
      private router: Router
  ) {
      this.reportTypes = reportTypes;
      
      // this.route.params.pipe(
      //   switchMap(params => this.dcService.details(params["caseid"]))
      // ).subscribe(r => {
      //   this.case = r;
      //   if (this.case.hasReport) {
      //     this.router.navigate(["case", this.case.id]);
      //   }
      // });

      // this.route.params
      //     .switchMap(params => this.dcService.details(params["caseid"]))
      //     .subscribe(r => {
      //         this.case = r;
      //         if (this.case.hasReport) {
      //             //If we for some reason land here with a case that already has a report (with unique routes, this can happen when refreshing), redirect to case details
      //             this.router.navigate(["case", this.case.id]);
      //         }
      //     });
  }

  ngOnInit() {
    if (!this.case) {
      const rawUrl: string = this.router.url;  // This gives the full URL path
      this.caseId = this.extractCaseIdFromUrl(rawUrl) as number; 
      console.log(`Extracted Case ID: ${this.caseId}`);

      this.dcService.details(this.caseId)
        .subscribe(r => {
          if (r) {
            this.case = r;
            console.log("hasReport: ", this.case.hasReport);
            if (this.case.hasReport) {
              this.router.navigate(["case", this.case.id]);
            }
          }
        });
    } else {
        this.caseId = this.case.id;
    }
  }

  createReport(event: MouseEvent, type: ReportType): void {
    this.currentReportType = type;
  }

  onReportCreated(r: ReportBase): void {
    console.log("onReportCreated(create)")
    this.case.hasReport = true;
    this.isCreateAssistancerapport.emit(false);
    this.router.navigate(["case", this.case.id]);
  }

  onReportCancelled(r: ReportBase): void {
    console.log("onReportCancelled");
    this.isCreateAssistancerapport.emit(false);

    this.dcService.details(this.case.id)
      .subscribe((dc: DamageCase) => this.router.navigate(["case", dc.id]));
    // this.router.navigate(["case", this.case.id]);
  }

  private extractCaseIdFromUrl(url: string): number | null {
    const match = url.match(/\/case\/(\d+)\/createreport/); // Regular expression to match /case/:caseId/report
    return match ? Number(match[1]) : null; // Return caseId as a number if matched, else null
  }
}
