import { Component, OnInit } from '@angular/core';
import { Employee } from '../../../models/employee';
import { EmployeesService } from '../../../services/employees/employees.service';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-add-employee',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './add-employee.component.html',
  styleUrl: './add-employee.component.scss'
})
export class AddEmployeeComponent implements OnInit  {
  newEmployee!: Employee;

  constructor(private employeesService: EmployeesService,
      public authService: AuthenticationService,
      private router: Router) { }

  ngOnInit(): void {
      this.newEmployee = new Employee();
  }

  onSubmitUpdateEmployee() {
      this.employeesService.createEmployee(this.newEmployee)
          .subscribe(dc => {
              this.router.navigate(['employees']);
          });
  }

  onCancel() {
      this.router.navigate(['employees']);
  }
}
