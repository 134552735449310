import { Injectable } from '@angular/core';
import { Customer } from '../models/customer';
import { Observable } from 'rxjs';
import { ServiceBase } from './base/service-base.service';
import { AlertService } from './alert.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RegisterService  extends ServiceBase {

  constructor(http: HttpClient, alertService: AlertService) {
      super(http);
  }


  registerCustomer(customer: Customer): Observable<Customer> {
      return this.postJson("/api/register/customer", customer);
  }

  isUsernameAvailable(username: string): Observable<boolean> {
      let data = {
          username: username
      };
      return this.postJson<boolean>("/api/register/checkusername", data);
  }
}
