
<div class="details">
    <div class="registration">{{vehicle.registrationNumber}}</div>
    <div class="description">{{vehicle.longName}}</div>
</div>
<ul *ngIf="employees && employees.length > 0; else empty">
    <li *ngFor="let e of employees">
        {{e.fullName}}
    </li>
</ul>
<ng-template #empty>Ingen medarbejdere tilknyttet</ng-template>