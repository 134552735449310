import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, EventEmitter, Input, KeyValueChangeRecord, KeyValueDiffers, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DamageCase } from '../../../models/damage-case';
import { Company } from '../../../interfaces/company';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { DamageCaseService } from '../../../services/damage-case.service';
import { ActionService } from '../../../services/action.service';
import { CompanyService } from '../../../services/company.service';
import { ComponentBaseComponent } from '../../component-base/component-base.component';
import { ICaseComponent } from '../../../interfaces/icase-component';
import { Action } from '../../../models/action';
import { Address } from '../../../models/address';
import { MomentPipe } from '../../../pipes/moment.pipe';
import { CommonModule } from '@angular/common';
import { MomentDiffPipe } from '../../../pipes/moment-diff.pipe';
import { CaseCheckinComponent } from '../case-checkin/case-checkin.component';
import { CaseAppointmentListComponent } from '../case-appointment-list/case-appointment-list.component';
import { CaseDetailsReportsComponent } from '../case-details-reports/case-details-reports.component';
import { CaseVehicleListComponent } from '../../vehicles/case-vehicle-list/case-vehicle-list.component';
import { CaseNoteListComponent } from '../case-note-list/case-note-list.component';
import { ReportsService } from '../../../services/reports-service.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { EquipmentLogComponent } from '../../equipment/equipment-log/equipment-log.component';
import { DrivingDirectionsComponent } from '../driving-directions/driving-directions.component';
import { CaseDetailsAttachmentsComponent } from '../case-details-attachments/case-details-attachments.component';
import { CaseDetailsCompensationComponent } from '../case-details-compensation/case-details-compensation.component';
import { UntrackedEquipmentComponent } from '../../equipment/untracked-equipment/untracked-equipment.component';
import { CaseDetailsMeasurementComponent } from "../../measurement/case-details-measurement/case-details-measurement.component";
import { CaseBuilderListComponent } from "../case-builder-list/case-builder-list.component";
import { AbsPipe } from "../../../pipes/abs.pipe";
import { CustomerSatisfactionComponent } from "../../customers/customer-satisfaction/customer-satisfaction.component";
import { ConfirmDialogComponent } from '../../ui/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-case-details',
  standalone: true,
  imports: [MomentPipe,
    CommonModule,
    MomentDiffPipe,
    RouterModule,
    CaseCheckinComponent,
    CaseAppointmentListComponent,
    CaseDetailsReportsComponent,
    CaseVehicleListComponent,
    CaseNoteListComponent,
    ScrollingModule,
    MatButtonModule,
    MatDialogModule,
    DrivingDirectionsComponent,
    CaseDetailsAttachmentsComponent,
    CaseDetailsCompensationComponent,
    UntrackedEquipmentComponent, CaseDetailsMeasurementComponent, CaseBuilderListComponent, AbsPipe, CustomerSatisfactionComponent],
  templateUrl: './case-details.component.html',
  styleUrl: './case-details.component.scss',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CaseDetailsComponent extends ComponentBaseComponent implements OnInit, OnChanges, DoCheck, ICaseComponent {
  @Input() case!: DamageCase;
  @Output() updated: EventEmitter<DamageCase> = new EventEmitter<DamageCase>();
  @Output() deleted: EventEmitter<void> = new EventEmitter<void>();
       
  public company!: Company;
  caseQueueNumber!: number;
  caseId! : number;
  differ: any;  
  currentDate = new Date();
  receivedData: string | undefined;

  constructor(
    auth: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private damCaseService: DamageCaseService,
    private reportService: ReportsService,
    private actionService: ActionService,
    private companyService: CompanyService,
    differs: KeyValueDiffers) {
        super(auth);
        this.differ = differs.find({}).create();
        this.caseId = this.route.snapshot.params['caseid'];
  }

  action(action: string): boolean {
      this.actionService.broadcastAction(new Action(action));
      return false;
  }

  ngOnChanges(changes: SimpleChanges) {
    // triggers before this.case is updated
    if (changes['selectedDamageCase']) {
        const newCase: DamageCase = changes['selectedDamageCase'].currentValue as DamageCase;
    
        // Use newCase instead of this.case
        this.updateCanClose(newCase);
    }
  }

  ngDoCheck() {
      const changes = this.differ.diff(this.case);

      if (changes) {
          //console.log('changes detected');
          changes.forEachChangedItem((r: any) => {
              this.processChanges(r);
          });
          changes.forEachAddedItem((r: any) => {
              this.processChanges(r);
          });
          changes.forEachRemovedItem((r: any) => this.processChanges(r));
      } else {
          //console.log('nothing changed');
      }
  }

  downloadPdfReport(id: number) {
    this.reportService.generatePdfReport(id)
        .subscribe(blob => {
            const url = window.URL.createObjectURL(blob);
            window.open(url); // Open in a new window/tab
            window.URL.revokeObjectURL(url); // Cleanup the URL after opening
        });
  }

  private processChanges(change: KeyValueChangeRecord<string, string>) {
      if (change.key === "employees" || change.key === "notes") {
        this.updateCanClose(this.case);
      }
  }

  private updateCanClose(damageCase: DamageCase) {
      if (damageCase.vehicles && damageCase.notes) {
          if ((damageCase.damageStatusId == 2 || damageCase.damageStatusId == 4) && damageCase.vehicles.length > 0 && damageCase.notes.length > 0) {
              this.damCaseService.checkCanClose(this.case)
                    .subscribe((response: any) => {
                        if (response.success) {
                            this.case.canClose = response.result;
                            this.cdRef.detectChanges();
                        }
                    });
          }
          else {
              this.case.canClose = false;
              console.log("this.case.canClose: false");
              this.cdRef.detectChanges();
          }
      }
  }

  closeCase(): void {
    if (this.case.canClose) {
        const dialogRefCloseCase = this.dialog.open(ConfirmDialogComponent, {
            width: "80%",
            maxWidth: "450px",
            disableClose: true,
            data: {
            title: "Sag",
            noButton: "Annuller",
            yesButton: "Luk sag",
            message: `Vil du lukke denne sag?`
        }});

        dialogRefCloseCase.afterClosed()
            .subscribe((dialogResult: boolean) => {
                if (dialogResult == true) {
                    this.case.damageStatusId = 3;
                    this.case.damageStatusName = 'lukket';
                    this.damCaseService.update(this.case)
                        .subscribe(() => this.updated.emit(this.case));
                } 
            });
    }
  }

  // Mulighed for på et senere tidspunk at benytte stastus genåbnet (4) som en separat status.
  // I denne version bliver sagen blot åbnet (2) igen, uden yderligere registrering på sagen.
  reopenCase(): void {
      if (this.case.damageStatusId == 3) {
        const dialogRefReOpenCase = this.dialog.open(ConfirmDialogComponent, {
            width: "80%",
            maxWidth: "450px",
            disableClose: true,
            data: {
            title: "Sag",
            noButton: "Annuller",
            yesButton: "Genåbn sag",
            message: `Vil du genåbne denne sag?`
        }});
    
        dialogRefReOpenCase.afterClosed()
            .subscribe((dialogResult: boolean) => {
                if (dialogResult == true) {
                    this.case.damageStatusId = 2;
                    this.case.damageStatusName = 'åben';
                    this.damCaseService.update(this.case)
                        .subscribe(() => this.updated.emit(this.case));
                } 
            });
      }
  }

  openEquipmentLog() {
    this.dialog.open(EquipmentLogComponent, {
        width: '90%',
        disableClose: true,
        data: this.case, 
      });
  }

  childUpdated() {
      this.updateCanClose(this.case);
      this.updated.emit(this.case);
  }


  delete() {
    const dialogRefReDeleteCase = this.dialog.open(ConfirmDialogComponent, {
        width: "80%",
        maxWidth: "450px",
        disableClose: true,
        data: {
        title: "Sag",
        noButton: "Annuller",
        yesButton: "Slet sag",
        message: `Vil du slette denne sag?`
    }});

    dialogRefReDeleteCase.afterClosed()
        .subscribe((dialogResult: boolean) => {
            if (dialogResult == true) {
                this.damCaseService.delete(this.case)
                    .subscribe((response: any) => {
                        console.log("response delete: ", response);
                        this.deleted.emit();
                    });
            } 
        });    
  }

  goToList() {
      this.router.navigate(["/"]);
  }

  public isAddressIdentical(a: Address, b: Address): boolean {
      return a.city === b.city && a.zip === b.zip && a.country === b.country && a.street === b.street;
  }
}
