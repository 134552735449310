
<div class="login resetpassword">
    <img class="logo" src="assets/images/dr_logo_white.svg" />

    <div class="content">
        <ng-container *ngIf="!submitted">
            <h2>Skift kodeord</h2>
            <form name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate class="loginform">
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !f_password.valid }">
                    <label for="password">Nyt kodeord</label>
                    <input type="password" class="form-control" id="password" name="password" [(ngModel)]="password" #f_password="ngModel" required />
                    <div *ngIf="f.submitted && !f_password.valid" class="help-block">Du skal angive et kodeord</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !f_confirmpassword.valid }">
                    <label for="confirmpassword">Bekræft kodeord</label>
                    <input type="password" class="form-control" id="confirmpassword" name="confirmpassword" [(ngModel)]="confirmpassword" #f_confirmpassword="ngModel" required (equalTo)="f_password" />
                    <div *ngIf="f_confirmpassword.errors">
                        <div *ngIf="f.submitted && f_confirmpassword.errors['required']" class="help-block">Du skal angive et kodeord</div>
                        <p *ngIf="f.submitted && f_confirmpassword.errors['equalTo']">De to passwords skal være ens</p>
                    </div>
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-primary">Skift kodeord</button>
                </div>
                <div *ngIf="error" class="alert alert-danger">{{error}}</div>
            </form>
        </ng-container>
        <ng-container *ngIf="submitted">
            <p>Dit kodeord er ændret.</p>
            <p><a [routerLink]="['/login']">Log ind nu</a></p>
        </ng-container>
    </div>
</div>
