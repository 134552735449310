<ng-container *ngIf="(!type || type?.trackIndividually) || typeid === null; else nottracked">
    <div class="content-container">
        <div class="search">
            <label class="visuallyhidden" for="search"></label>
            <input type="search" id="search" placeholder="Søg efter udstyr" [formControl]="search" />
        </div>
        <div class="list">
            <ul *ngIf="equipment && equipment.length > 0; else empty">
                <li *ngFor="let e of equipment" [ngClass]="{editing: e.editing}" [@slideUpDownList]="e.state">
                    <ng-container *ngIf="!e.editing; else edit">
                        <a [routerLink]="['/equipment/details', e.id]">{{e.number}} {{e.name}}</a>
                        <div class="label active" *ngIf="e.isActive">I brug</div>
                        <div class="label failed" *ngIf="e.failed">Fejlmeldt</div>
                        <div class="actions">
                            <button (click)="editItem(e)" title="Rediger"><i class="fa fa-pencil fa-lg"></i><span class="visuallyhidden"></span></button>
                            <button (click)="delete(e)" title="Slet"><i class="fa fa-trash fa-lg"></i><span class="visuallyhidden"></span></button>
                        </div>
                    </ng-container>
                    <ng-template class="edit" #edit>
                        <form (ngSubmit)="editform.form.valid && save(e)" #editform="ngForm">
                            <div class="field">
                                <label for="number">Nummer</label>
                                <input type="text" id="number" name="number" [(ngModel)]="e.number" required />
                            </div>
                            <div class="field">
                                <label for="name">Navn</label>
                                <input type="text" id="name" name="name" [(ngModel)]="e.name" required />
                            </div>
                            <div class="actions">
                                <button (click)="cancelEdit(e)" title="Annuller"><i class="fa fa-times fa-lg"></i><span class="visuallyhidden"></span></button>
                                <button type="submit" title="Gem"><i class="fa fa-save fa-lg"></i><span class="visuallyhidden"></span></button>
                            </div>
                        </form>
                    </ng-template>
                </li>

            </ul>
            <ng-template #empty>
                <div class="empty-placeholder">
                    Intet udstyr
                </div>
            </ng-template>
            <div class="buttons">
                <button *ngIf="typeid" (click)="create()"><i class="fa fa-plus"></i> Opret udstyr</button>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #nottracked>
    <form *ngIf="type" (ngSubmit)="f.form.valid && submitCount()" #f="ngForm" class="total-form">
        <div class="field">
            <label for="total">Totalt antal:</label>
            <input type="number" name="total" id="total" [(ngModel)]="type.totalAvailable" />
        </div>
        <div class="buttons">
            <button type="submit">Gem antal</button>
        </div>
    </form>
</ng-template>
