import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { catchError, map, throwError } from 'rxjs';
import { Address } from '../../../models/address';
import { ApiError } from '../../../models/api-error';
import { Customer } from '../../../models/customer';
import { AlertService } from '../../../services/alert.service';
import { RegisterService } from '../../../services/register.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { UserNameValidatorDirective } from '../../../validations/user-name-validator.directive';

@Component({
  selector: 'app-register-customer',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule, MatCheckboxModule, UserNameValidatorDirective],
  templateUrl: './register-customer.component.html',
  styleUrl: './register-customer.component.scss'
})
export class RegisterCustomerComponent implements OnInit {
  model!: Customer;
  loading: boolean = false;

  constructor(
      private router: Router,
      private registerService: RegisterService,
      private alertService: AlertService) { }

  ngOnInit(): void {
      this.model = new Customer();
      this.model.address = new Address();
      this.model.companyId = 6;
  }

  register() {
    this.loading = true;
    this.registerService.registerCustomer(this.model)
      .pipe(
        map((data) => {
          // set success message and pass true parameter to persist the message after redirecting to the login page
          this.alertService.success('Registration successful', true);
          this.router.navigate(['/login']);
        }),
        catchError((error) => {
          let errors = error as ApiError[];
          let errorMsg = "";
          if (errors) {
            errorMsg = errors.map(err => err.message).join(". ");
          } else {
            errorMsg = 'Unknown error';
            console.log('DamRep error: ', error);
          }
          this.alertService.error(errorMsg);
          this.loading = false;
          return throwError(errorMsg);
        })
      )
      .subscribe();
  }

//   register() {
//       this.loading = true;
//       this.registerService.registerCustomer(this.model)
//           .then(
//           (data) => {
//               // set success message and pass true paramater to persist the message after redirecting to the login page
//               this.alertService.success('Registration successful', true);
//               this.router.navigate(['/login']);
//           })
//           .catch((ex) => {
//               let errors = ex as ApiError[];
//               let errorsMsg = "";
//               if (errors) {
//                   for (let err of errors) {
//                       if (errorsMsg != "") {
//                           errorsMsg += ". ";
//                       }
//                       errorsMsg += err.message;
//                   }
//               }
//               else {
//                   errorsMsg = 'Unknown error';
//                   console.log('DamRep error: ' + ex);
//               }
//               this.alertService.error(errorsMsg);
//               this.loading = false;
//           });
//   }
}
