<div class="multi-select-container">
    <div class="multi-select-summary" (click)="toggle()">
        {{summary}} <i class="fa fa-caret-down"></i>
    </div>
    <div class="multi-select-popup" *ngIf="opened" [@slideUpDown]="opened">
        <ul>
            <!--<template ngFor [ngForOf]="items" [ngForTemplate]="template">
            </template>-->
            <li *ngFor="let i of items">
                <label><input type="checkbox" [(ngModel)]="i.selected" (change)="updateSummary()" />{{i.title}}</label>
            </li>
        </ul>
    </div>
</div>