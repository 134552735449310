<h2>Flyttekasser</h2>
<div *ngIf="count">
    Udleveret: {{count.total}} stk.
</div>
<div *ngIf="type">
    Tilgængelig: {{type.countAvailable}} stk.
</div>
<div class="handout-return">
    <input name="amount" type="number" min="0" [(ngModel)]="newAmount" />
    <div class="button-group">
        <button [disabled]="!newAmount || newAmount === 0 || type.countAvailable === 0" (click)="handout()">Udlever</button>
        <button [disabled]="!newAmount || newAmount === 0 || type.countAvailable === 0 || newAmount > (count.total ?? 0)" (click)="return()">Aflever</button>
    </div>
</div>