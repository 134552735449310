import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VehiclePreviewComponent } from "../../vehicles/vehicle-preview/vehicle-preview.component";
import { CommonModule } from '@angular/common';
import { DamageCase } from '../../../models/damage-case';
import { MomentDiffPipe } from '../../../pipes/moment-diff.pipe';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { AbsPipe } from "../../../pipes/abs.pipe";

@Component({
    selector: 'case-list-item',
    standalone: true,
    templateUrl: './case-list-item.component.html',
    styleUrl: './case-list-item.component.scss',
    imports: [CommonModule, VehiclePreviewComponent, MomentDiffPipe, AbsPipe]
})
export class CaseListItemComponent implements OnInit {
  @Input() case!: DamageCase;
  currentDate: Date = new Date();

  constructor(
      public authService: AuthenticationService,
      public router: Router,
      private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
  }

}
