import { ComponentRef, ElementRef, Injectable } from '@angular/core';
import { Vehicle } from '../../interfaces/vehicles/vehicle';
import { DomService } from '../ui/dom.service';
import { VehiclePreviewPopupComponent } from '../../components/vehicles/vehicle-preview-popup/vehicle-preview-popup.component';
import { Employee } from '../../models/employee';

@Injectable({
  providedIn: 'root'
})
export class VehiclePreviewPopupService {
  isOpen!: boolean;
  private popup!: ComponentRef<VehiclePreviewPopupComponent> | null;

  constructor(
      private domService: DomService
  ) { }

  public showPopup(target: ElementRef, vehicle: Vehicle) {
      this.hidePopup();
      this.popup = this.domService.createComponentRef(VehiclePreviewPopupComponent);

      this.popup.instance.target = target;
      this.popup.instance.vehicle = vehicle;
      this.popup.instance.employees = vehicle.employees as Employee[];

      const popupElement = this.domService.getDomElementFromComponentRef(this.popup);

      this.domService.addChild(popupElement);
      this.isOpen = true;
  }

  public hidePopup() {
      if (this.isOpen && this.popup) {
          this.isOpen = false;
          this.domService.destroyRef(this.popup);
          this.popup = null;
      }
      else if (this.isOpen && !this.popup) {
          setTimeout(this.hidePopup, 200);
      }
      else if (this.popup) {
          this.domService.destroyRef(this.popup);
          this.popup = null;
          this.isOpen = false;
      }
  }
}
