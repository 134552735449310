import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { slideUpDownList } from '../../../animations/animations';
import { SampleItemRoom } from '../../../interfaces/sample-item-room';
import { SampleItem } from '../../../interfaces/sample-item';
import { Settings } from '../../../interfaces/settings/settings';
import { SampleItemsService } from '../../../services/sample-items.service';
import { orderByComparator } from '../../../helpers/order';
import { WithName } from '../../../interfaces/settings/withname.interface';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs';
import { ConfirmDialogComponent } from '../../ui/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'sampleitems',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './sample-items.component.html',
  styleUrl: './sample-items.component.scss',
  animations: [slideUpDownList],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SampleItemsComponent implements OnInit, OnChanges {
  public newRoomName: string = "";
  public newItemName: string = "";
  public rooms: SampleItemRoom[] = [];
  public selectedRoom!: SampleItemRoom;
  public items: SampleItem[] = [];
  private originalRoom!: SampleItemRoom;

  @Input() public settings!: Settings;
  
  constructor(
    public dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private sampleItemService: SampleItemsService
  ) { }

  ngOnInit(): void {
    console.log("Rooms (Init): ", this.rooms);
    this.sampleItemService.listRooms()
      .pipe(filter((result: SampleItemRoom[]) => result != null))
      .subscribe((rooms: SampleItemRoom[]) => {
        // Sort rooms and their items in a single pass
        this.rooms = rooms.map(room => ({
            ...room,
            items: this.orderByName(room.items as SampleItem[])
        }));
    
        // Sort the rooms array itself
        this.rooms = this.orderByName(this.rooms);

        this.cdRef.detectChanges();
      });
  }


  ngOnChanges(changes: SimpleChanges): void {
      //We need to set the logo URL here, since settings are loaded async and won't be loaded in ngOnInit
  }

  createRoom() {
    if (this.newRoomName == "") {
        return;
    }

    this.sampleItemService.createRoom(this.newRoomName)
        .subscribe((r: SampleItemRoom)  => {
            r.state = "add";
            this.rooms.push(r);
            this.orderByName(this.rooms);
            this.newRoomName = "";
        });
  }

  selectRoom(room: SampleItemRoom) {
    //We reset state to null so that it doesn't replay the add animation when changing selected room
    room.items = room.items!.map(i => { i.state = undefined; return i; });
    this.selectedRoom = room;
  }

  createItem() {
      if (this.newItemName == "") {
          return;
      }
      this.sampleItemService.create(this.newItemName, this.selectedRoom.id)
        .subscribe((i: any) => {
          i.state = "add";
          this.selectedRoom.items!.push(i.result);
          this.orderByName(this.selectedRoom.items!);
          this.newItemName = "";

          this.cdRef.detectChanges();
        });
  }

  deleteItem(item: SampleItem) {
      item.state = "remove";
      this.sampleItemService.delete(item)
        .subscribe((i: SampleItem) => {
          const index: number = this.selectedRoom.items!.findIndex(x => x.id == item.id);
          this.selectedRoom.items!.splice(index, 1);

          this.cdRef.detectChanges();
      });
  }

  deleteRoom(room: SampleItemRoom) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: "80%",
        maxWidth: "450px",
        data: {
          title: "Rum",
          noButton: "NEJ",
          yesButton: "JA",
          message: "Vil du slette dette rum? Det sletter også alle punkter i dette rum."
        }
      })

    dialogRef.afterClosed()
        .subscribe((dialogResult: boolean) => {
            if (dialogResult == true) {
                room.state = "remove";
                if (this.selectedRoom == room) {
                    this.selectedRoom = {
                        name: '',
                        items: []
                    } as unknown as SampleItemRoom;

                    console.log("Empty SelectedRoom: ", this.selectRoom);
                }

                this.sampleItemService.deleteRoom(room)
                    .subscribe((i: SampleItemRoom) => {
                        console.log("SampleItemRoom to delete: ", i);
                        const index: number = this.rooms.findIndex(x => x.id == room.id);
                        console.log("Index: ", index);
                        this.rooms.splice(index, 1);

                        this.cdRef.detectChanges();
                    });
            } 
        });
  }

  editRoom(room: SampleItemRoom) {
    room.editing = true;
    this.originalRoom = { ...room };

    this.cdRef.detectChanges();
  }

  cancelEditRoom(room: SampleItemRoom) {
      room.editing = false;
      room.name = this.originalRoom.name;
      //delete this.originalRoom;
  }

  saveRoom(room: SampleItemRoom) {
      this.sampleItemService.updateRoom(room)
        .subscribe(r => room.editing = false);
  }

  private orderByName<T extends WithName>(items: T[]): T[] {
      return items.sort((a, b) => orderByComparator(a.name, b.name));
  }
}
