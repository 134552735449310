import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { slideUpDown } from '../../../animations/animations';
import { DatePickerOptions } from '../../../models/ui/date-picker-options';
import { DateModel } from '../../../models/ui/date-model';
import { ICalendarDate } from '../../../interfaces/ui/icalendar-date';
import { getScrollParent } from '../../../utils/getScrollParent';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import moment from 'moment';
import { datepickerOpts } from '../../../constants/date-picker.const';

@Component({
  selector: 'app-date-picker-popup',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './date-picker-popup.component.html',
  styleUrl: './date-picker-popup.component.scss',
  animations: [slideUpDown]
})
export class DatePickerPopupComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() options: DatePickerOptions;
  @Input() target!: ElementRef;
  @Input() fieldid!: string;

  @Output() dateSelected: EventEmitter<DateModel>;

  private scrollParent!: HTMLElement;
  date: DateModel;
  @HostBinding("@slideUpDown")
  opened: boolean;
  openUp: boolean = false;
  currentDate: moment.Moment;
  days: ICalendarDate[];
  years: number[];
  weekdays: string[];
  yearPicker!: boolean;
  // scrollOptions: SlimScrollOptions;

  minDate: moment.Moment | any;
  maxDate: moment.Moment | any;

  private onTouchedCallback: () => void = () => { };
  private onChangeCallback: (_: any) => void = () => { };

  constructor(
      public el: ElementRef,
      private renderer2: Renderer2) {
      moment.locale("da");
      this.opened = false;
      this.currentDate = moment();
      this.options = datepickerOpts;
      this.days = [];
      this.weekdays = [];
      this.years = [];
      this.date = new DateModel();
      this.dateSelected = new EventEmitter<DateModel>();
  }

  get value(): DateModel {
      return this.date;
  }

  set value(date: DateModel) {
      if (!date) { return; }
      this.date = date;
  }

  ngOnDestroy(): void {
      if (this.scrollParent) {
          this.scrollParent.removeEventListener("scroll", this.onScroll);
      }
  }

  ngOnInit() {
      this.updatePosition();

      if (this.target) {
          //This is pretty nasty, referencing the DOM directly in Angular, but this appears to be the only way
          //TODO: See if a better way is possible
          this.scrollParent = getScrollParent(this.target.nativeElement) as HTMLElement;
          if (this.scrollParent) {
              this.scrollParent.addEventListener("scroll", this.onScroll);
          }

      }

      this.options = new DatePickerOptions(this.options);
      moment.locale(this.options.locale);
      // this.scrollOptions = {
      //     barBackground: '#C9C9C9',
      //     barWidth: '7',
      //     gridBackground: '#C9C9C9',
      //     gridWidth: '2'
      // };

      this.generateYears();

      if (this.options.initialDate instanceof Date) {
          this.currentDate = moment(this.options.initialDate);
          this.selectDate(MouseEvent.prototype, this.currentDate);
      }

      if (this.options.minDate instanceof Date) {
          this.minDate = moment(this.options.minDate);
      } else {
          this.minDate = null;
      }

      if (this.options.maxDate instanceof Date) {
          this.maxDate = moment(this.options.maxDate);
      } else {
          this.maxDate = null;
      }

      if (this.value && this.value.momentObj) {
          this.currentDate = this.value.momentObj;
      }

      this.generateCalendar();
  }

  ngAfterViewInit(): void {
      this.updatePosition();
  }

  private updatePosition() {
      if (this.target && this.target.nativeElement) {
          // Get reference element position.
          let popupheight = this.el.nativeElement.offsetHeight;
          let rect = this.target.nativeElement.getBoundingClientRect();
          let targetheight = this.target.nativeElement.offsetHeight;

          // Set new top/left values.
          let left = rect.left;
          let top = rect.top + targetheight;

          if (window) {
              top += window.scrollY;
          }


          if (top + popupheight > window.innerHeight) {
              top -= popupheight + targetheight;
              this.renderer2.addClass(this.el.nativeElement, "open-up");
          }
          else {
              this.renderer2.addClass(this.el.nativeElement, "open-up");
          }

          // Position element.
          this.renderer2.addClass(this.el.nativeElement, 'top');
          this.el.nativeElement.style.top = `${top}px`;
          this.renderer2.addClass(this.el.nativeElement, 'left');
          this.el.nativeElement.style.left = `${left}px`;
      }
  }

  private onScroll = () => {
      this.updatePosition();
  }

  generateCalendar() {
      let date: moment.Moment = moment(this.currentDate);
      let month = date.month();
      let year = date.year();
      let n = 1;
      let firstWeekDay = (moment.localeData().firstDayOfWeek() == 0) ? date.date(2).day() : date.date(1).day();

      if (firstWeekDay !== 1) {
          n -= (firstWeekDay + 6) % 7;
      }

      this.weekdays = moment.weekdaysMin(true);

      this.days = [];
      let selectedDate: moment.Moment = this.date.momentObj as moment.Moment;
      for (let i = n; i <= date.endOf('month').date(); i += 1) {
          let currentDate: moment.Moment = moment(`${i}.${month + 1}.${year}`, 'DD.MM.YYYY');
          let today: boolean = (moment().isSame(currentDate, 'day') && moment().isSame(currentDate, 'month')) ? true : false;
          let selected: boolean = (selectedDate && selectedDate.isSame(currentDate, 'day')) ? true : false;
          let betweenMinMax = true;

          if (this.minDate !== null) {
              if (this.maxDate !== null) {
                  betweenMinMax = currentDate.isBetween(this.minDate, this.maxDate, 'day', '[]') ? true : false;
              } else {
                  betweenMinMax = currentDate.isBefore(this.minDate, 'day') ? false : true;
              }
          } else {
              if (this.maxDate !== null) {
                  betweenMinMax = currentDate.isAfter(this.maxDate, 'day') ? false : true;
              }
          }

          let day: ICalendarDate = {
              day: i > 0 ? i : 0,
              month: i > 0 ? month : 0,
              year: i > 0 ? year : 0,
              enabled: i > 0 ? betweenMinMax : false,
              today: i > 0 && today ? true : false,
              selected: i > 0 && selected ? true : false,
              momentObj: currentDate
          };

          this.days.push(day);
      }
  }

  public setDate(date: DateModel) {
      this.value = date;
      this.currentDate = date.momentObj as moment.Moment;
      this.generateCalendar();
  }

  selectDate(e: MouseEvent, date: moment.Moment) {
      if (e) { e.preventDefault(); }

      setTimeout(() => {
          this.value = {
              day: date.format('DD'),
              month: date.format('MM'),
              year: date.format('YYYY'),
              formatted: date.format(this.options.format),
              momentObj: date
          };
          this.generateCalendar();

          this.dateSelected.emit(this.value);
      });

      if (this.options.autoApply === true && this.opened === true) {
          this.opened = false;
      }
  }

  selectYear(e: MouseEvent, year: number) {
      e.preventDefault();
      e.stopPropagation();

      setTimeout(() => {
          let date: moment.Moment = this.currentDate.year(year);
          this.value = {
              day: date.format('DD'),
              month: date.format('MM'),
              year: date.format('YYYY'),
              formatted: date.format(this.options.format),
              momentObj: date
          };
          this.yearPicker = false;
          this.generateCalendar();
      });
  }

  generateYears() {
      let date: moment.Moment = this.options.minDate ? moment(this.options.minDate) : moment().year(moment().year() - 40);
      let toDate: moment.Moment = this.options.maxDate ? moment(this.options.maxDate) : moment().year(moment().year() + 40);
      let years = toDate.year() - date.year();

      for (let i = 0; i < years; i++) {
          this.years.push(date.year());
          date.add(1, 'year');
      }
  }

  writeValue(date: Date) {
      if (!date) { return; }

      this.date = this.createDateModel(date);
  }

  prevMonth(e: MouseEvent) {
      e.stopPropagation();
      this.currentDate = this.currentDate.subtract(1, 'month');
      this.generateCalendar();
  }

  nextMonth(e: MouseEvent) {
      e.stopPropagation();
      this.currentDate = this.currentDate.add(1, 'month');
      this.generateCalendar();
  }

  today() {
      this.currentDate = moment();
      this.selectDate(MouseEvent.prototype, this.currentDate);
  }

  openYearPicker(e: MouseEvent) {
      e.stopPropagation();
      setTimeout(() => this.yearPicker = true);
  }

  close() {
      //this.datePickerService.hidePicker();
  }


  private clear(event: MouseEvent): void {
      event.preventDefault();
      event.stopPropagation();

      this.date = new DateModel();
      this.onChangeCallback(this.date.momentObj ? this.date.momentObj.toDate() : null);
  }

  private createDateModel(date: Date): DateModel {
      let dm = new DateModel();
      let m: moment.Moment = moment(date);

      dm.day = m.format('DD');
      dm.month = m.format('MM');
      dm.year = m.format('YYYY');
      dm.formatted = m.format(this.options.format);
      dm.momentObj = m;

      return dm;

  }

  private isDateModel(value: any): value is DateModel {
      return value && value.momentObj !== undefined;
  }
}
