import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HeaderButton } from '../interfaces/header-button';
import { Action } from '../models/action';
import { ParameterAction } from './parameter-action';

export type ActionUnion = ParameterAction<any> | Action;

@Injectable({
  providedIn: 'root'
})
export class ActionService {
  onAction: EventEmitter<ActionUnion>;
  onButtonClick: EventEmitter<HeaderButton>;

  private _headerButtonsSubject = new BehaviorSubject<HeaderButton[]>([]); // Initialize directly
  headerButtons$ = this._headerButtonsSubject.asObservable(); // Observable for components to subscribe to

  private recentActions: ActionUnion[];

  constructor() {
    this.onAction = new EventEmitter<ActionUnion>();
    this.onButtonClick = new EventEmitter<HeaderButton>();
    this.recentActions = [];
  }

  broadcastAction<T>(action: ParameterAction<T> | Action) {
    this.recentActions.splice(0, 0, action);
    this.onAction.emit(action);
    if (this.recentActions.length > 10) {
      this.recentActions.pop();
    }
  }

  getRecentAction<T extends Action>(alias: string): T {
    return <T>this.recentActions.find(a => a.alias === alias);
  }

  addHeaderButton(btn: HeaderButton) {
    const currentButtons = this._headerButtonsSubject.value;
    this._headerButtonsSubject.next([...currentButtons, btn]); // Emit a new array with the added button
  }

  removeHeaderButton(btnAlias: string) {
    const currentButtons = this._headerButtonsSubject.value;
    const updatedButtons = currentButtons.filter(btn => btn.alias !== btnAlias);
    this._headerButtonsSubject.next(updatedButtons); // Emit a new array without the removed button
  }

  btnClick(btn: HeaderButton) {
    this.onButtonClick.emit(btn);
  }
}
