import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DamageCase } from '../../../models/damage-case';
import { ReportBase } from '../../../models/report-base';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { ActionService } from '../../../services/action.service';
import { ReportsService } from '../../../services/reports-service.service';
import { DamageCaseService } from '../../../services/damage-case.service';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { filter, of, switchMap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AssistanceReportFormComponent } from '../assistance-report-form/assistance-report-form.component';
import { StormReportFormComponent } from '../storm-report-form/storm-report-form.component';
import { CloudBurstReportFormComponent } from '../cloud-burst-report-form/cloud-burst-report-form.component';
import { UserAvatarComponent } from '../../user-avatar/user-avatar.component';
import { MomentPipe } from '../../../pipes/moment.pipe';

@Component({
  selector: 'edit-report',
  standalone: true,
  imports: [CommonModule, FormsModule, AssistanceReportFormComponent, StormReportFormComponent, CloudBurstReportFormComponent, UserAvatarComponent, MomentPipe],
  templateUrl: './edit-report.component.html',
  styleUrl: './edit-report.component.scss'
})
export class EditReportComponent implements OnInit {
  @Input() case!: DamageCase;
  @Output() isEditAssistancerapport = new EventEmitter<boolean>();
  // @Output() isCreateAssistancerapport = new EventEmitter<boolean>();

  reportTypeAlias!: string;
  reportId!: number;
  report!: ReportBase;
  caseId!: number;

  constructor(public authService: AuthenticationService,
      private actionService: ActionService,
      private reportsService: ReportsService,
      private dcService: DamageCaseService,
      private route: ActivatedRoute,
      private router: Router
  ) {
    // console.log("case (edit-report: ", this.case);
    // const rawUrl: string = this.router.url;  // This gives the full URL path
    // console.log("rawUrl: ", rawUrl);
    // this.caseId = this.extractCaseIdFromUrl(rawUrl); // Extract the caseId
    // console.log(`Extracted Case ID: ${this.caseId}`);

    // Monitor router events to detect navigation changes
    // this.router.events
    //     .pipe(filter(event => event instanceof NavigationEnd))
    //     .subscribe(event => {
    //         if (event instanceof NavigationEnd) {
    //             const currentUrl = event.urlAfterRedirects;
    //             console.log(`Navigated(edit-report) to: ${currentUrl}`);

    //             // Access route parameters using ActivatedRoute snapshot or paramMap
    //             this.caseId = this.route.parent?.snapshot.paramMap.get('caseid') as number;
    //             console.log(`Case ID: ${this.caseId}`);             
    //         }
    //     });
 

    //   this.route.params.pipe(
    //     switchMap(params => this.reportsService.getReportForCase(params["caseid"])))
    //     .subscribe(r => {
    //         this.report = r;
    //         this.reportId = r.id;
    //         this.reportTypeAlias = r.type;
    //     });

    // this.route.params.pipe(
    //     switchMap(params => this.dcService.details(params["caseid"]))
    //   ).subscribe(r => {
    //     this.case = r;
    //   });

    //   this.route.params
    //       .switchMap(params => this.dcService.details(params["caseid"]))
    //       .subscribe(r => this.case = r);
  }

  ngOnInit() {
    if (!this.case) {
        const rawUrl: string = this.router.url;  // This gives the full URL path
        this.caseId = this.extractCaseIdFromUrl(rawUrl) as number; 
        console.log(`Extracted Case ID: ${this.caseId}`);
    } else {
        this.caseId = this.case.id;
    }

    if (this.caseId) {
      this.reportsService.getReportForCase(this.caseId)
        .subscribe(r => {
          if (r) {
            this.report = r;
            this.reportId = r.id;
            this.reportTypeAlias = r.type;
          } else {
            console.log("Report not found: ");
            this.router.navigate([`/case/${this.caseId}/createreport`]);
          }
        }); 
    }
  }

  onReportSaved(r: ReportBase): void {   
    console.log("onReportSaved");
    this.isEditAssistancerapport.emit(false);
    // this.isCreateAssistancerapport.emit(false);

    this.dcService.details(this.case.id)
      .subscribe((dc: DamageCase) => this.router.navigate(["case", dc.id]));
      // this.router.navigate(["case", this.case.id]);
  }

  onReportCancelled(r: ReportBase): void {
    console.log("onReportCancelled");
    this.isEditAssistancerapport.emit(false);
    // this.isCreateAssistancerapport.emit(false);

    this.dcService.details(this.case.id)
      .subscribe((dc: DamageCase) => this.router.navigate(["case", dc.id]));
    // this.router.navigate(["case", this.case.id]);
  }

  private extractCaseIdFromUrl(url: string): number | null {
    const match = url.match(/\/case\/(\d+)\/report/); // Regular expression to match /case/:caseId/report
    return match ? Number(match[1]) : null; // Return caseId as a number if matched, else null
  }
 
}
