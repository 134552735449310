Afstand: {{distanceText}}

<google-map
  [center]="center"
  [zoom]="zoom"
  height="400px"
  width="100%">
  <map-directions-renderer
    *ngIf="directionsResults"
    [directions]="directionsResults"
  ></map-directions-renderer>
</google-map>
