import { DamageCase } from "./damage-case";
import { Employee } from "./employee";

export class ReportBase {
    [x: string]: any;
    id!: number;
    created!: Date;
    createdBy!: Employee;
    updated!: Date;
    updatedBy!: Employee;
    type!: string;
    damageCaseId!: number;
    damageCase!: DamageCase;
}
