import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'dropdown-item',
  standalone: true,
  imports: [],
  templateUrl: './dropdown-item.component.html',
  styleUrl: './dropdown-item.component.scss'
})
export class DropdownItemComponent {
  @Input() text!: string;
  @Input() route!: any[] | string;

  @Output() onMenuItemClick: EventEmitter<any> = new EventEmitter();
}
