<h2 *ngIf="equipment">
    <span class="id">{{equipment.number ? equipment.number : ''}} </span>
    <span class="name">{{equipment.name}}</span>
</h2>
<div class="notice failed" *ngIf="equipment?.failed">
    <h3>Dette udstyr er fejlmeldt</h3>
</div>
<div class="notice active" *ngIf="activeLog">
    <h3>Dette udstyr er aktivt på en sag</h3>
    Sag: {{activeLog.damageCase?.caseNumber}}<br />
    Opsat: {{activeLog.placedDate ? (activeLog.placedDate.toString() | moment:'DD-MM-YYYY') : ''}}<br />
    Adresse: {{activeLog.damageCase?.damageAddress?.street}}, {{activeLog.damageCase?.damageAddress?.zip}} {{activeLog.damageCase?.damageAddress?.city}}<br /><br />
    <a [routerLink]="['/case', activeLog.damageCase?.id]">Vis sag</a>
</div>
<div class="info">
    <strong>Udstyrstype: </strong>{{equipment ? equipment.type!.name : ''}}
</div>
<div class="history" *ngIf="equipment">
    <div class="logs">
        <h3>Sagshistorik</h3>
        <table *ngIf="equipment.logs!.length > 0; else emptyhistory">
            <thead>
                <tr>
                    <th>Sagsnr.</th>
                    <th>Status</th>
                    <th>Opsat</th>
                    <th>Nedtaget</th>
                    <ng-container *ngIf="equipment.type?.hasMeter">
                        <th>Måler start</th>
                        <th>Måler slut</th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let l of equipment.logs">
                    <td>{{l.damageCase?.caseNumber}}</td>
                    <td>{{l.isActive ? 'Aktiv' : 'Nedtaget'}}</td>
                    <td>{{l.placedDate ? (l.placedDate!.toString() | moment:'DD-MM-YYYY') : ''}}</td>
                    <td>{{l.removedDate ? (l.removedDate!.toString() | moment:'DD-MM-YYYY') : ''}}</td>
                    <ng-container *ngIf="equipment.type?.hasMeter">
                        <td>{{l.placedCounter  ? (l.placedCounter | number: '1.2-2') : null}} {{l.equipment?.type?.meterUnit}}</td>
                        <td>{{l.placedCounter ? (l.placedCounter | number: '1.2-2') : null}} {{l.equipment?.type?.meterUnit}}</td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
        <ng-template #emptyhistory>
            <div class="empty">Ingen sagshistorik</div>
        </ng-template>
    </div>
    <div class="repairs">
        <h3>Reparationer</h3>
        <table *ngIf="equipment.repairs!.length > 0; else emptyrepairs">
            <thead>
                <tr>
                    <th>Dato</th>
                    <th>Repareret</th>
                    <th>Beskrivelse</th>
                </tr>
            </thead>
            <tbody>
                <ng-container  *ngFor="let r of equipment.repairs">
                    <tr [ngClass]="{'in-progress': !r.repairFinishedDate}">
                        <td class="date">{{r.failureDate ? (r.failureDate.toString()| moment:'DD-MM-YYYY') : ''}} <avatar [user]="r.reportedEmployee!" size="small"></avatar></td>
                        <td class="date">{{r.repairFinishedDate ? (r.repairFinishedDate!.toString() | moment:'DD-MM-YYYY') : ''}} <avatar [user]="r.repairedEmployee!" size="small"></avatar></td>
                        <td>{{r.comments}}</td>
                    </tr>
                    <tr class="btn-row" *ngIf="!r.repairFinishedDate">
                        <td colspan="3" style="border-bottom: 0px !important">
                            <button type="button" (click)="repairFinished(r)">Repareret</button>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <ng-template #emptyrepairs>
            <div class="empty">Ingen sagshistorik</div>
        </ng-template>
        <button  *ngIf="!equipment?.failed" (click)="reportFailure()">Fejlmeld</button>
    </div>
</div>
