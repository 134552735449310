import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { slideLeftRightList, slideUpDownList } from '../../../animations/animations';
import { MeasurementLog } from '../../../models/measurement/measurement-log';
import { BehaviorSubject, filter, Observable, switchMap } from 'rxjs';
import { MeasuringPoint } from '../../../models/measurement/measuring-point';
import { MeasuringPointLocation } from '../../../enums/measurement/measuring-point-location';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MeasuringPointService } from '../../../services/measurement/measuring-point.service';
import { MeasurementLogService } from '../../../services/measurement/measurement-log.service';
import { MeasurementTransactionService } from '../../../services/measurement/measurement-transaction.service';
import { MeasurementTransaction } from '../../../models/measurement/measurement-transaction';
import { CommonModule } from '@angular/common';
import { MeasurementLogImageComponent } from '../measurement-log-image/measurement-log-image.component';
import { MeasurementTransactionComponent } from '../measurement-transaction/measurement-transaction.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../ui/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'measuring-point',
  standalone: true,
  imports: [CommonModule, MeasurementLogImageComponent, MeasurementTransactionComponent],
  templateUrl: './measuring-point.component.html',
  styleUrl: './measuring-point.component.scss',
  animations: [slideUpDownList, slideLeftRightList]
})
export class MeasuringPointComponent  implements OnInit {
  private _pointsSubject: BehaviorSubject<MeasuringPoint[]>;

  points: Observable<MeasuringPoint[]>;

  logId!: number;
  log!: MeasurementLog;
  hasPoints: boolean = false;
  newPoint: MeasuringPoint;
  measuringPointLocation = MeasuringPointLocation;

  locations = [
      { text: "Vælg placering" },
      { text: "Gulv", value: MeasuringPointLocation.Floor },
      { text: "Væg", value: MeasuringPointLocation.Wall },
      { text: "Loft", value: MeasuringPointLocation.Ceiling },
      { text: "Hulrum", value: MeasuringPointLocation.HollowSpace }
  ];

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private mpService: MeasuringPointService,
    private mlService: MeasurementLogService,
    private mtService: MeasurementTransactionService) {
      this.newPoint = new MeasuringPoint();
      this._pointsSubject = new BehaviorSubject<MeasuringPoint[]>([]);
      this.points = this._pointsSubject.asObservable();

      this.points
        .pipe(filter(r => r != null))
        .subscribe((p: MeasuringPoint[] | null) => {
          if (p) {
            this.hasPoints = p.length > 0;          
            this.cdRef.detectChanges();
          }
      })
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.logId = params["logid"];
    });

    if (this.logId) {
      this.route.params.pipe(
        switchMap((params: Params) => this.mlService.get(params["logid"]))
      )
      .subscribe((log: any) => {
        this.log = log.result;
        this._pointsSubject.next(this.log.points);
      });
    }
  }

  onSubmitCreate(): void {
      this.newPoint.measurementLogId = this.logId;

      this.mpService.create(this.newPoint)
        .subscribe(p => {
          p.state = "add";
          this.log.points.push(p);
          this.refreshPoints();
        });

      this.newPoint = new MeasuringPoint();
  }

  transactionCreated(t: MeasurementTransaction) {
      t.measurementLogId = this.log.id;

      this.mtService.create(t)
        .subscribe((r: any) => {
          if (r.result) {
            r.result.state = "add";
            this.log.transactions.push(r.result);
          }
        });

  }

  deletePoint(p: MeasuringPoint) {
    console.log("deletePoint: ", p);
    // TODO: Dialog 
      // var d = this.modal.confirm().message("Vil du slette dette målepunkt?").okBtn("Slet målepunkt").cancelBtn("Annuller").open()
      //     .then(v => v.result.then(r => {
      //         this.mpService.delete(p).then(r => {
      //             p.state = "remove";
      //             this.log.points.splice(this.log.points.indexOf(p), 1);
      //             this.refreshPoints();

      //             //If we are down to zero points, we delete all transations, since they have no data anymore
      //             //TODO: should this be a check on the server side instead
      //             if (this.log.points.length == 0) {
      //                 this.mtService.deleteMultiple(this.log.id).then(r => {
      //                     if (r) {
      //                         this.log.transactions.splice(0, this.log.transactions.length);
      //                     }
      //                 });
      //             }
      //         });
      //     }));
  }

  transactionDeleted(t: MeasurementTransaction) {
    console.log("transactionDeleted: ", t);
    const dialogRefDeleRoom = this.dialog.open(ConfirmDialogComponent, {
      width: "80%",
      maxWidth: "450px",
      disableClose: true,
      data: {
        title: "Måling",
        noButton: "Annuller",
        yesButton: "Slet registrering",
        message: `Vil du slette denne registering?`
      }
    })

    dialogRefDeleRoom.afterClosed()
      .subscribe((dialogResult: boolean) => {
        if (dialogResult == true) {
          this.mtService.delete(t)
            .subscribe(r => {
              t.state = "remove";
              this.log.transactions.splice(this.log.transactions.indexOf(t), 1);
            });    
        }
      });
  }

  private refreshPoints() {
      //Use array.from to create a new array to push, since Angular's change detection will else just see it as the same array.
      //Seems kind of a hack, but it works
      this._pointsSubject.next(Array.from(this.log.points));
  }

  public goToList() {
      this.router.navigate(["../"], { relativeTo: this.route });
  }
}
