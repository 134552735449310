import { Injectable } from '@angular/core';
import { AuthenticationService } from '../auth/authentication.service';
import { Settings } from '../../interfaces/settings/settings';
import { ApiResult } from '../../models/api-result';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceBase } from '../base/service-base.service';
import { AlertService } from '../alert.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends ServiceBase {
  private baseurl = '/api/admin/settings/';

  constructor(
    authhttp: HttpClient, 
    alert: AlertService, 
    private authService: AuthenticationService) {
    super(authhttp);
}

  getAdminSettings(): Observable<ApiResult<Settings>> {
      return this.getJson<ApiResult<Settings>>(this.baseurl + 'get/' + this.authService.companyId);
  }

  // private handleError(error: any): Promise<any> {
  //     console.error('An error occurred', error);
  //     return Promise.reject(error.message || error);
  // }

  saveAdminSettings(admSettings: Settings): Observable<ApiResult<Settings>> {
    return this.postJson<ApiResult<Settings>>(this.baseurl + 'update', JSON.stringify(admSettings));
    
      // let jsonData = JSON.stringify(admSettings);
      // return this.authhttp
      //     .post(this.baseurl + 'update', jsonData)
      //     .toPromise()
      //     .then(response => response.json() as ApiResult<Settings>)
      //     .catch(this.handleError);
  }

}
