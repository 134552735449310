<div class="case-vehicle-list">
    <div class="vehicles">
        <div *ngIf="case.damageStatusId != 3" class="add input-group">
            <select class="form-control" id="vehicleId" name="vehicleId" [(ngModel)]="selectedVehicle">
                <option [ngValue]="null">Vælg et køretøj</option>
                <option [ngValue]="v" *ngFor="let v of allVehicles">
                    {{v.registrationNumber}} - {{v.shortName}}
                </option>
            </select>
            <span class="input-group-addon">
                <button type="button" [disabled]="!selectedVehicle" (click)="addVehicle()">Tildel</button>
            </span>
        </div>
    </div>
    <ul class="item-list">
        <li *ngFor="let v of case.vehicles" class="item">
            {{v.registrationNumber}} {{v.shortName}}
            <div *ngIf="case.damageStatusId != 3" class="actions">
                <button (click)="removeVehicle($event, v)" title="Fjern bil"><i class="fa fa-trash fa-lg" aria-hidden="true"></i><span class="visuallyhidden"></span></button>
            </div>
        </li>
    </ul>
</div>
