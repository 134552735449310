import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HouseholdEffect } from '../../../models/household-effect';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { HouseholdEffectService } from '../../../services/household-effect.service';
import { DamageCase } from '../../../models/damage-case';
import { sortArray } from '../../../helpers/order';
import { CommonModule } from '@angular/common';
import { MomentPipe } from "../../../pipes/moment.pipe";
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'householdeffectlist',
  standalone: true,
  imports: [CommonModule, MomentPipe, FormsModule],
  templateUrl: './household-effect-list.component.html',
  styleUrl: './household-effect-list.component.scss'
})
export class HouseholdEffectListComponent implements OnInit {
  @Input() public case!: DamageCase;
  @Output() public add: EventEmitter<HouseholdEffect> = new EventEmitter<HouseholdEffect>();

  public effects: HouseholdEffect[] = [];
  public searchText: string = "";

  constructor(
      private authService: AuthenticationService, 
      private householdEffectService: HouseholdEffectService) {
  }

  ngOnInit(): void {
    this.loadItems();
  }

  private loadItems() {
    if (this.searchText === "") {
        this.householdEffectService.list(this.case.customerId)
          .subscribe((r: HouseholdEffect[]) => {
            this.effects = this.orderEffects(r);
          });
    }
    else {
        this.householdEffectService.search(this.case.customerId, this.searchText)
          .subscribe(r => {
            this.effects = this.orderEffects(r);
          });
    }
  }

  private orderEffects(items: HouseholdEffect[]): HouseholdEffect[] {
    for (let i of items) {
        if (i.attachedCases.length > 0) {
            i.addedToCase = true;
        }
    }
    return sortArray(items, "itemText");
  }  

  public itemClick(item: HouseholdEffect) {
    if (item.addedToCase) {
        return;
    }
    this.add.emit(item);
    this.loadItems();
  }

  public search() {
      this.loadItems();
  }

  public reload() {
      this.loadItems();
  }

}
