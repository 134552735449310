import moment from 'moment';
import { IDateModel } from '../../interfaces/ui/idate-model';

export class DateModel {
    day: string | null;
    month: string | null;
    year: string | null;
    formatted: string | null;
    momentObj: moment.Moment | null;

    constructor(obj?: IDateModel) {
        this.day = obj && obj.day ? obj.day : null;
        this.month = obj && obj.month ? obj.month : null;
        this.year = obj && obj.year ? obj.year : null;
        this.formatted = obj && obj.formatted ? obj.formatted : null;
        this.momentObj = obj && obj.momentObj ? obj.momentObj : null;
    }
}
