import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnInit, Optional } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: 'textarea[autosize]',
  standalone: true,
})
export class AutosizeDirective implements AfterViewInit, OnInit {
  private el: HTMLTextAreaElement;
  private _minHeight: number = 0;
  private _maxHeight: number = Infinity;
  private _clientWidth: number;

  @Input()
  set minHeight(value: number) {
    this._minHeight = value;
    this.updateMinHeight();
  }

  @Input()
  set maxHeight(value: number) {
    this._maxHeight = value;
    this.updateMaxHeight();
  }

  constructor(
    private elementRef: ElementRef<HTMLTextAreaElement>,
    @Optional() private ngModel: NgModel
  ) {
    this.el = this.elementRef.nativeElement;
    this._clientWidth = this.el.clientWidth;
  }

  ngOnInit(): void {
    // Subscribe to model changes if NgModel is present
    this.ngModel?.valueChanges?.subscribe(() => this.adjust());
  }

  ngAfterViewInit(): void {
    // Prevent manual resizing by user
    const style = getComputedStyle(this.el);
    if (style.resize === 'both') {
      this.el.style.resize = 'horizontal';
    } else if (style.resize === 'vertical') {
      this.el.style.resize = 'none';
    }

    // Initial adjustment
    this.adjust();
  }

  @HostListener('window:resize')
  onResize(): void {
    if (this.el.clientWidth !== this._clientWidth) {
      this._clientWidth = this.el.clientWidth;
      this.adjust();
    }
  }

  private adjust(): void {
    const { scrollHeight, style } = this.el;

    // Reset height to allow recalculation
    style.overflow = 'hidden';
    style.height = 'auto';

    // Set new height
    const newHeight = Math.min(scrollHeight, this._maxHeight);
    style.height = `${newHeight}px`;

    // Handle overflow
    if (scrollHeight > this._maxHeight) {
      this.el.classList.add('overflow');
      style.overflow = 'auto';
    } else {
      this.el.classList.remove('overflow');
    }
  }

  private updateMinHeight(): void {
    this.el.style.minHeight = `${this._minHeight}px`;
  }

  private updateMaxHeight(): void {
    this.el.style.maxHeight = `${this._maxHeight}px`;
  }
}
