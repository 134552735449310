import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DamageCase } from '../../../models/damage-case';
import { AuthenticationService } from '../../../services/auth/authentication.service';

@Component({
  selector: 'app-case-detail-section',
  standalone: true,
  imports: [],
  templateUrl: './case-detail-section.component.html',
  styleUrl: './case-detail-section.component.scss'
})
export class CaseDetailSectionComponent implements OnInit, OnChanges {
  @Input() case!: DamageCase;
  @Output()  updated: EventEmitter<DamageCase> = new EventEmitter<DamageCase>();

  constructor(public authService: AuthenticationService) {
  }

  ngOnInit() {
  }


  ngOnChanges(changes: SimpleChanges): void {
      for (let c in changes) {
          if (c === "case") {
              this.onCaseChanged();
          }
      }
  }

  protected onUpdated() {
      this.updated.emit(this.case);
  }

  protected onCaseChanged() {

  }
}
