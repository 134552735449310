import { Component, Inject, OnInit } from '@angular/core';
import { fadeUpDown } from '../../../animations/animations';
import { CommonModule } from '@angular/common';
import { AlertMessage } from '../../../interfaces/alert-message';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'alert-dialog',
  standalone: true,
  imports: [CommonModule, MatCardModule],
  templateUrl: './alert-dialog.component.html',
  styleUrl: './alert-dialog.component.scss',
  animations: [fadeUpDown]
})
export class AlertDialogComponent implements OnInit {
  defaultTimeout = 5000; // 5 seconds in milliseconds

  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertMessage) { 
    }
  
  ngOnInit(): void {
    setTimeout(() => {
      this.dialogRef.close(false);
    }, this.defaultTimeout); 
  }

  onDismiss() {
    this.dialogRef.close(false);  
  }
}
