
<div class="login register">
    <img class="logo" src="assets/images/dr_logo_white.svg" />

    <div class="content">
        <h2>Registrér</h2>
        <form name="form" (ngSubmit)="f.form.valid && register()" #f="ngForm" novalidate class="loginform register">
            <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                <label for="firstName">Navn og fornavn</label>
                <input type="text" class="form-control" name="fullName" [(ngModel)]="model.fullName" #fullName="ngModel" required />
                <div *ngIf="f.submitted && !fullName.valid" class="validation-message">Navn er obligatorisk</div>
            </div>
            <div class="form-group" [ngClass]="{ 'has-error': f.submitted }">
                <label for="companyIsIndustry">
                    <mat-checkbox class="form-control" color="primary" style="--checkbox-background-color: #fff;" name="companyIsIndustry" [(ngModel)]="model.customerIsIndustry" #companyName="ngModel"></mat-checkbox>
                    Registrér som erhvervkunde
                </label>
            </div>
            <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }" *ngIf="model.customerIsIndustry">
                <label for="companyName">Firmanavn</label>
                <input type="text" class="form-control" name="companyName" [(ngModel)]="model.customerCompanyName" #companyName="ngModel" required />
            </div>
            <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                <label for="emailAddress">Email</label>
                <input type="email" class="form-control" name="emailAddress" [(ngModel)]="model.email" #emailAddress="ngModel" required />
                <div *ngIf="f.submitted && !emailAddress.valid" class="help-block">
                    <div class="validation-message">Emailadresse er obligatorisk</div>
                </div>
            </div>
            <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                <label for="secondEmailAddress">Ekstra Email</label>
                <input type="email" class="form-control" name="secondEmailAddress" [(ngModel)]="model.secondEmail" #secondEmailAddress="ngModel" />
            </div>

            <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                <label for="username">Brugernavn</label>
                <input type="text" class="form-control" name="username" [(ngModel)]="model.userName" #username="ngModel" validateUsername required />
                <div class="validation-message" *ngIf="username.errors && (username.dirty || username.touched)">
                    <div *ngIf="username.errors['required']">
                        Du skal angive brugernavn
                    </div>
                    <div *ngIf="username.errors['validateUsername']">
                        Brugernavnet er optaget
                    </div>
                </div>
            </div>
            <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                <label for="password">Password</label>
                <input type="password" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel" required />
                <div *ngIf="f.submitted && !password.valid" class="help-block">Password er obligatorisk</div>
            </div>

            <!-- adresse -->
            <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                <label for="addressStreet">Adresse</label>
                <input type="text" class="form-control" name="addressStreet" [(ngModel)]="model.address.street" #addressStreet="ngModel" />

            </div>
            <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                <label for="addressCity">By</label>
                <input type="text" class="form-control" name="addressCity" [(ngModel)]="model.address.city" #addressCity="ngModel" />

            </div>
            <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                <label for="addressZip">Postnummer</label>
                <input type="text" class="form-control" name="addressZip" [(ngModel)]="model.address.zip" #addressZip="ngModel" />

            </div>
            <div class="buttons">
                <button [disabled]="loading || !f.valid" class="primary">Registrér</button>&nbsp;
                <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                <a [routerLink]="['/login']" class="btn btn-link">Annuller</a>
            </div>
        </form>
    </div>
</div>
