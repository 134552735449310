import { Injectable } from '@angular/core';
import { ServiceBase } from './base/service-base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService extends ServiceBase {
  private downloadUrl: string = '/api/damagecase/download/';

  constructor(
    private authhttp: HttpClient) {
    super(authhttp);
  }

  downloadFile(fileName: string): Observable<Blob> {
    return this.http.get(this.downloadUrl + fileName, {
      responseType: 'blob',
    });
  }
}
