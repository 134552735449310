import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FileItem } from 'ng2-file-upload';

@Component({
  selector: 'app-file-uploaded-args',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './file-uploaded-args.component.html',
  styleUrl: './file-uploaded-args.component.scss'
})

export class FileUploadedArgsComponent {
  constructor(
    public file: FileItem,
    @Inject('response') public response: string,
    @Inject('status') public status: number,
    //public response: any,
    //public status: number
  ) {
  }
  // file!: FileItem;
  // response!: string;
  // status!: number;

  getValue<T>(): T {
      return <T>JSON.parse(this.response);
  }
}
