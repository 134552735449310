<div *ngIf="row.mode == 'view' || !row.mode" class="itemrow">
    <div class="equipment">
        <span class="number">{{row.equipmentId ? row.equipment!.number : row.equipmentNumber}}</span>
        <span class="name">{{row.equipmentId ? row.equipment?.name : row.equipmentText}}</span>
    </div>
    <div class="placed">
        <!-- <div class="inline-header">Opsat</div> -->
        <div class="date">{{row.placedDate! | date: "dd-MM-YYYY"}}
            <avatar [user]="row.placedEmployee!" size="small"></avatar>
        </div>
        <div class="counter" *ngIf="row.equipment && row.equipment.type?.hasMeter || !row.equipment">
            {{row.placedCounter | number: '1.2-2'}}
        </div>
    </div>

    <div class="takendown">
        <ng-container *ngIf="!row.isActive" >
            <!-- <div class="inline-header">Nedtaget</div> -->
            <div class="date">{{row.removedDate! | date: "dd-MM-YYYY"}}
                <avatar [user]="row.removedEmployee!" size="small"></avatar>
            </div>
            <div class="counter" *ngIf="row.equipment && row.equipment.type?.hasMeter || !row.equipment">
                {{row.removedCounter | number: '1.2-2'}}
            </div>
        </ng-container>
    </div>
    
    <ng-container *ngIf="!row.isActive">
        <div class="usage" *ngIf="row.equipment && row.equipment.type?.hasMeter">
            {{calcCounter(row) | number: '1.2-2'}} {{row.equipment.type?.meterUnit}}
        </div>
        <div class="usage" *ngIf="row.equipment && !row.equipment.type?.hasMeter"></div>
        <div class="usage" *ngIf="!row.equipment">
            {{calcCounter(row) | number: '1.2-2'}} kWh
        </div>
        <div class="days">{{row.removedDate! | momentDiff:'days':row.placedDate!:true}} dg</div>
    </ng-container>

    <ng-container *ngIf="row.isActive">
        <div class="usage"></div>
        <div class="days"></div>
    </ng-container>
    
    <div class="actions half item-to-break">
        <div *ngIf="case.damageStatusId == 2 || case.damageStatusId == 4">
            <button (click)="editItem($event, row)" title="Redigér">
                <i class="fa fa-pencil" aria-hidden="true"></i>
            </button>
            <button *ngIf="row.isActive" (click)="takeDown($event, row)" title="Nedtag">
                <i class="fa fa-stop-circle-o" aria-hidden="true"></i>
            </button>
        </div>
    </div>
</div>

<div *ngIf="row.mode == 'edit' || row.mode == 'add'">
    <form name="form" (ngSubmit)="f.form.valid && saveItem(row)" #f="ngForm"  class="itemrow">
        <ng-container *ngIf="!row.equipmentId; else eq">
            <div class="equipment"><input type="text" [(ngModel)]="row.equipmentText" required name="eqipmentText" /> <input type="text" [(ngModel)]="row.equipmentNumber" required name="equipmentNumber" /></div>
        </ng-container>
        <ng-template #eq>
            <div class="equipment"><span class="type">{{row.equipment!.type!.name}}</span> <span class="number">{{row.equipment!.number}}</span></div>
        </ng-template>
        <div class="placed">

            <ng-container *ngIf="!row.settingup; else setup">
                <!-- <div class="inline-header">Opsat</div> -->
                <div class="date">{{row.placedDate | date: "dd-MM-YYYY"}} </div>
                <div class="counter" *ngIf="row.equipment && row.equipment.type!.hasMeter || !row.equipment">{{row.placedCounter | number: '1.2-2'}}</div>
            </ng-container>

            <ng-template #setup>
                <div class="date">
                    <input type="date" value="{{row.placedDate | date: 'YYYY-MM-dd'}}" required name="placedDate" />
                    <!-- <dr-datepicker fieldid="placedDate" name="placedDate" [(ngModel)]="row.placedDate" #placedDate="ngModel" required></dr-datepicker> -->
                </div>
                <div class="counter" *ngIf="row.equipment && row.equipment.type!.hasMeter || !row.equipment">
                    <input type="number" step="0.01" [(ngModel)]="row.placedCounter" required name="placedCounter" />
                </div>
            </ng-template>

        </div>

        <div class="takendown">
            <ng-container *ngIf="!row.takingdown; else takedown">
                <!-- <div class="inline-header">Nedtaget</div> -->
                <div class="date">{{row.removedDate | date: "dd-MM-YYYY"}}</div>
                <div class="counter" *ngIf="row.equipment && row.equipment.type!.hasMeter || !row.equipment">{{row.removedCounter | number: '1.2-2'}}</div>
            </ng-container>
            <ng-template #takedown>
                <div class="date">
                    <input type="date" value="{{row.removedDate | date: 'YYYY-MM-dd'}}" required name="removedDate" />-->
                    <!-- <dr-datepicker fieldid="removedDate" name="removedDate" [(ngModel)]="row.removedDate" #removedDate="ngModel" required></dr-datepicker> -->
                </div>
                <div class="counter" *ngIf="row.equipment && row.equipment.type!.hasMeter || !row.equipment">
                    <input type="number" step="0.01" [(ngModel)]="row.removedCounter" required [min]="row.placedCounter+0.01" name="removedCounter" #removedCounter="ngModel" />
                </div>
            </ng-template>
        </div>
        <div class="usage"></div>
        <div class="days"> </div>
        <div class="actions half item-to-break">
            <span><button type="submit" title="Gem"><i class="fa fa-check" aria-hidden="true"></i></button>
            <button (click)="cancelEditing($event, row)" title="Annuller"><i class="fa fa-remove" aria-hidden="true"></i></button></span>
        </div>

    </form>
</div>