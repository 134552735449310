<div class="case-detail-panel">
    <canvas baseChart width="400" height="400"
            (datasets)="lineChartData"
            (labels)="lineChartLabels"
            (options)="lineChartOptions"
            (colors)="lineChartColors"
            (legend)="lineChartLegend"
            (chartType)="lineChartType"
            (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)"
            >
            </canvas>
    <div>
        <div class="col-xxs-6 pull">
            <dr-datepicker [(ngModel)]="datefrom" (ngModelChange)="dateFromChange()"></dr-datepicker>
        </div>
        
        <div class="col-xxs-6 push">
            <dr-datepicker [(ngModel)]="dateto" (ngModelChange)="dateToChange()"></dr-datepicker>
        </div>
        
    </div>
       
</div>
