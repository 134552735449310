<div class="list">
    <button class="back-to-list" (click)="goToList()"><i class="fa fa-angle-left"></i> Tilbage til liste</button>
    <measurementlog-image [log]="log"></measurementlog-image>
    <div *ngIf="hasPoints">
        <div class="header">
                <div class="value"></div>
                <div class="value">Instr.</div>
                <div class="value" *ngFor="let p of points | async" [@slideLeftRightList]="p.state">
                    {{p.name}}
                </div>
                <div class="value">Ref. måling</div>
                <div class="value"></div>
        </div>

        <div *ngFor="let t of log.transactions; let i = index">
            <div class="transaction-wrapper" [ngClass]="{'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0}">
                <measurement-transaction 
                    [points]="points | async" 
                    [log]="log" 
                    [transaction]="t" 
                    mode="view" 
                    (deleted)="transactionDeleted($event)" 
                    [@slideUpDownList]="t.state">
                </measurement-transaction>
            </div>
        </div>
        
        <measurement-transaction 
            [points]="points | async" 
            [log]="log" 
            mode="create" 
            (created)="transactionCreated($event)">
        </measurement-transaction>

    </div>
</div>




 <!-- '{{ log.transactions | json}}' -->
        
                <!-- <measurement-transaction class="measurement-transaction" *ngFor="let t of log.transactions" [points]="points | async" [log]="log" [transaction]="t" (deleted)="transactionDeleted($event)" [@slideUpDownList]="t.state"></measurement-transaction> -->
        
                <!-- <measurement-transaction class="measurement-transaction" [points]="points | async" [log]="log" mode="create" (created)="transactionCreated($event)"></measurement-transaction> -->