import { ComponentRef, ElementRef, Injectable } from '@angular/core';
import { DomService } from './dom.service';
import { DateModel } from '../../models/ui/date-model';
import { DatePickerOptions } from '../../models/ui/date-picker-options';
import { DatePickerPopupComponent } from '../../components/ui/date-picker-popup/date-picker-popup.component';

@Injectable({
  providedIn: 'root'
})
export class DatePickerService {
  isOpen!: boolean;
  private popupRef!: ComponentRef<DatePickerPopupComponent>;

  constructor(
      private domService: DomService
  ) { }

  public showPicker(target: ElementRef, currentValue: DateModel, options: DatePickerOptions): Promise<DateModel> {

      const promise = new Promise<DateModel>((resolve, reject) => {
          this.hidePicker();
          let popup =  this.domService.createComponentRef(DatePickerPopupComponent);
          this.isOpen = true;

          //this.domService.setDynamicStyles(this.toastStyle, toastRef);

          //toastRef.instance.message = message;

          //toastRef.instance.type = type;

          popup.instance.target = target;
          //popup.instance.setDate(currentValue);
          popup.instance.value = currentValue;
          popup.instance.options = options;

          popup.instance.dateSelected.subscribe(x => {
              resolve(x);
          });


          const popupElement = this.domService.getDomElementFromComponentRef(popup);

          this.domService.addChild(popupElement);
          //this.popupRef.instance.opened = true;

          setTimeout(() => {
              this.popupRef = popup;
          }, 200);
      });

      return promise;
  }

  public hidePicker() {
      if (this.isOpen && this.popupRef) {
          this.isOpen = false;
          //this.popupRef.instance.opened = false;
          this.domService.destroyRef(this.popupRef);
          //delete this.popupRef;
      }
  }
}
