import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Customer } from '../../models/customer';
import { Employee } from '../../models/employee';
import { CommonModule } from '@angular/common';

export type AvatarSize = "mini" | "small" | "normal";

@Component({
  selector: 'avatar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './user-avatar.component.html',
  styleUrl: './user-avatar.component.scss'
})
export class UserAvatarComponent implements OnInit, OnChanges  {
  @Input('user') user!: Employee | Customer;
  @Input('size') size: AvatarSize = "normal";
  @Input('title') title: string | null = null;

  displayValue!: string;
  displayTitle!: string;

  constructor(
    private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.refreshDisplay();    
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let c in changes) {
        if (c === "user") {
            this.refreshDisplay();
        }
    }
  }

  private refreshDisplay() {
      if (this.user) {
          if (this.title && this.title.length > 0) {
              this.displayTitle = `${this.title}\n${this.user.fullName}`;
          }
          else {
              this.displayTitle = this.user.fullName;
          }

          if (this.isEmployee(this.user)) {
              this.displayValue = this.user.displayName;
              if (this.displayValue && this.displayValue.length > 0) {
                  return;
              }
          }

          //We didn't have a display name, so fall back to taking letters from the name
          const splitname: string[] = this.user.fullName.split(" ");
          if (splitname.length >= 2) {
              this.displayValue = splitname[0].substring(0, 1);
              this.displayValue += splitname[splitname.length - 1].substr(0, 1);
          }
          else {
              this.displayValue = splitname[0].substr(0, 2);
          }

        }
  }

  private isEmployee(item: any): item is Employee{
      return item.displayName !== undefined;
  }  

}
