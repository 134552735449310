
<div class="newcasedetails">
    <div class="panelyellow">
        <h1><strong>{{formtitle}}</strong></h1>
    </div>
    <div class="panellightgreen" *ngIf="model">
        <form (ngSubmit)="onSubmit()" #createCaseForm="ngForm">
            <div *ngIf="!authService.isCustomer">
                <div class="form-group">
                    <label for="customerId">Kunde</label>
                    <select id="customerId" [(ngModel)]="model.customerId" name="customerId" required (change)="customerChange($event)">
                        <option value="" selected>vælg kunde</option>
                        <option *ngFor="let cust of customers" [value]="cust.id">{{cust.fullName}}</option>
                    </select>
                </div>

                <div class="form-group">
                    <label for="caseNumber">Sagsnummer</label>
                    <input type="text" class="form-control" id="caseNumber" [(ngModel)]="model.caseNumber" name="caseNumber" required>
                </div>
            </div>
            <div *ngIf="authService.isCustomer">
            </div>

            <div class="form-group">
                <label for="damageTypeId">Skadetype</label>
                <select id="id" [(ngModel)]="model.damageTypeId" name="damageTypeId" required>
                    <option [ngValue]="null">vælg skadetype</option>
                    <option [ngValue]="type.id" *ngFor="let type of damageTypes">{{ type.name }}</option>
                </select>
            </div>
            <div class="date-time">
                <label for="damageAddress_street">Skadedato</label>
                <input type="date" class="styled-input-date" id="caseNumber" name="damagedate" placeholder="Vælg datoen" required
                    (keydown)="disableTyping($event)"
                    value="{{damageDate}}">
                    <!-- <dr-datepicker type="text" class="form-control" id="caseNumber" [(ngModel)]="model.damageDate" name="damagedate"></dr-datepicker> -->
            </div>
            <div class="form-group">
                <label for="damageAddress_street">Skadeadresse</label>
                <input type="text" class="form-control" id="caseNumber" [(ngModel)]="model.damageAddress.street" name="damageAddress_street" required>
            </div>
            <div class="form-group">
                <label for="damageAddress_zip">Postnr</label>
                <input type="text" class="form-control" id="damageAddress_zip" [(ngModel)]="model.damageAddress.zip" name="damageAddress_zip" required>
            </div>
            <div class="form-group">
                <label for="damageAddress_city">By</label>
                <input type="text" class="form-control" id="damageAddress_city" [(ngModel)]="model.damageAddress.city" name="damageAddress_city" required>
            </div>
            <div class="form-group">
                <label for="insuranceCompanyName">Forsikringsselskab</label>
                <input type="text" class="form-control" id="insuranceCompanyName" [(ngModel)]="model.insuranceCompanyName" name="insuranceCompanyName" required>
            </div>
            <div class="form-group">
                <label for="insurancePolicyNumber">Policenummer</label>
                <input type="text" class="form-control" id="insurancePolicyNumber" [(ngModel)]="model.insurancePolicyNumber" name="insurancePolicyNumber" required>
            </div>

            <div class="form-group">
                <label for="shortDescription">Kort beskrivelse</label>
                <textarea class="form-control" id="shortDescription" [(ngModel)]="model.shortDescription" name="shortDescription" required></textarea>
            </div>

            <!--<div class="form-group">
<label for="workAddress_street">Arbejdsadresse</label>
<input type="text" class="form-control" id="workAddress_street" [(ngModel)]="model.workAddress.street" name="workAddress_street">
</div>
<div class="form-group">
<label for="workAddress_zip">Arbejdspostnr</label>
<input type="text" class="form-control" id="workAddress_zip" [(ngModel)]="model.workAddress.zip" name="workAddress_zip">
</div>
<div class="form-group">
<label for="workAddress_city">Arbejdsby</label>
<input type="text" class="form-control" id="workAddress_city" [(ngModel)]="model.workAddress.city" name="workAddress_city">
</div>-->

            <div class="form-group">
                <label for="requestedByname">Rekvirent</label>
                <input type="text" class="form-control" id="requestedByname" [(ngModel)]="model.requestedByName" name="requestedByname" required>
            </div>

            <!--<div class="form-group">
<label for="requestedBy_street">Rekvirentadresse</label>
<input type="text" class="form-control" id="requestedBy_street" [(ngModel)]="model.requestedByAddress.street" name="requestedBy_street">
</div>
<div class="form-group">
<label for="requestedBy_zip">Rekvirentpostnr</label>
<input type="text" class="form-control" id="requestedBy_zip" [(ngModel)]="model.requestedByAddress.zip" name="requestedBy_zip">
</div>
<div class="form-group">
<label for="requestedBy_city">Rekvirentby</label>
<input type="text" class="form-control" id="requestedBy_city" [(ngModel)]="model.requestedByAddress.city" name="requestedBy_city">
</div>-->


            <div class="text-center row">
                <button type="submit" class="btn btn-default" [disabled]="!createCaseForm.form.valid">Gem og luk</button>
                <button (click)="onCancel($event)">Annuller</button>
            </div>

        </form>
    </div>
</div>
