import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private hubConnection!: HubConnection;
  
  constructor() {
    this.hubConnection = new HubConnectionBuilder()
    .withUrl('/signalr') // URL of your SignalR hub    
    .build();
  }

  startConnection() {
    this.hubConnection.start()
      .then(() => console.log('Connection started'))
      .catch(err => console.log('Error while starting connection: ' + err));
  }

  addMessageListener(callback: (message: string) => void) {
    this.hubConnection.on('ReceiveMessage', (message: string) => {
      callback(message);
    });
  }

  sendMessage(message: string) {
    this.hubConnection.invoke('SendMessage', message)
      .catch(err => console.error(err));
  }
}
