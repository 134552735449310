<div class="datepicker-calendar">
    <div class="datepicker-calendar-top">
        <span class="year-title">{{ currentDate.format('YYYY') }}</span>
        <button type="button" (click)="openYearPicker($event)" *ngIf="!yearPicker">
            <i class="ion-arrow-right-c"></i>
            Vælg år
        </button>
        <i class="close fa fa-close" (click)="close()"></i>
    </div>
    <div class="datepicker-calendar-container">
        <div *ngIf="!yearPicker">
            <div class="datepicker-calendar-month-section">
                <i class="fa fa-arrow-left" (click)="prevMonth($event)"></i>
                <span class="month-title">{{ currentDate.format('MMMM') }}</span>
                <i class="fa fa-arrow-right" (click)="nextMonth($event)"></i>
            </div>
            <div class="datepicker-calendar-day-names">
                <span *ngFor="let d of weekdays">{{d}}</span>
            </div>
            <div class="datepicker-calendar-days-container">
                <span class="day" *ngFor="let d of days; let i = index"
                      (click)="selectDate($event, d.momentObj)"
                      [ngClass]="{ 'disabled': !d.enabled, 'today': d.today, 'selected': d.selected }">
                    {{ d.day }}
                </span>
            </div>
            <div class="datepicker-buttons" *ngIf="!options.autoApply">
                <button type="button" class="a-button u-is-secondary u-is-small" (click)="today()">Today</button>
                <button type="button" class="a-button u-is-primary u-is-small" (click)="close()">Apply</button>
            </div>
        </div>
        <div *ngIf="yearPicker">
            <!-- <div class="datepicker-calendar-years-container" slimScroll [options]="scrollOptions"> -->
            <div class="datepicker-calendar-years-container" slimScroll>
                <span class="year" *ngFor="let y of years; let i = index" (click)="selectYear($event, y)">
                    {{ y }}
                </span>
            </div>
        </div>
    </div>
</div>