<div class="custsatisf">
    <!-- Custom Rating solution, since ngx-rating isn't supported in Angular 17-->
    <div class="star-rating">
        <span
          *ngFor="let star of stars; let i = index"
          (click)="setRating(i)"
          (mouseenter)="highlightStars(i)"
          (mouseleave)="resetHighlight()"
          [class.filled]="i < rating">
          ★
        </span>
    </div>

    <div>
        <button *ngIf="!case.customerSatisfactionRating || case.customerSatisfactionRating < 1" (click)="saveRating()" [disabled]="!rating">Gem</button>
    </div>
</div>
