import { Injectable } from '@angular/core';
import { ServiceBase } from '../base/service-base.service';
import { AlertService } from '../alert.service';
import { Observable } from 'rxjs';
import { Equipment } from '../../interfaces/equipment/equipment';
import { EquipmentType } from '../../interfaces/equipment/equipment-type';
import { EquipmentRepairHistory } from '../../interfaces/equipment/equipment-repair-history';
import { EquipmentLog } from '../../interfaces/equipment/equipment-log';
import { UntrackedEquipmentCount } from '../../interfaces/equipment/untracked-equipment-count';
import { UntrackedEquipmentHistory } from '../../interfaces/equipment/untracked-equipment-history';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService extends ServiceBase {
  private rooturl = '/api/equipment/';

  constructor(authhttp: HttpClient, alert: AlertService) {
      super(authhttp);
      // ["repairs.failureDate, placedDate, removedDate"]
  }

  create(item: Equipment): Observable<Equipment> {
      return this.putJson<Equipment>(`${this.rooturl}`, item, ["duplicateId"]);
  }

  update(item: Equipment): Observable<Equipment> {
      return this.patchJson<Equipment>(`${this.rooturl}`, item, ["duplicateId"]);
  }

  delete(item: Equipment): Observable<Equipment> {
      return this.deleteJson(`${this.rooturl}${item.id}`);
  }

  getTypes(): Observable<EquipmentType[]> {
    return this.getJson<EquipmentType[]>(`${this.rooturl}type`);
  }

  getType(id: number): Observable<EquipmentType> {
      return this.getJson<EquipmentType>(`${this.rooturl}type/${id}`);
  }

  updateTotalAvailableForType(data: EquipmentType): Observable<any> {
      return this.patchJson<any>(`${this.rooturl}type`, data);
  }

//   getForType(typeid: number): Observable<Equipment[]>
  getForType(typeid: number, searchText: string ): Observable<Equipment[]> {
      if (searchText && searchText != "") {
          return this.getJson<Equipment[]>(`${this.rooturl}${typeid}?search=${searchText}`);
      }
      else {
          return this.getJson<Equipment[]>(`${this.rooturl}${typeid}`);
      }
  }

  getAvailableForType(typeid: number): Observable<Equipment[]> {
      return this.getJson<Equipment[]>(`${this.rooturl}available/${typeid}`);
  }

  getAll(searchText: string): Observable<Equipment[]> {
      if (searchText && searchText != "") {
          return this.getJson<Equipment[]>(`${this.rooturl}?search=${searchText}`);
      }
      else {
          return this.getJson<Equipment[]>(`${this.rooturl}`);
      }
  }

  getAllAvailable(): Observable<Equipment[]> {
      return this.getJson<Equipment[]>(`${this.rooturl}available`);
  }

  getDetails(id: number): Observable<Equipment> {
      return this.getJson<Equipment>(`${this.rooturl}details/${id}`);
  }

  createFailure(equipmentId: number, repair: EquipmentRepairHistory): Observable<EquipmentRepairHistory> {
      return this.postJson(`${this.rooturl}failures/${equipmentId}`, repair);
  }

  updateFailure(equipmentId: number, item: EquipmentRepairHistory): Observable<EquipmentRepairHistory> {
      return this.patchJson(`${this.rooturl}failures/${equipmentId}`, item);
  }

  getLogForCase(caseId: number): Observable<EquipmentLog[]> {
      return this.getJson(`${this.rooturl}log/${caseId}`);
  }

  createLogForCase(caseId: number, log: EquipmentLog): Observable<EquipmentLog> {
    return this.putJson(`${this.rooturl}log/${caseId}`, log);
  }

  updateLogForCase(caseId: number, log: EquipmentLog): Observable<EquipmentLog> {
      return this.patchJson(`${this.rooturl}log/${caseId}`, log);
  }

  getUntrackedForCase(caseId: number): Observable<UntrackedEquipmentCount[]> {
      return this.getJson<UntrackedEquipmentCount[]>(`${this.rooturl}untracked/${caseId}`);
  }

  registerUntrackedForCase(caseId: number, equipmentTypeId: number, amount: number): Observable<UntrackedEquipmentCount[]> {
      var data: UntrackedEquipmentHistory = {
          damageCaseId: caseId,
          equipmentTypeId: equipmentTypeId,
          amount: amount
      }
      return this.putJson<UntrackedEquipmentCount[]>(`${this.rooturl}untracked/${caseId}`, data);
  }
}
