import { Pipe, PipeTransform } from '@angular/core';

import moment from 'moment';

@Pipe({
  name: 'momentDiff',
  standalone: true
})
export class MomentDiffPipe implements PipeTransform {
  transform(value: Date, unit: moment.unitOfTime.Diff = "days", otherDate: Date, includeStart: boolean = false): number {
    if (!value) return 0;
    var v: number;
    if (otherDate) {
        v = moment(value).diff(otherDate, unit);
    }
    else {
        v = moment(value).diff(moment(), unit);
        v = Math.abs(v);
    }

    if (includeStart) {
        v++;
    }

    return v;
  }
}
