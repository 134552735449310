import { ListItem, ListItemState } from "../interfaces/list-item";
import { Employee } from "./employee";

export class DamageCaseNote implements ListItem
{
    id!: number;
    damageCaseId!: number;
    employee!: Employee;
    note!: string;
    created!: Date;
    updated!: Date;
    editing!: Boolean;
    state!: ListItemState;
    readById!: string;
    readBy!: Employee;
    readOn!: Date;
    appointmentNote!: Boolean;
}