import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { DamageCase } from '../../../models/damage-case';
import { CompensationClaim } from '../../../interfaces/compensation-claim';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { CompensationClaimService } from '../../../services/compensation/compensation-claim.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';

export interface CompensationDialogData {
  case: DamageCase;
  claim: CompensationClaim;
  totalCompensationAmount: number;
}

@Component({
  selector: 'app-approve-claim-dialog',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './approve-claim-dialog.component.html',
  styleUrl: './approve-claim-dialog.component.scss'
})
export class ApproveClaimDialogComponent {
  public case!: DamageCase;
  public claim!: CompensationClaim;
  public compensationTotal: number = 0;
  public confirm: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ApproveClaimDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CompensationDialogData) {

    this.case = this.data.case;
    this.claim = this.data.claim;
    this.compensationTotal = this.data.totalCompensationAmount;
  }

  cancel() {
    this.dialogRef.close(false);
  }

  approve() {
    this.dialogRef.close(true);
  }
}
