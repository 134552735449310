import { Component, OnInit } from '@angular/core';
import { EquipmentType } from '../../../interfaces/equipment/equipment-type';
import { EquipmentService } from '../../../services/equipment/equipment.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NotificationService } from '../../../services/notification.service';
import { EventNotification } from '../../../interfaces/event-notification';
import { map } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { EquipmentListComponent } from '../equipment-list/equipment-list.component';

@Component({
  selector: 'app-equipment-overview',
  standalone: true,
  imports: [CommonModule, RouterModule, EquipmentListComponent],
  templateUrl: './equipment-overview.component.html',
  styleUrl: './equipment-overview.component.scss'
})
export class EquipmentOverviewComponent implements OnInit {
  types: EquipmentType[] = [];
  selectedType!: EquipmentType;

  constructor(
      private equipmentService: EquipmentService,
      public router: Router,
      private route: ActivatedRoute,
      private notificationService: NotificationService
  ) {

    notificationService.notifications
    .pipe(
        map(n => <EventNotification<EquipmentType[], any>>n)
    )
    .subscribe(n => {
        if (n.Type === "equipmentCountUpdated") {
            for (let t of this.types) {
                var item = n.Data.find(x => x.id === t.id);
                Object.assign(t, item);
            }
        }
    });
        
      // notificationService.notifications
      //     .map(n => <EventNotification<EquipmentType[], any>>n)
      //     .subscribe(n => {
      //         if (n.type === "equipmentCountUpdated") {
      //             for (let t of this.types) {
      //                 var item = n.data.find(x => x.id === t.id);
      //                 Object.assign(t, item);
      //             }
      //         }
      //     });
  }

  ngOnInit(): void {
      this.equipmentService.getTypes()
        .subscribe((t: EquipmentType[]) => {
          this.types = t;
        })
  }

  selectType(equipmentTypeId: number | null) {
    //this.router.navigate([equipmentTypeId], { relativeTo: this.route })
    this.router.navigate(["/equipment", equipmentTypeId]);
  }

  selectAll() {
      this.router.navigate(["/equipment"]);
  }
}
