import { Pipe, PipeTransform } from '@angular/core';

import moment from 'moment';

@Pipe({
  name: 'moment',
  standalone: true
})
export class MomentPipe implements PipeTransform {
    transform(value: string, format: string = ""): string {
        if (!value || value === "") return "";
        return moment(value).format(format);
    }

}
