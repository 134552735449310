export function toCamelCase(obj: any): any {
    if (Array.isArray(obj)) {
      return obj.map(toCamelCase); // Recursively handle arrays
    } else if (obj !== null && typeof obj === 'object') {
      return Object.keys(obj).reduce((result, key) => {
        const camelKey = key.charAt(0).toLowerCase() + key.slice(1);
        result[camelKey] = toCamelCase(obj[key]); // Recursively convert nested objects
        return result;
      }, {} as any);
    }
    return obj; // Return primitive values as-is
  }