<div class="caserow" *ngIf="case" 
     [class.industry]="case.customer != null && case.customer.customerIsIndustry == true"
     [class.selected]="router.isActive('case/' + case.id, true) || router.isActive('case/' + case.id + '/edit', true)"
     [class.new]="case.damageStatusName == 'ny'"
     [class.closed]="case.damageStatusName == 'lukket'">

    <div class="queue-no">
        {{case.queueNumber}}
    </div>
    <div class="icons">
        <span *ngIf="!case.hasReport && case.damageStatusName == 'åben'">
            <i class="fa fa-exclamation-triangle fa-lg" title="Sag mangler rapport"></i>
        </span>
        <span *ngIf="case.hasNotes">
            <i class="fa fa-envelope-o" title="Har noter"></i>
        </span>
    </div>
    <div class="customername">
        <i class="fa fa-industry" *ngIf="case.customer != null && case.customer.customerIsIndustry" title="Erhverv"></i> 
        <span *ngIf="case.customer != null">&nbsp;{{case.customer.fullName}}</span>
    </div>
    <div class="days">
        <span *ngIf="case.checkinDate">
        {{ (case.checkinDate | momentDiff:'days':currentDate:true)  | abs }} dg
        </span>
    </div>
    <div class="address" [class.has-rating]="case.customer != null && case.customerSatisfactionRating">
        {{case.damageAddress.street}}<br />{{case.damageAddress.zip}}
        <div class="rating" *ngIf="case.customer != null && case.customerSatisfactionRating" [title]="case.customerSatisfactionRating"><i class="fa fa-star"></i></div>
    </div>

    <div class="damagetype">
        {{case.damageTypeName}}
    </div>
    <div class="vehicles">
        <vehicle-preview *ngFor="let v of case.vehicles" [vehicle]="v"></vehicle-preview>
    </div>
    <div class="insurance-company">
        {{case.insuranceCompanyName}}
    </div>
    <div class="appointment" *ngIf="case.nextAppointment">
        <span *ngIf="case.nextAppointment?.type == 0">Møde</span>
        <span *ngIf="case.nextAppointment?.type == 1">Fugt</span>
    </div>
</div>
