import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class IsBrowserHelper {
  private isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  isRunningInBrowser(): boolean {
    return this.isBrowser;
  }
}

// @Injectable({
//     providedIn: 'root',
// })
// export class IsBrowserHelper {
//     public static isBrowser: boolean;

//     constructor(@Inject(PLATFORM_ID) private platformId: object) {
//       IsBrowserHelper.isBrowser = isPlatformBrowser(this.platformId);
//       IsBrowserHelper.isBrowser = true;
//     }

//     isBrowser(): boolean {
//       return isPlatformBrowser(this.platformId);
//     }
// }