<div class="casedetails employeedetails">
    <div *ngIf="employee">
        <div class="casedetailpanel">
            <form (ngSubmit)="onSubmitUpdateEmployee()" #updateEmpForm="ngForm">

                <div class="form-group">
                    <label for="employeeUserName">Brugernavn</label>
                    <input type="text" class="form-control" id="employeeUserName" [(ngModel)]="employee.userName" name="employeeUserName">
                </div>

                <div class="form-group">
                    <label for="employeeName">Navn</label>
                    <input type="text" class="form-control" id="employeeName" [(ngModel)]="employee.fullName" name="employeeName" required>
                </div>

                <div class="form-group">
                    <label for="employeeDisplayName">Initialer</label>
                    <input type="text" class="form-control" id="employeeDisplayName" [(ngModel)]="employee.displayName" name="employeeDisplayName" required maxlength="3" #employeeDisplayName="ngModel">
                    <div *ngIf="employeeDisplayName.invalid && (employeeDisplayName.dirty || employeeDisplayName.touched)"
                         class="validation-error">
                        <div *ngIf="employeeDisplayName.errors!['maxlength']">
                            Initialer kan maks. være 3 tegn
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="employeeOccupation">Stilling</label>
                    <input type="text" class="form-control" id="employeeOccupation" [(ngModel)]="employee.occupation" name="employeeOccupation">
                </div>

                <div class="form-group">
                    <label for="employeePhone">Telefon</label>
                    <input type="text" class="form-control" id="employeePhone" [(ngModel)]="employee.phoneNumber" name="employeePhone">
                </div>

                <div class="form-group">
                    <label for="employeeCellphone">Mobil</label>
                    <input type="text" class="form-control" id="employeeCellphone" [(ngModel)]="employee.cellphoneNumber" name="employeeCellphone">
                </div>

                <div class="form-group">
                    <label for="employeeEmail">Email</label>
                    <input type="text" class="form-control" id="employeeEmail" [(ngModel)]="employee.email" name="employeeEmail" required>
                </div>

                <div class="info">

                </div>
                
                <div *ngIf="employee.vehicle">
                    <div>Medarbejder er registreret til: {{employee.vehicle.shortName}}</div>
                    <div>Registreringsnummer: {{employee.vehicle.registrationNumber}}</div>
                    <button (click)="removeVehicle($event, employee)" class="link"><i class="fa fa-trash" aria-hidden="true"></i> Fjern bil fra medarbejder</button>
                </div>
                <div class="vehicles">
                    <div *ngIf="!employee.vehicleId" class="add input-group">
                        <select class="form-control" id="vehicleId" name="vehicleId" [(ngModel)]="selectedVehicle">
                            <option [ngValue]="null">Vælg et køretøj</option>
                            <option [ngValue]="v" *ngFor="let v of allVehicles | async">
                                {{v.registrationNumber}} - {{v.shortName}}
                            </option>
                        </select>
                        <span class="input-group-addon">
                            <button type="button" [disabled]="!selectedVehicle" (click)="addVehicle()">Tildel</button>
                        </span>
                    </div>
                </div>

                <div class="form-group">
                    <!-- <label><input type="checkbox" size="10" name="disabled" [(ngModel)]="employee.disabled" /> Deaktiveret</label> -->
                    <mat-checkbox color="primary" name="disabled" [(ngModel)]="employee.disabled">Deaktiveret</mat-checkbox>
                </div>

                <div class="center-row">
                    <button type="submit" class="submitbutton">Gem</button>
                </div>
            </form>
        </div>
    </div>
</div>
