@if (error$ | async; as error) {
<div class="error">{{ error }}</div>
}

@if (loaded$ | async) {
<div class="case-details-reports">
  @if (report$ | async; as report) {
  <!-- Report found-->
  <div class="report-preview">
    <h3>{{ (reportType$ | async)?.name }}</h3>
    <div class="date">{{ formatDate(report.updated) }}</div>
    <button class="link" (click)="editReport($event)">Rediger</button>
  </div>
  } @else {
  <!--No report found-->
  <button (click)="createReport($event)">Opret rapport</button>
  }
</div>
}
