import { ReportBase } from "./report-base";

type BuildingType = "house" | "garage" | "barn" | "stable" | "fence" | "conservatory" | "other";
type BuildingMaterial = "brick" | "concrete" | "wood" | "other";

type RoofType = "tile" | "sheet" | "bitumen" | "thatched" | "other";
type RoofPitch = "flat" | "normal" | "high";
type LiftAccessibility = "good" | "difficult" | "impossible";

type Quantity = "none" | "few" | "medium" | "many";

export class StormReport extends ReportBase {
    //Building
    buildingType!: BuildingType;
    buildingTypeOther!: string;
    buildingMaterial!: BuildingMaterial;
    buildingMaterialOther!: string;
    windowDoorCount!: number;

    //Roof
    roofType!: RoofType;
    roofTypeOther!: string;
    roofPitch!: RoofPitch;
    roofHeight!: number;

    //Purpose unclear
    roofFloor!: string;
    roofCoveringFromInsidePossible!: boolean;
    roofLiftRequired!: boolean;
    liftAccessibility!: LiftAccessibility;
    roofHoleCount!: number;
    roofSingleHoleSize!: number;
    waterIngress!: boolean;
    powerFailed!: boolean;
    livestock!: boolean;
    householdEffects!: Quantity;
    notes!: string;
}
