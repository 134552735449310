<h2>Stormrapport</h2>
<form (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" class="report">
    <fieldset>
        <legend>Bygning</legend>
        <div class="row">
            <div class="col-m-12">
                <div [class.group-invalid]="buildingType.invalid && buildingType.errors?.['required']">
                    <label>Type</label>
                    <div class="radio-group inline">
                        <label><input type="radio" [(ngModel)]="formModel.buildingType" value="house" name="buildingType" [disabled]="isdisabled" required #buildingType="ngModel" /> Hus</label>
                        <label><input type="radio" [(ngModel)]="formModel.buildingType" value="garage" name="buildingType" [disabled]="isdisabled" required #buildingType="ngModel" /> Garage</label>
                        <label><input type="radio" [(ngModel)]="formModel.buildingType" value="barn" name="buildingType" [disabled]="isdisabled" required #buildingType="ngModel" /> Lade</label>
                        <label><input type="radio" [(ngModel)]="formModel.buildingType" value="stable" name="buildingType" [disabled]="isdisabled" required #buildingType="ngModel" /> Stald</label>
                        <label><input type="radio" [(ngModel)]="formModel.buildingType" value="fence" name="buildingType" [disabled]="isdisabled" required #buildingType="ngModel" /> Hegn</label>
                        <label><input type="radio" [(ngModel)]="formModel.buildingType" value="conservatory" name="buildingType" [disabled]="isdisabled" required #buildingType="ngModel" /> Havestue</label>
                        <label><input type="radio" [(ngModel)]="formModel.buildingType" value="other" name="buildingType" [disabled]="isdisabled" required #buildingType="ngModel" /> Andet</label>
                    </div>
                    <div *ngIf="formModel.buildingType == 'other'">
                        <label class="visuallyhidden" for="buildingTypeOther">Anden bygningstype</label>
                        <input type="text" name="buildingTypeOther" id="buildingTypeOther" [(ngModel)]="formModel.buildingTypeOther" required [disabled]="isdisabled" />
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-m-12">
                <div [class.group-invalid]="buildingWall.invalid && buildingWall.errors?.['required']">
                    <label>Gavl/vægge</label>
                    <div class="radio-group inline">
                        <label><input type="radio" [(ngModel)]="formModel.buildingMaterial" value="brick" name="buildingMaterial" [disabled]="isdisabled" required #buildingWall="ngModel" /> Musten</label>
                        <label><input type="radio" [(ngModel)]="formModel.buildingMaterial" value="concrete" name="buildingMaterial" [disabled]="isdisabled" required #buildingWall="ngModel" /> Gasbeton</label>
                        <label><input type="radio" [(ngModel)]="formModel.buildingMaterial" value="wood" name="buildingMaterial" [disabled]="isdisabled" required #buildingWall="ngModel" /> Træ</label>
                        <label><input type="radio" [(ngModel)]="formModel.buildingMaterial" value="other" name="buildingMaterial" [disabled]="isdisabled" required #buildingWall="ngModel" /> Andet</label>
                    </div>
                    <div *ngIf="formModel.buildingMaterial == 'other'">
                        <label class="visuallyhidden" for="buildingMaterialOther">Andet bygningsmateriale</label>
                        <input type="text" name="buildingMaterialOther" id="buildingMaterialOther" [(ngModel)]="formModel.buildingMaterialOther" required [disabled]="isdisabled" />
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-m-12">
                <label for="windowDoorCount">Antal vinduer/døre</label>
                <input type="number" name="windowDoorCount" id="windowDoorCount" [(ngModel)]="formModel.windowDoorCount" [disabled]="isdisabled" required />
            </div>
        </div>
    </fieldset>

    <fieldset>
        <legend>Tag</legend>
        <div class="row">
            <div class="col-m-12">
                <div [class.group-invalid]="buildingRoof.invalid && buildingRoof.errors?.['required']">
                    <label>Tagtype</label>
                    <div class="radio-group inline">
                        <label><input type="radio" [(ngModel)]="formModel.roofType" value="tile" name="roofType" [disabled]="isdisabled" required #buildingRoof="ngModel" /> Tegl</label>
                        <label><input type="radio" [(ngModel)]="formModel.roofType" value="sheet" name="roofType" [disabled]="isdisabled" required #buildingRoof="ngModel" /> Plader</label>
                        <label><input type="radio" [(ngModel)]="formModel.roofType" value="bitumen" name="roofType" [disabled]="isdisabled" required #buildingRoof="ngModel" /> Tagpap</label>
                        <label><input type="radio" [(ngModel)]="formModel.roofType" value="thatched" name="roofType" [disabled]="isdisabled" required #buildingRoof="ngModel" /> Stråtag</label>
                        <label><input type="radio" [(ngModel)]="formModel.roofType" value="other" name="roofType" [disabled]="isdisabled" required #buildingRoof="ngModel" /> Andet</label>
                    </div>
                    <div *ngIf="formModel.roofType == 'other'">
                        <label class="visuallyhidden" for="roofTypeOther">Anden tagtype</label>
                        <input type="text" name="roofTypeOther" id="roofTypeOther" [(ngModel)]="formModel.roofTypeOther" required [disabled]="isdisabled" />
                    </div>
                </div>
            </div>
        </div>
        <br />

        <div class="form-group">
            <div class="form-item">
                <div class="input-group">
                <label for="roofHeight">Højde</label>
                    <input type="number" [(ngModel)]="formModel.roofHeight" name="roofHeight" id="roofHeight" [disabled]="isdisabled" required />
                    <span class="input-group-addon unit">m</span>
                </div>
            </div>
            <div class="form-item">
                <div class="input-group">
                    <label for="roofFloor">Etage</label>
                    <input type="number" [(ngModel)]="formModel.roofFloor" name="roofFloor" id="roofFloor" [disabled]="isdisabled" required />
                </div>
            </div>
        </div>
        
        <!-- <div class="row"> -->
            <!-- <div class="col-m-12"> -->
        <label class="checkbox-label">
            <input type="checkbox" class="custom-checkbox" [(ngModel)]="formModel.roofCoveringFromInsidePossible" name="roofCoveringFromInsidePossible" [disabled]="isdisabled" /> Afdækning kan foretages indefra
        </label>
            <!-- </div> -->
        <!-- </div> -->

        <!-- <div class="row">
            <div class="col-m-12"> -->
        <label class="checkbox-label">
            <input type="checkbox" class="custom-checkbox" [(ngModel)]="formModel.roofLiftRequired" name="roofLiftRequired" [disabled]="isdisabled" /> Lift nødvendig
        </label>
            <!-- </div>
        </div> -->
        
        <div class="spacing-top" *ngIf="formModel.roofLiftRequired">
            <div class="col-m-12">
                <div [class.group-invalid]="liftRequired.invalid && liftRequired.errors?.['required']">
                    <label>Adgangsforhold for lift</label>
                    <div class="radio-group inline">
                        <label><input type="radio" [(ngModel)]="formModel.liftAccessibility" value="good" name="liftAccessibility" [disabled]="isdisabled" required #liftRequired="ngModel" /> OK</label>
                        <label><input type="radio" [(ngModel)]="formModel.liftAccessibility" value="difficult" name="liftAccessibility" [disabled]="isdisabled" required #liftRequired="ngModel" /> Besværlig</label>
                        <label><input type="radio" [(ngModel)]="formModel.liftAccessibility" value="impossible" name="liftAccessibility" [disabled]="isdisabled" required #liftRequired="ngModel" /> Umulig</label>
                    </div>
                </div>
            </div>
        </div>

        <label for="roofHoleCount">Antal huller</label>
        <input type="number" [(ngModel)]="formModel.roofHoleCount" name="roofHoleCount" id="roofHoleCount" [disabled]="isdisabled" required />
        <div *ngIf="formModel.roofHoleCount == 1">
            <label for="roofFloor">Størrelse</label>
            <div class="input-group">
                <input type="number" class="roofHoleCount" [(ngModel)]="formModel.roofSingleHoleSize" name="roofSingleHoleSize" id="roofSingleHoleSize" [disabled]="isdisabled" required/>
                <span class="input-group-addon unit">m<sup>2</sup></span>
            </div>
        </div>
    </fieldset>

    <fieldset>
        <legend>Skader</legend>
        <label class="checkbox-label">
            <input type="checkbox" class="custom-checkbox" [(ngModel)]="formModel.waterIngress" name="waterIngress" [disabled]="isdisabled" /> Vand i bygning
        </label>

        <label class="checkbox-label">
            <input type="checkbox" class="custom-checkbox" [(ngModel)]="formModel.powerFailed" name="powerFailed" [disabled]="isdisabled" /> Strøm mangler
        </label>
            
        <label class="checkbox-label">
            <input type="checkbox"  class="custom-checkbox" [(ngModel)]="formModel.livestock" name="livestock" [disabled]="isdisabled" /> Dyr (landbrug)
        </label>

        <div class="spacing-top" [class.group-invalid]="household.invalid && household.errors?.['required']">
            <label>Løsøre i bygning</label>
            <div class="radio-group inline">
                <label><input type="radio" [(ngModel)]="formModel.householdEffects" value="none" name="householdEffects" [disabled]="isdisabled" required #household="ngModel" /> Intet</label>
                <label><input type="radio" [(ngModel)]="formModel.householdEffects" value="few" name="householdEffects" [disabled]="isdisabled" required #household="ngModel" /> Lidt</label>
                <label><input type="radio" [(ngModel)]="formModel.householdEffects" value="medium" name="householdEffects" [disabled]="isdisabled" required #household="ngModel" /> Medium</label>
                <label><input type="radio" [(ngModel)]="formModel.householdEffects" value="many" name="householdEffects" [disabled]="isdisabled" required #household="ngModel" /> Mange</label>
            </div>
        </div>

    </fieldset>
    <br />

    <fieldset>
        <label for="notes">Bemærkninger</label>
        <textarea class="text-area" name="notes" id="notes" [(ngModel)]="formModel.notes" [disabled]="isdisabled" required></textarea>
    </fieldset>

    <div *ngIf="!f.form.valid">
        Bemærk! Der er felter der mangler at blive udfyldt.
    </div>

    <div class="buttons" *ngIf="mode == 'create'">
        <button type="submit" class="primary" [disabled]="isdisabled || !f.form.valid">Opret rapport</button>
        <button (click)="cancel($event)">Annuller</button>
    </div>
    <div class="buttons" *ngIf="mode == 'edit'">
        <button type="submit" class="primary" [disabled]="isdisabled || !f.form.valid">Gem og luk</button>
        <button (click)="cancel($event)">Annuller</button>
    </div>
</form>