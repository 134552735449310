import { ReportBase } from "../report-base";

export class CloudBurstReport extends ReportBase {
    damagedFloorArea!: number;
    damagedWallArea!: number;
    waterHeight!: number;
    waterIngressRoute!: string;
    damageNotes!: string;

    //actions performed
    actionWaterPumped!: boolean;
    actionDisinfected!: boolean;
    actionWoodFloorRemoved!: boolean;
    woodFloorRemovedArea!: number;
    actionCarpetRemoved!: boolean;
    carpetRemovedArea!: number;
    actionEmergencyCleaning!: boolean;
    actionPhoto!: boolean;
    actionFloorSampleTaken!: boolean;
    actionNotes!: string;

    //actions to perform
    todoClean!: boolean;
    todoDisinfect!: boolean;
    todoDehumidify!: boolean;
    todoMeasureHumidity!: boolean;
    todoRemoveFloor!: boolean;
    todoRemoveWall!: boolean;
    todoFinalCleaning!: boolean;
    todoHouseholdEffectList!: boolean;
    todoNotes!: string;
}