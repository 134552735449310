<div class="pane-list">
    <div class="list-container list">
        <table class="item-table">
            <thead>
                <tr>
                    <th>Løsøre</th>
                    <th>Anskaffelsesværdi</th>
                    <th>Købsdato</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let h of householdeffects"
                    [class.selected]="h === selectedHouseholdEffect"
                    (click)="onSelect(h)">
                    <td class="item-header">
                        {{h.itemText}}
                    </td>
                    <td data-label="Anskaffelsesværdi">
                        {{ h.itemPrice | currency:'DKK':'symbol':'1.2-2' }}
                    </td>
                    <td data-label="Købsdato">
                        {{h.itemAcquisitionDate.toString() !== '0001-01-01T00:00:00+00:00' ? (h.itemAcquisitionDate | date:'dd-MM-YYYY') : ''}}
                    </td>
                    <td class="actions">
                        <button class="link" (click)="delete(h)" title="Slet"><i class="fa fa-trash"></i><span class="visuallyhidden"></span></button>
                    </td>
                </tr>
            </tbody>
        </table>
        <button (click)="addNewHouseholdEffect()"><i class="fa fa-plus"></i> Opret ny løsøre</button>
    </div>
    <div class="detail-container">
        <div class="detailpanel">
            <div *ngIf="selectedHouseholdEffect">
                <form (ngSubmit)="onSubmitUpdateHousehold()" #f="ngForm" autocomplete="off">
                    <div class="form-group" [ngClass]="{ 'has-error': f.submitted}">
                        <label for="itemText">Løsøre</label>
                        <input type="text" class="form-control" name="itemText" [(ngModel)]="selectedHouseholdEffect.itemText" #itemText="ngModel" required />
                        <div *ngIf="f.submitted && !itemText.valid" class="help-block">Beskrivelse er obligatorisk</div>
                    </div>
                    <div class="form-group" [ngClass]="{ 'has-error': f.submitted}">
                        <label for="itemPrice">Anskaffelsesværdi</label>
                        <div class="input-group">
                            <input type="number" class="form-control input-width" name="itemPrice" [(ngModel)]="selectedHouseholdEffect.itemPrice" #itemPrice="ngModel" required />
                            <span class="input-group-addon supplement">&nbsp;kr.</span>
                        </div>
                        <div *ngIf="f.submitted && !itemPrice.valid" class="help-block">Anskaffelsesværdi er obligatorisk</div>
                    </div>
                    <div class="form-group" [ngClass]="{ 'has-error': f.submitted}">
                        <label for="itemAcquisitionDate">Købsdato</label><br>
                        <!-- <dr-datepicker fieldid="itemAcquisitionDate" name="itemAcquisitionDate" [(ngModel)]="selectedHouseholdEffect.itemAcquisitionDate" #itemAcquisitionDate="ngModel" required></dr-datepicker> -->
                        <mat-form-field class="datepickerfield">
                            <mat-label>Vælg dato</mat-label>
                            <input matInput [matDatepicker]="dp" [formControl]="datepickerControl" name="itemAcquisitionDate" (dateChange)="onDateChange($event)" required>
                            <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
                            <mat-datepicker #dp></mat-datepicker>
                            <mat-hint>cola</mat-hint>
                        </mat-form-field>                
                    </div>
                    <div *ngIf="f.submitted && !datepickerControl.valid" class="help-block">Anskaffelsesdato er obligatorisk</div>

                    <div class="form-group" *ngIf="selectedHouseholdEffect.attachedCases && selectedHouseholdEffect.attachedCases.length > 0">
                        Tilknyttet til sag:
                        <ul>
                            <li *ngFor="let c of selectedHouseholdEffect.attachedCases">{{c.caseNumber}}</li>
                        </ul>
                    </div>
                    <div class="input-group-addon text-center row align-center">
                        <button type="submit" class="btn btn-default" [disabled]="!f.valid || !datepickerControl.valid">Gem</button>&nbsp;
                        <button *ngIf="addingnew" (click)="cancelAddingNew()">Annuller</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>