import { Injectable } from '@angular/core';
import { Company } from '../interfaces/company';
import { Observable, from } from 'rxjs';
import { ServiceBase } from './base/service-base.service';
import { AlertService } from './alert.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends ServiceBase {
  company!: Company | null;

  constructor(authhttp: HttpClient, alertService: AlertService) {
      super(authhttp);
  }

  public getCompany(): Observable<Company> {
      if (this.company) {
          return from([this.company]);
      }

      return this.getJson<Company>("/api/company");
  }

  public reset(): void {
      this.company = null;
  }
}
