<div class="pane-list">
    <div class="dclist spinner-container list-container">
        <dr-spinner [isRunning]="loading"></dr-spinner>
        <div class="container-fluid">
            <div class="row">
                <div class="col-xs-12">                    
                    <div class="filter">
                        <search *ngIf="searchService.enabled"></search>
                    </div>
                </div>
            </div>
            <div class="list" #list>
                <cdk-virtual-scroll-viewport #caseRowViewport itemSize="50" class="virtual-scroll">
                    <case-list-item *cdkVirtualFor="let item of damagecases" [case]="item" (click)="onSelect(item)"  [id]="'case-' + item.id"></case-list-item>
                </cdk-virtual-scroll-viewport>
            </div>          
        </div>
    </div>
    <div class="detail-container">
        <div class="detailpanel" style="height: 100vh;">
            <app-home-statistics *ngIf="showHomeStatistics"></app-home-statistics>
            <app-case-details *ngIf="showCaseDetails" [case]="selectedDamageCase!"></app-case-details>
            <add-new-case *ngIf="showAddNewCase"></add-new-case>
            <app-edit-case *ngIf="showEditCase" [model]="selectedDamageCase!"></app-edit-case>
            <edit-report *ngIf="showEditReport" [case]="selectedDamageCase!" (isEditAssistancerapport)="receiveData($event)"></edit-report>
            <create-report *ngIf="showCreateReport" [case]="selectedDamageCase!" (isCreateAssistancerapport)="receiveData($event)"></create-report>
          </div>
    </div>
</div>
