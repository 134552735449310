<div class="dashboard" *ngIf="loaded">
    <div class="casedetailpanel panelred" >
        <h1><strong>{{countNew}}</strong> nye sager</h1>
    </div>
    <div class="casedetailpanel">
        <h1><strong>{{countOpen}}</strong> åbne sager</h1>
    </div>
    <div *ngIf="authService.userRole != '3'" class="casedetailpanel">
        <equipment-statistics></equipment-statistics>
    </div>
    <div class="casedetailpanel" *ngIf="authService.userRole == '2'">
        <h1>Kundetilfredshed</h1>
        <customer-satisfaction-graph></customer-satisfaction-graph>
    </div>
</div>
