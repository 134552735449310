import { afterNextRender, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { Observable, Subscription, filter, of } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SpinnerComponent } from "../ui/spinner/spinner.component";
import { SearchComponent } from "../search/search.component";
import { CaseListItemComponent } from "../case/case-list-item/case-list-item.component";
import { DamageCase } from '../../models/damage-case';
import { Customer } from '../../models/customer';
import { DamageCaseService } from '../../services/damage-case.service';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { UserService } from '../../services/user.service';
import { ActionService, ActionUnion } from '../../services/action.service';
import { SearchService } from '../../services/search.service';
import { NotificationService } from '../../services/notification.service';
import { HeaderButton } from '../../interfaces/header-button';
import { SearchCriteria } from '../../interfaces/search-criteria';
import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { CaseDetailsComponent } from "../case/case-details/case-details.component";
import { HomeStatisticsComponent } from "../home-statistics/home-statistics.component";
import { EditCaseComponent } from '../case/edit-case/edit-case.component';
import { EditReportComponent } from "../reports/edit-report/edit-report.component";
import { CreateReportComponent } from '../reports/create-report/create-report.component';
import { AppComponent } from '../../app.component';
import { AddNewCaseComponent } from '../case/add-new-case/add-new-case-component.component';
import { DamageCaseIdService } from '../../services/damagecases/damage-case-id.service';
import { toCamelCase } from '../../helpers/element-to-camelcase';

@Component({
    selector: 'app-home',
    standalone: true,
    imports: [
      CommonModule,
      FormsModule,
      RouterModule,
      ScrollingModule,
      CdkVirtualScrollViewport,
      SpinnerComponent,
      SearchComponent,
      CaseListItemComponent,
      CaseDetailsComponent,
      HomeStatisticsComponent,
      EditCaseComponent,
      EditReportComponent,
      CreateReportComponent,
      AddNewCaseComponent
    ],
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('caseRowViewport') viewport!: CdkVirtualScrollViewport;
  @ViewChild("list") listElm!: ElementRef;

  selectedDamageCaseId!: number | null;
  selectedDamageCase!: DamageCase | undefined;
  
  private damageCaseSubscription: Subscription | null = null;
  
  damagecasesSubject!: DamageCase[] | null;
  damagecases!: DamageCase[] | null;
  customers!: Customer[];
  newNoteText!: string;

  isCreateMode: boolean = false;
  isEditMode: boolean = false;
  isEditAssistancerapport: boolean = false;
  isCreateAssistancerapport: boolean = false;
  loading: boolean = true;
  currentUrl: string = "";

  constructor(
    private damagecaseService: DamageCaseService,
    private damageCaseIdService: DamageCaseIdService,
    public authService: AuthenticationService,
    public appComponent: AppComponent,
    private cdRef: ChangeDetectorRef,
    private userService: UserService,
    private actionService: ActionService,
    public searchService: SearchService,
    private notificationService: NotificationService,
    public router: Router,
    private route: ActivatedRoute,
    private damageCaseService: DamageCaseService) {

    // Get the params of last child found
    let activeRoute = this.route;
    while (activeRoute.firstChild) {
      activeRoute = activeRoute.firstChild;
    }
  
    activeRoute.paramMap.subscribe(paramMap => {
      const caseId = paramMap.get('caseid');
      if (caseId) {
        if (this.selectedDamageCaseId !== parseInt(caseId, 10)) {
          this.damageCaseIdService.setSelectedDamageCaseId(parseInt(caseId, 10));
        }
      } else {
        this.damageCaseIdService.setSelectedDamageCaseId(null);
      }
    });

    // Start SignalR
    if (authService.loggedin) {
      console.log("Start SignalR connection...");
      this.notificationService.startSignalRConnection();
    }

    // Subscribe selectedDamageCaseId changes
    this.damageCaseIdService.selectedDamageCaseId$
      .subscribe((id: number | null) => {
        this.selectedDamageCaseId = id;

        if (id !== null) {
          this.damageCaseService.details(id)
            .pipe(filter(result => result !== null))
            .subscribe((result: DamageCase) => {
              this.selectedDamageCase = result;
              console.log("Details: ", this.selectedDamageCase);
            });
        } else {
          this.selectedDamageCase = undefined;
        }
      });

    this.damageCaseSubscription = this.damagecaseService.damageCasesSubject
      .subscribe((data: DamageCase[]) => {
        this.damagecases = data;
        this.loading = false;

        if (this.selectedDamageCaseId) {
          this.scrollToCase(this.selectedDamageCaseId);
        }
      });

    // Start fetching streamed data
    this.damagecaseService.fetchStreamedItems();

      // Monitor router events to detect navigation changes
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.isCreateMode = false;
          this.isEditMode = false;
          this.isCreateAssistancerapport = false;
          this.isEditAssistancerapport = false;
      
          this.currentUrl = event.urlAfterRedirects;
          // console.log(`Navigated to: ${this.currentUrl}`);
      
          switch (true) {
            case this.currentUrl.includes("addcase"):
            this.isCreateMode = true;
              break;
            case this.currentUrl.includes('edit'):
              this.isEditMode = true;
              break;
            
            case this.currentUrl.includes('createreport'):
              this.isCreateAssistancerapport = true;
              break;
      
            case this.currentUrl.includes('report'):
              this.isEditAssistancerapport = true;
              break;
            
            default:
              break;
          }
        }
      });

      this.actionService.onAction.subscribe(a => this.onAction(a));
      this.actionService.onButtonClick.subscribe(a => {
        // If a case is selected and create button pressed, abandon the previous.
        if (a.alias == 'newCase') {
          this.selectedDamageCase = undefined;
          this.damageCaseIdService.setSelectedDamageCaseId(null);
        }

        this.onHeaderButtonClick(a);
      });

      this.notificationService.notifications
      .pipe(
        filter(n => n.Type === "damageCaseNoteStatusUpdated" ||
                    n.Type === "damageCaseAppointmentStatusUpdated" ||
                    n.Type === "damageCaseCreated" ||
                    n.Type === "damageCaseUpdated")
      )
      .subscribe(n => {
        console.log("Notification emitted: ", n);

        switch(n.Type) {
          case "damageCaseNoteStatusUpdated": {
            console.log("damageCaseNoteStatusUpdated");
            const value = <boolean>n.Data;
            const dc = this.damagecases?.find(x => x.id === n.Context.id);
            if (dc) {
              dc.hasNotes = value;
            }
            break;
          }
          case "damageCaseAppointmentStatusUpdated": {
            console.log("damageCaseAppointmentStatusUpdated");
            const value = <boolean>n.Data;
            const dc = this.damagecases?.find(x => x.id === n.Context.id);
            if (dc) {
              dc.hasAppointments = value;
              dc.nextAppointment = n.Context.nextAppointment;
            }
            break;
          }
          case "damageCaseCreated": {
            console.log("damageCaseCreated");
            const dc = toCamelCase(n.Data) as DamageCase;
            this.damageCaseService.addDamageCaseItem(dc);
            this.sortDamageCases(this.damagecases!);
            break;
          }
          case "damageCaseUpdated": {
            const dc = toCamelCase(n.Data) as DamageCase;
            if (this.selectedDamageCase) {
              this.selectedDamageCase = { ...this.selectedDamageCase, ...dc };
              this.sortDamageCases(this.damagecases!);
            }
            break;
          }
          default: {
            console.warn(`Unhandled notification type: ${n}`);
            break;
          }
        }
      });

      this.searchService.criteria
      .pipe(
        distinctUntilChanged()
      )
      .subscribe((c: SearchCriteria) => {
        this.loadFilteredResults(c);
      });
  
      // Use afterNextRender for viewport-related operations
      afterNextRender(() => {
        // Any initialization that requires the view to be ready
      });
  }

  ngOnInit(): void {
    if (!this.authService.isCustomer) {
      //Customers shouldn't load users list
      this.getUsers();
    }
    
    this.actionService.addHeaderButton({
        alias: "newCase",
        text: "Opret sag",
        iconClass: "fa-plus"
    });

    this.searchService.enabled = true;
  }

  receiveData(data: boolean) {
    this.isEditAssistancerapport = data;
    this.isCreateAssistancerapport = data;    
  }

  // receivedDamageCase(damageCase: DamageCase) {
  //   this.damageCaseService.addDamageCaseItem(damageCase);
  // }

  private onAction(action: ActionUnion) {
  }

  private onHeaderButtonClick(btn: HeaderButton) {
      if (btn.alias === "newCase") {
          this.router.navigate(["addcase"]);
      }
  }

  // Method to scroll to a specific case element based on caseId
  scrollToCase(caseId: number) {
    const index: number = this.damagecases!.findIndex(x => x.id == caseId);

    if (index !== -1  && this.viewport) {
      // Use requestAnimationFrame for smooth scrolling
      requestAnimationFrame(() => {
        this.viewport.scrollToIndex(index, 'smooth');
      });
    }
  }

  private modalAlert(dc: DamageCase) {
    //TODO: Dialog
    // this.modal.alert().message("Der skal først oprettes en skaderapport på sag: " + dc.caseNumber + " navn: " + dc.customer.fullName).open();
  }

  private verifyReportStatusForUser(): any {
      var cases = this.damagecases!.filter(x => !x.hasReport && x.checkinEmployee === this.authService.userId);
      if (cases.length > 0) {
          this.selectCase(cases[0]);

          this.modalAlert(cases[0]);
      }
  }

  getUsers(): void {
    this.userService.getCustomers()
      .subscribe({
        next: (dcresult) => {
          this.customers = dcresult;
        }
      });
  }

  private loadFilteredResults(criteria: SearchCriteria): void {
    // Start fetching streamed data
    this.loading = true;
    this.damagecaseService.fetchStreamedSearchItems(criteria);
    this.damageCaseService.removeUnfetchedItems();

    this.loading = false;
  }

  onSelect(damageCase: DamageCase): void {
      if (this.selectedDamageCaseId === damageCase.id) {
          this.router.navigate(["/"]);
          this.selectedDamageCase = undefined;
          this.damageCaseIdService.setSelectedDamageCaseId(null);
      }
      else {
          this.selectCase(damageCase);
      }
  }

  private selectCase(damageCase: DamageCase) {
      this.damageCaseIdService.setSelectedDamageCaseId(damageCase.id);
      this.router.navigateByUrl(`/case/${damageCase.id}`);
  }

  caseAssignedToEmployee(dcase: DamageCase): boolean {
      if (dcase.employees.find(x => x.id == this.authService.userId) == undefined) {
          return false;
      }
      else {
          return true;
      }
  }

  private sortDamageCases(array: DamageCase[]): DamageCase[] {
    return array.slice().sort((a, b) => {
        if (a.damageStatusId !== b.damageStatusId) {
            return a.damageStatusId - b.damageStatusId;
        }
        
        if (a.customer.customerIsIndustry !== b.customer.customerIsIndustry) {
            return a.customer.customerIsIndustry ? -1 : 1;
        }
        
        if (a.queueNumber !== undefined && b.queueNumber !== undefined) {
            return a.queueNumber - b.queueNumber;
        }
        
        return this.compareNumber(a.id, b.id);
    });
  }

  private compareNumber(a: number, b: number): number {
      return a > b ? 1 : a < b ? -1 : 0;
  }

  get showHomeStatistics(): boolean {
    return !this.selectedDamageCase && !this.isEditMode && !this.isEditAssistancerapport && !this.isCreateAssistancerapport && !this.isCreateMode;
  }
  
  get showCaseDetails(): boolean {
    return !!this.selectedDamageCase && !this.isEditMode && !this.isEditAssistancerapport && !this.isCreateAssistancerapport && !this.isCreateMode;
  }
  
  get showAddNewCase(): boolean {
    return !!this.isCreateMode && !this.isEditMode && !this.isEditAssistancerapport && !this.isCreateAssistancerapport;
  }
  
  get showEditCase(): boolean {
    return !!this.selectedDamageCase && this.isEditMode;
  }
  
  get showEditReport(): boolean {
    return !!this.selectedDamageCase && this.isEditAssistancerapport;
  }
  
  get showCreateReport(): boolean {
    return !!this.selectedDamageCase && this.isCreateAssistancerapport;
  }

    // Recursive function to find params in any child route
  private getParamsFromRoute(route: ActivatedRoute): Observable<any> {
    if (route.snapshot.params && Object.keys(route.snapshot.params).length > 0) {
      return of(route.snapshot.params); // Return the params if they exist in the current route
    }

    for (let child of route.children) {
      const result = this.getParamsFromRoute(child);
      if (result) {
        return result;
      }
    }

    return of(null); // Return null if no params found
  }

  ngOnDestroy(): void {
    // this.actionService.onAction.unsubscribe();
    this.searchService.enabled = false;
    this.actionService.removeHeaderButton("newCase");
    this.damageCaseSubscription?.unsubscribe();
    // this.notificationSubscription?.unsubscribe();
  }

}
