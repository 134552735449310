import { Injectable } from '@angular/core';
import { ServiceBase } from '../base/service-base.service';
import { AlertService } from '../alert.service';
import { MeasurementTransaction } from '../../models/measurement/measurement-transaction';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MeasurementTransactionService  extends ServiceBase {
  private baseUrl = '/api/measurement/transaction/';

  constructor(authhttp: HttpClient, alert: AlertService) {
      super(authhttp);
  }

  create(point: MeasurementTransaction): Observable<MeasurementTransaction> {
      return this.postJson<MeasurementTransaction>(this.baseUrl + "createitem", point);
  }

  get(pointId: number): Observable<MeasurementTransaction> {
      return this.getJson<MeasurementTransaction>(this.baseUrl + "get/" + pointId);
  }

  update(point: MeasurementTransaction): Observable<MeasurementTransaction> {
      return this.postJson<MeasurementTransaction>(this.baseUrl + "update", point);
  }

  delete(transaction: MeasurementTransaction): Observable<MeasurementTransaction> {
      return this.postJson<MeasurementTransaction>(this.baseUrl + "delete", transaction);
  }

  deleteMultiple(logId: number): Observable<true> {
      let data = {
          measurementLogId: logId
      };
      return this.postJson<true>(this.baseUrl + "deletemultiple", data);
  }
}
