import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { DamageCase } from '../../../../models/damage-case';
import { CompensationClaim } from '../../../../interfaces/compensation-claim';
import { CompensationClaimItem } from '../../../../interfaces/compensation-claim-item';
import { CompensationClaimItemRoom } from '../../../../interfaces/compensation-claim-item-room';
import { AuthenticationService } from '../../../../services/auth/authentication.service';
import { CompensationClaimService } from '../../../../services/compensation/compensation-claim.service';
import { sortArray } from '../../../../helpers/order';
import { HouseholdEffectListComponent } from '../../../household-effect/household-effect-list/household-effect-list.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogData } from '../../../../interfaces/ui/confirm-dialog';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UserAvatarComponent } from '../../../user-avatar/user-avatar.component';
import { MomentPipe } from '../../../../pipes/moment.pipe';
import { PercentagePipe } from "../../../../pipes/percentage.pipe";
import { HouseholdEffect } from '../../../../models/household-effect';
import { SampleListComponent } from "../sample-list/sample-list.component";
import { SampleItem } from '../../../../interfaces/sample-item';
import { MatTabsModule } from '@angular/material/tabs';
import { ReportsService } from '../../../../services/reports-service.service';
import { ConfirmDialogComponent } from '../../../ui/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from '../../../ui/alert-dialog/alert-dialog.component';
import { ApproveClaimDialogComponent, CompensationDialogData } from '../../../ui/approve-claim-dialog/approve-claim-dialog.component';

@Component({
  selector: 'app-compensation-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    UserAvatarComponent,
    MomentPipe,
    PercentagePipe,
    HouseholdEffectListComponent,
    SampleListComponent,
    MatTabsModule
],
  templateUrl: './compensation-dialog.component.html',
  styleUrl: './compensation-dialog.component.scss'
})
export class CompensationDialogComponent implements OnInit, AfterViewChecked {
  @Input() case!: DamageCase;

  public claim!: CompensationClaim;
  public itemEditing: boolean = false;

  dialogData!: ConfirmDialogData;
  title!:string;
  message!:string;

  private originalItem: CompensationClaimItem | undefined = undefined;
  private originalRoom!: CompensationClaimItemRoom | null;

  private shouldScrollListToBottom: boolean = false;

  public currentPriceTotal: number = 0;
  public compensationTotal: number = 0;
  public readOnly: boolean = false;
  public selectedRoom: CompensationClaimItemRoom | null = null;

  @ViewChild(HouseholdEffectListComponent) private householdEffectList!: HouseholdEffectListComponent;
  @ViewChild('listContainer') private listContainer!: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<CompensationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CompensationDialogData,
    public dialog: MatDialog,

    public authService: AuthenticationService,
    private reportService: ReportsService,
    private cdRef: ChangeDetectorRef,
    private compensationService: CompensationClaimService) {
      this.case = data.case;
  }

  ngOnInit(): void {
    this.compensationService.getOrCreateClaim(this.case.id)
      .subscribe((compensationClaim: any) => {
        this.claim = compensationClaim.result;

        const sortedRooms: CompensationClaimItemRoom[] = this.claim?.rooms ? sortArray(this.claim.rooms, "name") : [];
        if (sortedRooms.length > 0) {
            this.selectedRoom = sortedRooms[0];
        }

        this.updateCompensations();

        if (this.claim.approvedByCustomer || this.claim.processedByAssesor) {
            this.readOnly = true;
        }
      });
  }

  ngAfterViewChecked(): void {
    //We cannot do the actual scrolling in the event handler, since Angular has not updated the view at that point, and so we get the wrong scrollHeight
    //Instead, we set a flag and then do the scroll here, after the view has been updated
    if (this.shouldScrollListToBottom) {
        try {
            this.listContainer.nativeElement.scrollTop = this.listContainer.nativeElement.scrollHeight;
        } catch (err) { }
        this.shouldScrollListToBottom = false;
    }
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);  
  } 

  private updateCompensations(): void {
    this.compensationTotal = 0;
    this.currentPriceTotal = 0;

    // Early return if this.claim.rooms is null or empty
    if (!this.claim?.rooms?.length) return;

    for (const room of this.claim.rooms) {
        if (room.items?.length) {
            this.compensationTotal += room.items.reduce((sum, item) => sum + (item.compensationAmount || 0), 0);
            this.currentPriceTotal += room.items.reduce((sum, item) => sum + (item.currentPrice || 0), 0);

            for (const item of room.items) {
                item.invalid = !this.isItemValid(item);
            }
        }
    }

    this.cdRef.detectChanges();
  }

  private isItemValid(item: CompensationClaimItem): boolean {
    if (this.authService.isAdmin) {
        return !!(item.compensationAmount && item.writeDownPercentage);
    }
    return !!(item.purchaseDate && item.originalPrice && item.currentPrice);
  }

  private scrollListToBottom(): void {
    this.shouldScrollListToBottom = true;
  }

  selectRoom(room: CompensationClaimItemRoom) {
    this.selectedRoom = room;
  }

  editRoom(room: CompensationClaimItemRoom): void {
    if (this.readOnly) return;

    this.originalRoom = { ...room };
    Object.assign(room, { editing: true });
    this.itemEditing = true;

    this.cdRef.detectChanges();
  }

  deleteRoom(room: CompensationClaimItemRoom) {
    if (this.readOnly) {
        return;
    }

    const dialogRefDeleRoom = this.dialog.open(ConfirmDialogComponent, {
      width: "80%",
      maxWidth: "450px",
      disableClose: true,
      data: {
        title: "Rum",
        noButton: "Annuller",
        yesButton: "Slet rum",
        message: `Vil du slette rummet '${room.name}?`,
      }
    })

    dialogRefDeleRoom.afterClosed()
      .subscribe((dialogResult: boolean) => {
          if (dialogResult == true) {
            this.compensationService.deleteRoom(this.case.id, room)
              .subscribe(x => {
                this.cancelEditRoom(room);
              
                if (this.selectedRoom == room) {
                    this.selectedRoom = null;
                }

                if (this.claim.rooms) {
                  const index: number = this.claim.rooms.indexOf(room);
                  this.claim.rooms.splice(index, 1);

                  this.cdRef.detectChanges();
                }
              });            
          } 
      });
  }

  cancelEditRoom(room: CompensationClaimItemRoom): void {
    if (this.readOnly) return;

    if (room.id) {
        Object.assign(room, this.originalRoom);
        room.editing = false;
        this.originalRoom = null;
    } else {
        // Remove newly added unsaved item
        this.claim.rooms = this.claim.rooms!.filter(r => r !== room);
    }

    this.itemEditing = false;
  }

  saveRoom(room: CompensationClaimItemRoom): void {
    if (this.readOnly) return;

    const saveAction = room.id
        ? this.compensationService.updateRoom(this.case.id, room)
        : this.compensationService.createRoom(this.case.id, room.name);

    saveAction.subscribe((updatedRoom: any) => {
      Object.assign(room, updatedRoom.result);
      delete this.originalItem;

      room.editing = false;
      this.itemEditing = false;
      this.updateCompensations();

      // If a new room was created, select it
      if (!room.id) this.selectedRoom = room;
    });
  }

  editItem(item: CompensationClaimItem): void {
    if (this.readOnly) return;

    this.originalItem = { ...item };
    Object.assign(item, { editing: true });
    this.itemEditing = true;
  }

  deleteItem(item: CompensationClaimItem): void {
  if (this.readOnly) return;

  this.compensationService.delete(this.case.id, this.selectedRoom?.id as number, item)
    .subscribe(() => {
      this.selectedRoom!.items = this.selectedRoom?.items?.filter(i => i !== item) as CompensationClaimItem[];

      // Reload household effect list if it exists
      this.householdEffectList?.reload();

      this.updateCompensations();
    });
  }

  updateItemPercentage(item: CompensationClaimItem): void {
    if (this.readOnly || !item.currentPrice || item.currentPrice <= 0 || !item.compensationAmount) return;

    const writedownAmount = item.currentPrice - item.compensationAmount;
    item.writeDownPercentage = (writedownAmount / item.currentPrice) * 100;

    this.updateCompensations();
  }

  updateItemAmount(item: CompensationClaimItem): void {
    if (this.readOnly || !item.currentPrice || item.currentPrice <= 0) return;

    if (item.writeDownPercentage) {
        const writeDownAmount = item.currentPrice * (item.writeDownPercentage / 100);
        item.compensationAmount = item.currentPrice - writeDownAmount;
        this.updateCompensations();
    }
  }

  cancelEditItem(item: CompensationClaimItem): void {
    if (this.readOnly) return;

    if (item.id) {
        Object.assign(item, this.originalItem);
        item.editing = false;
        delete this.originalItem;
    } else {
        // Remove the unsaved item from the selectedRoom items array
        this.selectedRoom!.items = this.selectedRoom?.items?.filter(i => i !== item) as CompensationClaimItem[];
    }

    this.itemEditing = false;
  }

  saveItem(item: CompensationClaimItem): void {
    if (this.readOnly) return;

    const saveOperation = item.id 
        ? this.compensationService.update(this.case.id, this.selectedRoom?.id!, item)
        : this.compensationService.create(this.case.id, this.selectedRoom?.id!, item);

    saveOperation.subscribe((x) => {
        Object.assign(item, x);
        this.resetItemEditing(item);
        this.updateCompensations();
    });
  }

  addBlankItem(): void {
    if (this.readOnly || this.itemEditing) return;

    const item: CompensationClaimItem = {
        name: "",
        companyId: this.authService.companyId,
        claimId: this.claim.damageCaseId,
        roomId: this.selectedRoom?.id!
    };

    this.selectedRoom?.items!.push(item);
    this.editItem(item);
    this.scrollListToBottom();
  }

  addRoom(): void {
    if (this.readOnly || this.itemEditing) return;

    const room: CompensationClaimItemRoom = {
        companyId: this.authService.companyId,
        claimId: this.claim.damageCaseId,
        name: ""
    };

    this.claim.rooms = [...this.claim!.rooms!, room];
    this.editRoom(room);
  }

  addHouseholdEffect(item: HouseholdEffect): void {
    if (this.readOnly) return;

    const effectData: CompensationClaimItem = {
        name: item.itemText,
        companyId: this.authService.companyId,
        claimId: this.claim.damageCaseId,
        roomId: this.selectedRoom!.id!,
        originalPrice: item.itemPrice,
        purchaseDate: item.itemAcquisitionDate,
        householdEffectId: item.id
    };

    this.compensationService.create(this.case.id, this.selectedRoom?.id!, effectData)
      .subscribe(newItem => {
        this.selectedRoom!.items = sortArray([...this.selectedRoom?.items!, newItem], 'name');
        this.updateCompensations();
      });
  }

  addSampleItem(item: SampleItem): void {
    if (this.readOnly) return;

    const newItem: CompensationClaimItem = {
        name: item.name,
        companyId: this.authService.companyId,
        claimId: this.claim.damageCaseId,
        roomId: this.selectedRoom?.id!,
        createdById: this.authService.userId,
        updatedById: this.authService.userId,
    };

    this.compensationService.create(this.case.id, this.selectedRoom?.id!, newItem)
    .subscribe(createdItem => {
      this.selectedRoom!.items = sortArray([...this.selectedRoom?.items!, newItem], 'name');
        this.updateCompensations();
      });
  }

  public approve(): void {
    const allItemsValid = this.claim.rooms!.every(room => room.items!.every(item => !item.invalid));

    if (allItemsValid) {
      const context: CompensationDialogData = { case: this.case,claim: this.claim, totalCompensationAmount: this.compensationTotal };

      const dialogRefApprove = this.dialog.open(ApproveClaimDialogComponent, {
        width: "80%",
        maxWidth: "450px",
        disableClose: true,
        data: context
      })
  
      dialogRefApprove.afterClosed()
        .subscribe((dialogResult: boolean) => {
          if (dialogResult == true) {
            this.claim.approvedByCustomer = true;
            this.compensationService.updateClaim(this.claim.damageCaseId, this.claim)
              .subscribe(updatedClaim => {
                Object.assign(this.claim, updatedClaim);
              });
          } 
        });
    } else {
      this.dialog.open(AlertDialogComponent, {
        width: "80%",
        maxWidth: "460px",
        disableClose: true,
        hasBackdrop: false,
        panelClass: 'transparent-dialog',
        data: {
          type: "error",
          text: `Du har ikke udfyldt oplysninger alle emner på listen. De rækker, der mangler oplysninger, er markeret med rødt.`,
        }
      })      
    }
  }

  public openReport(): void {
    this.reportService.getReplacementReportForCase(this.claim.damageCaseId)
        .subscribe(blob => {
            const url = window.URL.createObjectURL(blob);
            window.open(url); // Open in a new window/tab
            window.URL.revokeObjectURL(url); // Cleanup the URL after opening
        });
    // window.open(`/api/compensationclaim/report/${this.claim.damageCaseId}`, '_blank');
  }

  /**
   * Set the flag that the claim is ready for customer approval
   */
  public sendForApproval(): void {
    const allItemsHaveCompensation = this.claim.rooms!.every(room => 
        room.items!.every(item => this.hasCompensationValues(item))
    );

    if (allItemsHaveCompensation) {
      const dialogRefSendForApproval = this.dialog.open(ConfirmDialogComponent, {
        width: "80%",
        maxWidth: "450px",
        disableClose: true,
        data: {
          title: "Godkendelse",
          noButton: "Annuller",
          yesButton: "Send til godkendelse",
          message: `Dette markerer, at erstatningslisten er klar til godkendelse hos kunden.`,
        }
      })
  
      dialogRefSendForApproval.afterClosed()
        .subscribe((dialogResult: boolean) => {
          if (dialogResult == true) {
            this.claim.processedByAssesor = true;
            this.compensationService.updateClaim(this.claim.damageCaseId, this.claim)
            .subscribe(() => this.cdRef.detectChanges());
          } 
        });

    } else {
      this.dialog.open(AlertDialogComponent, {
        width: "80%",
        maxWidth: "460px",
        disableClose: true,
        hasBackdrop: false,
        panelClass: 'transparent-dialog',
        data: {
          type: "error",
          text: `Du har ikke udfyldt erstatningsbeløb og nedskrivningsprocent for alle emner på listen. Før det er gjort, kan du ikke sende erstatningslisten til godkendelse`,
        }
      })
    }
  }

  private hasCompensationValues(item: CompensationClaimItem): boolean {
    return item.compensationAmount != null && item.writeDownPercentage != null;
  }


  private resetItemEditing(item: CompensationClaimItem): void {
    delete this.originalItem;
    item.editing = false;
    this.itemEditing = false;
  }


}