import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'newLine',
  standalone: true,
})
export class NewLinePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string | null | undefined): SafeHtml | string {
    if (!value) {
      return '';
    }

    const transformedValue = value.replace(/(?:\r\n|\r|\n)/g, '<br />');
    return this.sanitizer.bypassSecurityTrustHtml(transformedValue);
  }
}

