<div class="case-builder-list">
    <div class="add input-group">
        <select class="form-control" id="builderId" name="builderId" [(ngModel)]="selectedBuilder">
            <option disabled [ngValue]="blankBuilder">{{blankBuilder.name}}</option>
            <optgroup *ngFor="let type of allBuilders" label="{{type.name}}">
                <option [ngValue]="b" *ngFor="let b of type['builders']">
                    {{b.name}}, tlf. {{b.phoneNumber}}
                </option>
            </optgroup>
        </select>
        <span class="input-group-addon">
            <button type="button" [disabled]="selectedBuilder.id === -1" (click)="addBuilder()">Tilføj</button>
        </span>
    </div>

    <ul class="item-list builder-list">
        <li *ngFor="let b of case.builders" class="item">
            {{b.name}}, tlf. {{b.phoneNumber}}
            <div class="actions">
                <button (click)="removeBuilder($event, b)" title="Fjern håndværker"><i class="fa fa-trash" aria-hidden="true"></i><span class="visuallyhidden">Fjern</span></button>
            </div>
        </li>
    </ul>
</div>