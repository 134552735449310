import { ApplicationConfig, LOCALE_ID, PLATFORM_ID, importProvidersFrom, isDevMode } from '@angular/core';
import { provideRouter, withComponentInputBinding, withRouterConfig } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { tokenGetter } from './helpers/token-helper';
import { environment } from '../environments/environment';
import { HubConfig } from './models/hub-config';
import { SignalrWindow } from './services/signalr-window';
import { SignalRService } from './services/signal-r.service';
import { JwtInterceptor } from './interceptors/token.interceptor';
import { IsBrowserHelper } from './helpers/localstorage-helper';
import { CaseHasReportGuard } from './guards/case-has-report.guard';
import { provideServiceWorker } from '@angular/service-worker';
import { registerLocaleData } from '@angular/common';
import localeDa from '@angular/common/locales/da';

registerLocaleData(localeDa);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withRouterConfig({ onSameUrlNavigation: 'reload' }), withComponentInputBinding()),
    //provideClientHydration(), server rendering is not enabled in current setup
    provideAnimationsAsync(),
    importProvidersFrom(JwtModule.forRoot({
        config: {
            tokenGetter: tokenGetter,
            allowedDomains: [environment.config.basePath],
            // disallowedRoutes: ["http://example.com/examplebadroute/"],
        },
    })),
    provideHttpClient(withInterceptorsFromDi()),
    SignalRService,
    //{ provide: SignalrWindow, useFactory: getWindow },
    { provide: 'hub.config', useFactory: GetHubConfig },
    { provide: LOCALE_ID, useValue: "da" },
    { provide: IsBrowserHelper, useClass: IsBrowserHelper, deps: [PLATFORM_ID] },
    provideAnimationsAsync(),
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    CaseHasReportGuard,
    provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
    })
]
};

export function GetHubConfig(): HubConfig {
  let hubConfig = new HubConfig();
  hubConfig.url = "/signalr";
  hubConfig.hubName = "NotificationHub";

  return hubConfig;
}

export function getWindow() {
  return window;
}
