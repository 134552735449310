import { Injectable } from '@angular/core';
import { AlertMessage } from '../interfaces/alert-message';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private subject = new Subject<AlertMessage>();
  private keepAfterNavigationChange = false;

  constructor(private router: Router) {
      // clear alert message on route change
      router.events
        .subscribe(event => {
          if (event instanceof NavigationStart) {
              if (this.keepAfterNavigationChange) {
                  // only keep for a single location change
                  this.keepAfterNavigationChange = false;
              } else {
                  // clear alert
                  this.subject.next({} as AlertMessage);
              }
          }
        });
  }
  
  success(message: string | AlertMessage, keepAfterNavigationChange = false) {
      this.keepAfterNavigationChange = keepAfterNavigationChange;
      if (this.isAlertMessage(message))
      {
          this.subject.next(message);
      }
      else {
          this.subject.next({ type: 'success', text: message });
      }
  }
  
  error(message: string | AlertMessage, keepAfterNavigationChange = false) {
      this.keepAfterNavigationChange = keepAfterNavigationChange;
      if (this.isAlertMessage(message)) {
          this.subject.next(message);
      }
      else {
          this.subject.next({ type: 'error', text: message });
      }
  }

  getMessage(): Observable<AlertMessage> {
      return this.subject.asObservable();
  }

  protected isAlertMessage(value: AlertMessage | string): value is AlertMessage {
      return (<AlertMessage>value).type !== undefined;
  }
}
