import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { switchMap } from 'rxjs';
import { EmbedViewerComponent } from '../ui/embed-viewer/embed-viewer.component';
import { WelcomeData } from '../../interfaces/welcome-data';
import { WelcomeService } from '../../services/welcome.service';

@Component({
  selector: 'app-welcome',
  standalone: true,
  imports: [CommonModule, EmbedViewerComponent],
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.scss'
})
export class WelcomeComponent implements OnInit {
  public data!: WelcomeData;
  public logoUrl: string | null = null;

  constructor(
      private router: Router, 
      private route: ActivatedRoute, 
      private welcomeService: WelcomeService) 
    { }

  ngOnInit() {
    this.route.params.pipe(
      switchMap((params: Params) => this.welcomeService.get(params["companyid"]))
    ).subscribe(data => {
      this.data = data;
      if (data.logoUrl) {
        this.logoUrl = data.logoUrl + "?height=60";
      }
    });

    // this.route.params
    //     .switchMap((params: Params) => this.welcomeService.get(params["companyid"]))
    //     .subscribe(data => {
    //         this.data = data;
    //         if (data.logoUrl) {
    //             this.logoUrl = data.logoUrl + "?height=60";
    //         }
    //     });
  }
}
