import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ComponentBaseComponent } from '../../component-base/component-base.component';
import { DamageCase } from '../../../models/damage-case';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { DamageCaseService } from '../../../services/damage-case.service';
import { CustomerSatisfactionUpdate } from '../../../models/customer-satisfaction-update';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-customer-satisfaction',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './customer-satisfaction.component.html',
  styleUrl: './customer-satisfaction.component.scss'
})
export class CustomerSatisfactionComponent extends ComponentBaseComponent implements OnInit {
  @Input() case! : DamageCase;
  @Input() rating: number = 0; // Current rating
  @Input() maxStars: number = 5; // Maximum number of stars

  stars: boolean[] = [];

  constructor(
    auth: AuthenticationService,
    private damCaseService: DamageCaseService) {
      super(auth);
    }

  override ngOnInit(): void {
    this.stars = Array(this.maxStars).fill(false);
  }

  setRating(index: number): void {
    this.rating = index + 1;
  }

  highlightStars(index: number): void {
    this.stars = this.stars.map((_, i) => i <= index);
  }

  resetHighlight(): void {
    this.stars = this.stars.map((_, i) => i < this.rating);
  }

  saveRating() {
      let ratingModel = new CustomerSatisfactionUpdate();
      ratingModel.companyId = this.authService.companyId;
      ratingModel.customerId = this.authService.userId;
      ratingModel.id = this.case.id;
      ratingModel.customerSatisfactionRating = this.rating;
      console.log("ratingModel: ", ratingModel)
    //   this.damCaseService.updateRating(ratingModel)
    //     .subscribe(result => this.case.customerSatisfactionRating = this.rating);
  }
}
