
<div class="login resetpassword">
    <img class="logo" src="/assets/images/dr_logo_white.svg" />

    <div class="content">
        <ng-container *ngIf="!submitted">
            <h2>Glemt kodeord</h2>
            <p>Indtast din e-mail her, og så vil du modtage et link, som du kan bruge til at skifte dit kodeord.</p>
            <form name="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate class="loginform">
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !email }">
                    <label for="email">E-mail</label>
                    <input type="text" class="form-control" name="email" [(ngModel)]="email" #f_email="ngModel" placeholder="navn@example.com" required />
                    <div *ngIf="f.submitted && !f_email.valid" class="help-block">Du skal angive en e-mail</div>
                </div>
                <div class="form-group">
                    <button [disabled]="loading || !f.valid" type="submit" class="btn btn-primary">Send mail</button>
                    <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </div>
                <div *ngIf="error" class="alert alert-danger">{{error}}</div>
            </form>
        </ng-container>
        <ng-container *ngIf="submitted">
            <p>Hvis din e-mail passer til en konto, vil du modtage en e-mail, hvor du kan skifte dit kodeord.</p>
        </ng-container>
    </div>
</div>
