import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DamageCase } from '../../../models/damage-case';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { UploadComponent } from '../../upload/upload.component';
import { FileUploadedArgsComponent } from '../../file-uploaded-args/file-uploaded-args.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SpinnerComponent } from '../../ui/spinner/spinner.component';
import { Attachment } from '../../../models/attachment';
import { AttachmentService } from '../../../services/attachment.service';

@Component({
  selector: 'case-details-attachments',
  standalone: true,
  imports: [CommonModule, FormsModule, SpinnerComponent, UploadComponent],
  templateUrl: './case-details-attachments.component.html',
  styleUrl: './case-details-attachments.component.scss'
})
export class CaseDetailsAttachmentsComponent implements OnInit, OnChanges {
  @Input() case!: DamageCase;

  @ViewChild(UploadComponent) public upload!: UploadComponent;

  itemAlias: string = "file";
  uploadUrl!: string;
  allAttachments: Attachment[] = [];
  public hasAttachments: boolean = false;

  constructor(
    public authService: AuthenticationService,
    private attachmentService: AttachmentService,
    private cdRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (this.case.attachments) {
        this.allAttachments = this.case.attachments;
    }

    this.reset();
  }

  onUpload(event: MouseEvent): void {
      this.upload.upload();
  }

  onFileUploaded(event: FileUploadedArgsComponent) {
    const value = event.getValue<Attachment>();

    if (value) {
        this.allAttachments.push(value);
        this.cdRef.detectChanges();
    }

    this.reset();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let p in changes) {
        if (p === "case") {
            //If the case changes, we need to clear the upload widget
            this.reset();
        }
    }
  }

  downloadFile(fileName: string, openInNewWindow: boolean = true): void {
    this.attachmentService.downloadFile(fileName)
      .subscribe((blob) => {
     // Create a URL for the file
     const url = window.URL.createObjectURL(blob);

     if (openInNewWindow) {
       // Open file in a new browser window
       window.open(url);
     } else {
       // Download the file
       const a = document.createElement('a');
       a.href = url;
       a.download = fileName;
       document.body.appendChild(a);
       a.click();

       // Cleanup
       document.body.removeChild(a);
     }

     // Cleanup the object URL
     window.URL.revokeObjectURL(url);
      });
  }

  private reset() {
    if (this.case) {
        this.uploadUrl = "/api/damagecase/" + this.case.id + "/uploadattachment";
        this.hasAttachments = true;
    }

    if (this.upload) {
        this.upload.reset();
    }
  }
}
