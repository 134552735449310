import { Component, OnInit } from '@angular/core';
import { ReportFormBaseComponent } from '../report-form-base/report-form-base.component';
import { StormReport } from '../../../models/storm-report';
import { StormReportService } from '../../../services/storm-report.service';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CloudBurstReportFormComponent } from '../cloud-burst-report-form/cloud-burst-report-form.component';
import { AssistanceReportFormComponent } from '../assistance-report-form/assistance-report-form.component';
import { Employee } from '../../../models/employee';
import { EmployeesService } from '../../../services/employees/employees.service';

@Component({
  selector: 'report-storm',
  standalone: true,
  imports: [CommonModule, FormsModule, StormReportFormComponent, CloudBurstReportFormComponent, AssistanceReportFormComponent],
  templateUrl: './storm-report-form.component.html',
  styleUrl: './storm-report-form.component.scss'
})
export class StormReportFormComponent extends ReportFormBaseComponent<StormReport> implements OnInit {
  constructor(
    authService: AuthenticationService, 
    private employeesService: EmployeesService,    
    private stormReportService: StormReportService) {
      super(authService);
      this.formModel = new StormReport();
  }

  override ngOnInit() {
    if (this.mode == "create") {
        this.formModel.damageCaseId = this.case.id;
    }
    else if (this.mode == "edit" && this.reportId) {
        //We are in edit mode and have the data we need to go load the report from the server
        this.stormReportService.get(this.reportId)
        .subscribe((report: any) => this.formModel = report.result);
    }
  }

  override onSubmit() {
    this.employeesService.getEmployee(this.authService.userId)
      .subscribe((result: Employee) => {       
        if (this.mode == "create") {
          this.formModel.createdBy = result;
          this.formModel.updatedBy = result;
          this.formModel.created = new Date();
          this.formModel.updated = new Date();

          this.stormReportService.create(this.formModel)
            .subscribe((r: StormReport) => this.created.emit(r));
        }
        else {
          this.formModel.updatedBy = result;
          this.formModel.updated = new Date();

          this.stormReportService.update(this.formModel)
            .subscribe((r: StormReport) => this.saved.emit(r));
        }
      });
  }
}
