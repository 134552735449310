import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map, take } from 'rxjs';
import { EquipmentStatisticsComponent } from '../equipment/equipment-statistics/equipment-statistics.component';
import { CustomerSatisfactionGraphComponent } from "../customers/customer-satisfaction-graph/customer-satisfaction-graph.component";
import { DamageCaseCounts } from '../../interfaces/damage-case-counts';
import { EventNotification } from '../../interfaces/event-notification';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { DamageCaseService } from '../../services/damage-case.service';
import { NotificationService } from '../../services/notification.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-home-statistics',
    standalone: true,
    templateUrl: './home-statistics.component.html',
    styleUrl: './home-statistics.component.scss',
    imports: [CommonModule, EquipmentStatisticsComponent, CustomerSatisfactionGraphComponent]
})
export class HomeStatisticsComponent implements OnInit {
  caseCountsUpdated: any;
  countNew!: number;
  countOpen!: number;
  loaded: boolean = false;

  constructor(
    private damagecaseService: DamageCaseService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    public authService: AuthenticationService,
    private userService: UserService) {
      this.notificationService.notifications
        .pipe(
            map(n => <EventNotification<DamageCaseCounts, any>>n),
            filter(n => n.Type === "caseCountsUpdated")
        )
        .subscribe(n => {
            this.countNew = n.Data.new;
            this.countOpen = n.Data.open;
        });

      this.damagecaseService.counts()
        .pipe(filter((r: DamageCaseCounts) => r != null), take(1))
        .subscribe(x => {
          this.countNew = x.new;
          this.countOpen = x.open;
          this.loaded = true;
        });
    // this.notificationService.notifications
    //     .map(n => <EventNotification<DamageCaseCounts, any>>n)
    //     .filter(n => n.type == "caseCountsUpdated")
    //     .subscribe(n => {
    //         this.countNew = n.data.new;
    //         this.countOpen = n.data.open
    //     });
  }


  ngOnInit(): void {
    this.damagecaseService.counts()
      .pipe(filter((r: DamageCaseCounts) => r != null))
      .subscribe(x => {
        this.countNew = x.new;
        this.countOpen = x.open;
        this.loaded = true;
      });
  }
}
