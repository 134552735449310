import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from '../../../models/customer';
import { DamageCase } from '../../../models/damage-case';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { DamageCaseService } from '../../../services/damage-case.service';
import { UserService } from '../../../services/user.service';
import { damageTypes } from '../../../types/mode.type';

@Component({
  selector: 'app-edit-case',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './edit-case.component.html',
  styleUrl: './edit-case.component.scss'
})
export class EditCaseComponent implements OnInit {
  @Input() model!: DamageCase;
  customers!: Customer[];
  currentCustomer!: Customer;
  damageTypes = damageTypes;

  damageDate: string = "";

  formtitle: string;
  submitted = false;

  onSubmit() {
      this.submitted = true;
      this.saveCase();
  }

  constructor(
    private damagecaseService: DamageCaseService,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    public authService: AuthenticationService,
    private userService: UserService) {

    this.formtitle = "REDIGÉR";
  }

  getUsers(): void {
    this.userService.getCustomers()
      .subscribe((dcresult: Customer[]) => this.customers = dcresult);
  }

  ngOnInit(): void {
    const date = new Date(this.model.damageDate);

    // Use Intl.DateTimeFormat to format the date as 'yyyy-MM-dd' in CET/CEST
    const formattedDate = new Intl.DateTimeFormat('en-GB', { 
      year: 'numeric', 
      month: '2-digit', 
      day: '2-digit',
      timeZone: 'Europe/Copenhagen' // Central European Time Zone
    }).format(date);

    // Convert the format to 'yyyy-MM-dd'
    const [day, month, year] = formattedDate.split('/');
    this.damageDate = `${year}-${month}-${day}`;

    this.getUsers();
  }

  disableTyping(event: KeyboardEvent): void {
    event.preventDefault();  // Prevents keyboard input
  }

  saveCase() {
    //this.model.damageDate = new Date(this.damageDate);

    this.damagecaseService.update(this.model)
      .subscribe((dc: DamageCase) => this.router.navigate(["case", this.model.id]));
  }

  customerChange(newValue: any) {
      const currentCustomer: Customer | undefined = this.customers.find(customer => customer.id == newValue.value);

      if (currentCustomer) {
          this.model.damageAddress.street = currentCustomer.address.street;
          this.model.damageAddress.city = currentCustomer.address.city;
          this.model.damageAddress.zip = currentCustomer.address.zip;
          this.model.damageAddress.country = currentCustomer.address.country;
      }
  }

  onCancel(event: MouseEvent): void {
      event.preventDefault();
      this.router.navigate(["case", this.model.id]);
  }
}
