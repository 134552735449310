import { Component, OnInit } from '@angular/core';
import { Customer } from '../../../models/customer';
import { UserService } from '../../../services/user.service';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { map, switchMap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-customer-details',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './customer-details.component.html',
  styleUrl: './customer-details.component.scss'
})
export class CustomerDetailsComponent implements OnInit {
  public customer!: Customer;

  constructor(private userService: UserService,
      public authService: AuthenticationService,
      private router: Router,
      private route: ActivatedRoute) {
  }

  
  ngOnInit(): void {
    this.route.params.pipe(
      switchMap((params: Params) => {
        // Assuming this.userService.getCustomer(params["customerid"]) returns an Observable<Customer>
        return this.userService.getCustomer(params["id"]);
      }),
      map((c: Customer) => {
        this.customer = c;
        return c; // Optionally return the customer
      })
    ).subscribe((c: Customer) => {
      // You can handle the customer here if needed
    });

  //     this.route.params
  //         .switchMap((params: Params) => this.userService.getCustomer(params["customerid"]))
  //         .subscribe((c: Customer) => this.customer = c);
  }

  onSubmitUpdateCustomer() {
    this.customer.password = null;
    console.log("Customer: ", this.customer);
      this.userService.update(this.customer)
          .subscribe(dc => {
              this.router.navigate(["/customers"]);
          });
  }

  goToList() {
      this.router.navigate(["/customers"]);
  }
}
