import { Component, Inject } from '@angular/core';
import { ConfirmDialogData } from '../../../interfaces/ui/confirm-dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-confirm-dialog',
  standalone: true,
  imports: [CommonModule, MatCardModule],
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss'
})
export class ConfirmDialogComponent {
  dialogData!: ConfirmDialogData;
  title!:string;
  message!:string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {}

    onConfirm(): void {
      this.dialogRef.close(true);
    }
  
    onDismiss(): void {
      this.dialogRef.close(false);  
    }      
}
