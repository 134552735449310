import { Injectable } from '@angular/core';
import { ServiceBase } from '../base/service-base.service';
import { AlertService } from '../alert.service';
import { MeasuringPoint } from '../../models/measurement/measuring-point';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MeasuringPointService extends ServiceBase {
  private baseUrl = '/api/measurement/point/';

  constructor(authhttp: HttpClient, alert: AlertService) {
      super(authhttp);
  }

  create(point: MeasuringPoint): Observable<MeasuringPoint> {
      return this.postJson<MeasuringPoint>(this.baseUrl + "create", point);
  }

  get(pointId: number): Observable<MeasuringPoint> {
      return this.getJson<MeasuringPoint>(this.baseUrl + "get/" + pointId);
  }

  list(logId: number): Observable<MeasuringPoint[]> {
      return this.getJson<MeasuringPoint[]>(this.baseUrl + "list/" + logId);
  }
  
  update(point: MeasuringPoint): Observable<MeasuringPoint> {
      return this.postJson<MeasuringPoint>(this.baseUrl + "update", point);
  }

  delete(point: MeasuringPoint): Observable<MeasuringPoint> {
      return this.postJson<MeasuringPoint>(this.baseUrl + "delete", point);
  }
}
