import { ReportType } from '../interfaces/report-type';

export const reportTypes: ReportType[] = [
    {
        alias: "assistance",
        name: "Assistancerapport"
    },
    {
        alias: "storm",
        name: "Storm"
    },
    {
        alias: "cloudburst",
        name: "Skybrud"
    }
]