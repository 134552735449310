<div class="casedetails customeradd">
    <button class="back-to-list" (click)="goToList()"><i class="fa fa-angle-left"></i> Tilbage til liste</button>
    <div>
        <div class="casedetailpanel">
            <form (ngSubmit)="onSubmitUpdateCustomer()" #f="ngForm" autocomplete="off">

                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                    <label for="firstName">Navn og fornavn</label>
                    <input type="text" class="form-control" name="fullName" [(ngModel)]="customer.fullName" #fullName="ngModel" required />
                    <div *ngIf="f.submitted && !fullName.valid" class="help-block">Navn er obligatorisk</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                    <label for="companyIsIndustry">Registrér som erhvervkunde</label>
                    <input type="checkbox" class="form-control" name="companyIsIndustry" [(ngModel)]="customer.customerIsIndustry" #companyName="ngModel" />
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                    <label for="companyName">Firmanavn (kun erhverv)</label>
                    <input type="text" class="form-control" name="companyName" [(ngModel)]="customer.customerCompanyName" #companyName="ngModel" />
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                    <label for="emailAddress">Email</label>
                    <input type="email" class="form-control" name="emailAddress" [(ngModel)]="customer.email" #emailAddress="ngModel" />
                    <!--<div *ngIf="f.submitted && !emailAddress.valid" class="help-block">Emailadresse er obligatorisk</div>-->
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                    <label for="secondEmailAddress">Ekstra Email</label>
                    <input type="email" class="form-control" name="secondEmailAddress" [(ngModel)]="customer.secondEmail" #secondEmailAddress="ngModel" />
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                    <label for="username">Brugernavn</label>
                    <input type="text" class="form-control" name="username" id="username" [(ngModel)]="customer.userName" #username="ngModel" validateUsername required />
                    <div class="validation-message" *ngIf="username.errors && (username.dirty || username.touched)">
                        <div *ngIf="username.errors['required']">
                            Du skal angive brugernavn
                        </div>
                        <div *ngIf="username.errors['validateUsername']">
                            Brugernavnet er optaget
                        </div>
                    </div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                    <label for="password">Password</label>
                    <input type="password" class="form-control" name="custpassword" [(ngModel)]="customer.password" #password="ngModel" required />
                    <div *ngIf="f.submitted && !password.valid" class="help-block">Password er obligatorisk</div>
                </div>

                <!-- adresse -->
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                    <label for="addressStreet">Adresse</label>
                    <input type="text" class="form-control" name="addressStreet" [(ngModel)]="customer.address.street" #addressStreet="ngModel" />

                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                    <label for="addressCity">By</label>
                    <input type="text" class="form-control" name="addressCity" [(ngModel)]="customer.address.city" #addressCity="ngModel" />

                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                    <label for="addressZip">Postnummer</label>
                    <input type="text" class="form-control" name="addressZip" [(ngModel)]="customer.address.zip" #addressZip="ngModel" />

                </div>

                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                    <label for="addressPhone">Telefon</label>
                    <input type="text" class="form-control" name="addressPhone" [(ngModel)]="customer.phoneNumber" #addressPhone="ngModel" />

                </div>

                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                    <label for="addressCellphone">Mobil</label>
                    <input type="text" class="form-control" name="addressCellphone" [(ngModel)]="customer.cellphoneNumber" #addressCellphone="ngModel" />

                </div>

                <div class="buttons">
                    <button type="submit" class="btn btn-default">Opret</button>
                    <button type="button" (click)="onCancel()">Annuller</button>
                </div>
            </form>
        </div>
    </div>


</div>
