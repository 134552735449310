import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EquipmentLog } from '../../../interfaces/equipment/equipment-log';
import { DamageCase } from '../../../models/damage-case';
import { EquipmentService } from '../../../services/equipment/equipment.service';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { AlertService } from '../../../services/alert.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UserAvatarComponent } from "../../user-avatar/user-avatar.component";

import moment from 'moment';
import { MomentDiffPipe } from "../../../pipes/moment-diff.pipe";


@Component({
    selector: 'equipment-log-item',
    standalone: true,
    templateUrl: './equipment-log-row.component.html',
    styleUrl: './equipment-log-row.component.scss',
    imports: [CommonModule, FormsModule, UserAvatarComponent, MomentDiffPipe]
})
export class EquipmentLogRowComponent implements OnInit {
  @Input() row!: EquipmentLog;
  @Input() case!: DamageCase;
  @Output() cancelAdd: EventEmitter<EquipmentLog>;

  oldvalues!: EquipmentLog;

  constructor(
    private equipmentService: EquipmentService,
    public authService: AuthenticationService,
    private alertService: AlertService) {
      this.cancelAdd = new EventEmitter<EquipmentLog>();
  }

  ngOnInit(): void {
  }

  editItem(event: MouseEvent, item: EquipmentLog) {
      event.preventDefault();
      this.oldvalues = item;
      //item.copyTothis.copyItem(item, this.oldvalues);
      item.mode = "edit";
      item.settingup = item.isActive;
      item.takingdown = !item.settingup;
  }

  takeDown(event: MouseEvent, item: EquipmentLog) {
      event.preventDefault();
      this.oldvalues = item;
      item.removedDate = new Date();
      //this.copyItem(item, this.oldvalues);
      item.mode = "edit";
      item.takingdown = true;
  }

  saveItem(item: EquipmentLog): boolean {
    if (item.takingdown) {
        item.isActive = false;
    }

    const handleError = (err: any): void => {
        const errorMsg = Array.isArray(err) && err.length > 0
            ? err.map((e: any) => e.message).join('. ')
            : 'Ukendt fejl';
        this.alertService.error(errorMsg, false);
    };

    if (item.mode === "add") {
        item.isActive = true;
        this.equipmentService.createLogForCase(this.case.id, item)
            .subscribe({
                next: (data) => {
                    Object.assign(item, {
                        id: data.id,
                        placedEmployeeId: data.placedEmployeeId,
                        placedEmployee: data.placedEmployee,
                        mode: "view",
                        takingdown: false,
                        settingup: false,
                    });
                    this.alertService.success('Gemt!', false);
                },
                error: handleError,
            });
    } else if (item.mode === "edit") {
        this.equipmentService.updateLogForCase(this.case.id, item)
            .subscribe({
                next: (data) => {
                    Object.assign(item, {
                        mode: "view",
                        takingdown: false,
                        settingup: false,
                        placedEmployeeId: data.placedEmployeeId,
                        placedEmployee: data.placedEmployee,
                        removedEmployeeId: data.removedEmployeeId,
                        removedEmployee: data.removedEmployee,
                    });
                    this.alertService.success('Gemt!', false);
                },
                error: handleError,
            });
    } else {
        return true;
    }

    return false;
  }

  cancelEditing(event: MouseEvent, item: EquipmentLog) {
      event.preventDefault();
      if (item.mode == "add") {
          this.cancelAdd.emit(item);
      }
      else
      if (item.mode == "edit")
      {
          this.oldvalues, item;
          //this.copyItem(this.oldvalues, item);
          item.mode = "view";
      }
  }

  calcCounter(r: EquipmentLog): number {
      if (r.removedCounter) {
          let counterVal = r.removedCounter - r.placedCounter!;
          if (counterVal >= 0) {
              return counterVal;
          }
          else {
              return 0;
          }
      }
      else {
          return 0;
      }
  }

  calcDays(r: EquipmentLog) {
      if (r.removedDate) {
          const removeDate = moment(r.removedDate);
          const placeDate = moment(r.placedDate);
          const diff = removeDate.diff(placeDate, 'days') + 1; //Needs to include placeDate
          return diff;
      }
      else {
          return "";
      }
  }
}
