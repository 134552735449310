<ng-container *ngIf="transaction">
    <form class="createrow" *ngIf="mode == 'create'" (ngSubmit)="f.form.valid && onSubmitCreate()" #f="ngForm">
        <div class="value date">
            {{transaction.date.toString() | date:'DD-MM'}} 
            <!-- <avatar [user]="transaction.employee" size="small"></avatar> -->
        </div>
        
        <div class="value instrument">
            <input type="text" name="instrument" id="instrument" [(ngModel)]="transaction.instrument" required />
        </div>

        <div class="value" *ngFor="let v of transaction.values" [@slideLeftRightList]="v.state">
            <input type="number" name="value_{{v.pointId}}" id="value_{{v.pointId}}" min="0" [(ngModel)]="v.value" required />
        </div>

        <div class="value reference">
            <input type="number" name="reference" id="reference" [(ngModel)]="transaction.referenceMeasurement" required />
        </div>

        <div class="btns">
            <button type="submit" [disabled]="!f.form.valid">Angiv</button>
        </div>
    </form>

    <ng-container *ngIf="mode == 'view'">
        <form #f="ngForm">
            <div class="value date" title="Indtasted af: {{transaction.employee.displayName}}">
                {{transaction.date.toString() | date:'DD-MM'}}
                <!-- <avatar [user]="transaction.employee" size="small"></avatar> -->
            </div>
            <div class="value instrument">
                <input type="text" name="instrument" id="instrument" [(ngModel)]="transaction.instrument" readonly />
            </div>
            <div class="value" *ngFor="let v of transaction.values" [@slideLeftRightList]="v.state">
                <input type="number" name="value_{{v.pointId}}" id="value_{{v.pointId}}" min="0" [(ngModel)]="v.value" readonly />
            </div>
            <div class="value reference">
                <input type="number" name="reference" id="reference" [(ngModel)]="transaction.referenceMeasurement" readonly />
            </div>
            <div class="btns">
                <button (click)="delete()" title="Slet">
                    <i class="fa fa-trash fa-lg"></i>
                    <span class="visuallyhidden"></span>
                </button>
            </div>
        </form>
    </ng-container>
</ng-container>