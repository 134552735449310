<div *ngIf="case" class="case-appointment-list">
    <div *ngIf="case.damageStatusId != 3 && authService.userRole != '3'">
        <form (ngSubmit)="createAppointmentForm.form.valid && saveAppointment()" #createAppointmentForm="ngForm">

            Besøg/Møde:
            <div class="date-time">
                <input type="text" class="styled-input-date" id="date" name="date" placeholder="Vælg dato" required
                    onfocus="this.type='date'" 
                    onblur="if(!this.value) { this.type='text'; }"
                    [(ngModel)]="newAppointmentDate">         

                <input type="text" class="styled-input-time" id="time" name="time" placeholder="Vælg tidspunkt" required
                    onfocus="this.type='time'" 
                    onblur="if(!this.value) { this.type='text'; }"
                    [(ngModel)]="newAppointmentTime">
                <!-- <dr-datepicker name="appointmentdate" options="dateOptions" [(ngModel)]="newAppointmentDate" required></dr-datepicker> -->
                <!-- <dr-timepicker name="appointmenttime" [(ngModel)]="newAppointmentTime" required></dr-timepicker> -->
            </div>
            <label>
                <input type="radio" name="appointmenttype" value="0" [(ngModel)]="newAppointmentType" required />
                Møde
            </label>
            <label>
                <input type="radio" name="appointmenttype" value="1" [(ngModel)]="newAppointmentType" required />
                Fugtbesøg
            </label>
            Mødebeskrivelse:<br>
            <input type="text" name="description" [(ngModel)]="newAppointmentDescription" required />
            <button typeof="submit" [disabled]="!createAppointmentForm.form.valid">Gem møde</button>
        </form>
    </div>
    <ul class="item-list">
        <li *ngFor="let n of case.appointments;" (click)="appointmentSelected(n)" [@slideUpDownList]="n.state" class="item">
            <ng-container *ngIf="!n.editing; else edit">
                <avatar [user]="n.createdBy!" size="small"></avatar>
                <div class="content">
                    <div *ngIf="n.type == 0">
                        <strong>Møde:</strong> {{n.description}}
                    </div>
                    <div *ngIf="n.type == 1">
                        <strong>Fugtbesøg:</strong> {{n.description}}
                    </div>
                </div>
                <time [attr.datetime]="n.appointmentDate">{{n.appointmentDate.toString()| date: 'dd-MM-yyyy HH:mm'}}</time>
                <div class="heldby" *ngIf="!authService.isCustomer && n.isHeld && n.heldBy">
                    Afholdt af <avatar [user]="n.heldBy" size="mini"></avatar>
                </div>
                <div *ngIf="authService.userRole != '3'" class="actions">
                    <div *ngIf="!n.isHeld">
                        <button (click)="appointmentHeld($event, n)" title="Marker som afholdt"><i class="fa fa-bell-o"></i></button>
                    </div>
                    <div *ngIf="n.isHeld">
                        <button class="cursor-not-allowed" title="Afholdt"><i class="fa fa-bell-slash-o"></i></button>
                    </div>
                </div>
            </ng-container>

            <ng-template #edit>
                <form #appointmentForm="ngForm">
                    <div class="content">
                        <input type="text" name="date" [(ngModel)]="n.description" required />
                        <div class="date-time">
                            <input type="date" class="styled-input-date" id="appointmentdate" name="appointmentdate" placeholder="Vælg dato" required
                            [ngModel]="n.appointmentDate | date:'yyyy-MM-dd'"
                            (ngModelChange)="updateAppointmentDateTime($event, null, n)">         
        
                            <input type="time" class="styled-input-time" id="appointmenttime" name="appointmenttime" placeholder="Vælg tidspunkt" required
                            [ngModel]="n.appointmentDate | date: 'HH:mm'"
                            (ngModelChange)="updateAppointmentDateTime(null, $event, n)">

                            <!-- <dr-datepicker name="appointmentdate" options="dateOptions" [(ngModel)]="n.appointmentDate" required></dr-datepicker> -->
                            <!-- <dr-timepicker name="appointmenttime" [(ngModel)]="n.appointmentDate" required></dr-timepicker> -->
                        </div>
                    </div>
                    <div class="actions">
                        <button [disabled]="!appointmentForm.form.valid" (click)="updateAppointment($event, n)" title="Gem">
                            <i class="fa fa-check" aria-hidden="true"></i><span class="visuallyhidden"></span>
                        </button>
                        <button [disabled]="!appointmentForm.form.valid" (click)="deleteAppointment($event, n)" title="Slet">
                            <i class="fa fa-times" aria-hidden="true"></i><span class="visuallyhidden"></span>
                        </button>
                    </div>
                </form>
            </ng-template>
        </li>
    </ul>
</div>
