export function convertToDate(inputDate: string, inputTime: string): Date | null {
    if (!inputDate || !inputTime) {
      return null; // Handle invalid inputs
    }
  
    try {
      // Combine the date and time strings in ISO 8601 format
      const combined = `${inputDate}T${inputTime}`;
      const date = new Date(combined);
  
      // Validate the date
      if (isNaN(date.getTime())) {
        throw new Error('Invalid date');
      }
  
      return date;
    } catch (error) {
      console.error('Error converting input to date:', error);
      return null;
    }
  }
  