<div>
    <mat-dialog-content class="safty-container">
            <div class="clearfix">
                <h1 class="inline-block">Sikkerhedsblade og dokumenter</h1>
                <button class="primary push" (click)="close()">Luk</button>
            </div>
            
            <div *ngIf="authService.userRole == '2'">
                <file-upload [uploadUrl]="uploadUrl" (fileUploaded)="onFileUploaded($event)"></file-upload>
                <button (click)="onUpload($event)">Tilføj filer</button>
            </div>

            <div class="spinner-container">
                <div class="safetydoc-search">
                    <input [(ngModel)]="searchterm" (ngModelChange)="filterDocs()" placeholder="søg"/>            
                </div>
                <ul class="item-list">
                    <li *ngFor="let f of allSafetyDocsView">
                        <a (click)="downloadFile(f.fileName)" target="_blank">{{f.fileName}}</a>
                    </li>
                </ul>
                <dr-spinner [isRunning]="loading"></dr-spinner>
            </div>
    </mat-dialog-content>
</div>