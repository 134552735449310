import { Vehicle } from "../interfaces/vehicles/vehicle";

export class Employee {
    userName!: string;
    password!: string | null;
    fullName!: string;
    id!: string;
    occupation!: string;
    phoneNumber!: string;
    cellphoneNumber!: string;
    displayName!: string;
    email!: string;

    caseCount!: number;
    vehicleId!: number | null;
    vehicle!: Vehicle | null;
    disabled!: boolean;

    // Missing?!?
    vehicleName!: string;
    vehicleRegistration!: string;
}
