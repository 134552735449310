import { Component, Input, OnInit } from '@angular/core';
import { DamageCase } from '../../../models/damage-case';
import { MeasurementLog } from '../../../models/measurement/measurement-log';
import { ActivatedRoute, Params, Router, RouterModule } from '@angular/router';
import { DamageCaseService } from '../../../services/damage-case.service';
import { MeasurementLogService } from '../../../services/measurement/measurement-log.service';
import { switchMap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MeasuringPointComponent } from "../measuring-point/measuring-point.component";

@Component({
  selector: 'measurement-log-list',
  standalone: true,
  imports: [
    CommonModule, 
    FormsModule, 
    RouterModule, 
    MeasuringPointComponent
  ],
  templateUrl: './measurement-log-list.component.html',
  styleUrl: './measurement-log-list.component.scss'
})
export class MeasurementLogListComponent implements OnInit {
  @Input() case!: DamageCase

  create!: boolean;
  _router!: Router;
  caseid: number | null = null;

  newLog!: MeasurementLog;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private dcService: DamageCaseService,
      private mlService: MeasurementLogService
  )
  {
      this.newLog = new MeasurementLog();
      this._router = router;
  }

  ngOnInit(): void {
    if (!this.case) {
      this.route.params.pipe(
        switchMap((params: Params) => {
          this.caseid = params['caseid'];
          return this.dcService.details(this.caseid as number);
        })
      )
      .subscribe((c: DamageCase) => {       
        this.case = c;
      });      
    } else {
      this.route.params.subscribe(params => {
          this.caseid = params['caseid'];
        });
    }
  }

  onSubmitCreate(): void {
      this.newLog.damageCaseId = this.case.id;
      this.mlService.create(this.newLog)
        .subscribe(l => {
          this.case.measurementLogs.push(l);
          this.router.navigate([l.id], { relativeTo: this.route });
        });

      this.newLog = new MeasurementLog();
  }
}
