<div class="container">
    <h1 mat-dialog-title class="title">Opsæt udstyr</h1>
    <mat-dialog-content style="overflow:hidden">
        <form (ngSubmit)="f.form.valid && submit()" #f="ngForm">
            <div class="search">
                <!-- <label class="visuallyhidden" for="search">Søg efter udstyr</label> -->
                <input dr-autofocus type="search" id="search" placeholder="Søg efter udstyr" [formControl]="search" />
                <label class="visuallyhidden" for="type">Vælg type:</label>
                <select [(ngModel)]="selectedType" name="type" id="type" (ngModelChange)="loadEquipment()">
                    <option selected [ngValue]="null">Alle typer</option>
                    <option *ngFor="let t of types" [ngValue]="t">{{t.name}}</option>
                </select>
            </div>
            <ul *ngIf="equipment && equipment.length > 0; else empty">
                <li *ngFor="let e of equipment" (click)="select(e)" [class.selected]="selectedEquipment && e.id === selectedEquipment.id" [class.failed]="e.failed" [class.active]="e.isActive">
                    <span class="id">{{e.number}}</span> {{e.name}} <span class="type" *ngIf="!selectedType">({{e.type?.name}})</span>
                    <span class="failed-msg" *ngIf="e.failed">Fejlmeldt</span>
                    <span class="active-msg" *ngIf="e.isActive">I brug</span>
                </li>
            </ul>
            <ng-template #empty>
                <div class="empty-placeholder">
                    Intet udstyr af denne type fundet
                </div>
            </ng-template>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="dialog-buttons">
            <button (click)="submit()" class="primary" [disabled]="!selectedEquipment">Opsæt udstyr</button>
            <button class="primary" mat-dialog-close=>Annuller</button>
        </div>
    </mat-dialog-actions>
</div>
