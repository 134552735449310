<h2>Detaljer</h2>
<div class="details">
    <div class="info" *ngIf="vehicle">
        <div>Registreringsnummer: {{vehicle.registrationNumber!}}</div>
        <div>Kort navn: {{vehicle.shortName}}</div>
        <div>Langt navn: {{vehicle.longName}}</div>
    </div>
    <div class="employees">
        <div class="add input-group">
            <select class="form-control" id="employeeId" name="employeeId" [(ngModel)]="selectedEmployee">
                <option [ngValue]="null">Vælg medarbejder</option>
                <option [ngValue]="emp" *ngFor="let emp of employees">
                    <div *ngIf="emp.vehicleName" class="emp-list">
                        {{emp.fullName}} <i>(Tilknyttet: {{emp.vehicleName}} - {{emp.vehicleRegistration}})</i>
                    </div>
                    <div *ngIf="!emp.vehicleName" class="emp-list">
                        {{emp.fullName}}
                    </div>
                </option>
            </select>
            <span class="input-group-addon">
                <button [disabled]="!selectedEmployee" (click)="addEmployee()">Tildel</button>
            </span>
        </div>
        <ul *ngIf="vehicle">
            <li  *ngFor="let e of vehicle.employees">
                {{e.fullName}}
                <div class="actions">
                    <button (click)="removeEmployee(e)" title="Fjern"><i class="fa fa-trash"></i><span class="visuallyhidden"></span></button>
                </div>
            </li>
        </ul>
    </div>
</div>