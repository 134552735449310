import { Component, OnInit } from '@angular/core';
import { slideUpDownList } from '../../../animations/animations';
import { Vehicle } from '../../../interfaces/vehicles/vehicle';
import { FormControl, FormsModule } from '@angular/forms';
import { VehicleService } from '../../../services/vehicles/vehicle.service';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ConfirmDialogComponent } from '../../ui/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-vehicle-overview',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule],
  templateUrl: './vehicle-overview.component.html',
  styleUrl: './vehicle-overview.component.scss',
  animations: [slideUpDownList]
})
export class VehicleOverviewComponent implements OnInit {
  vehicles: Vehicle[] = [];
  searchText!: string;
  search = new FormControl();

  private originalItem!: Vehicle;

  constructor(
      private vehicleService: VehicleService,
      public dialog: MatDialog,
      private authService: AuthenticationService
  ) {

    //******************* JONAS - HARDCODET DATA 1 ********************************

    //this.vehicles = this.getHardcodedData();
    //******************* JONAS - HARDCODET DATA 1-slut ********************************

  }


  //******************* JONAS - HARDCODET DATA 2 ********************************
  private getHardcodedData(): any[] {
    return [
      {
        "registrationNumber": "AB12456",
        "shortName": "Varebil",
        "longName": "Varebil med stillads og pumpe",
        "employees": [],
        "id": 1,
        "companyId": 6
      },
    ]
  }
  //******************* JONAS - HARDCODET DATA 2-slut ********************************



  ngOnInit(): void {
      this.vehicleService.getVehicles()
        .subscribe(r => this.vehicles = r);
  }

  save(item: Vehicle) {
      if (item.creating) {
          this.vehicleService.create(item).subscribe(v => {
              item.creating = false;
              item.editing = false;
              Object.assign(item, v);
          },
              err => {
                // TODO: Dialog 
                  // this.modal.alert().message("Dette nummer er allerede i brug").open();
              });
      }
      else
      {
          this.vehicleService.update(item).subscribe(v => {
              item.creating = false;
              item.editing = false;
              Object.assign(item, v);
          },
              err => {
                // TODO: Dialog
                // this.modal.alert().message("Dette nummer er allerede i brug").open();
              });
      }
  }

  create() {
      if (this.vehicles.findIndex(x => x.editing) > -1) {
          return;
      }

      this.vehicles.push({
        registrationNumber: "",
        shortName: "",
        longName: "",
        editing: true,
        creating: true,
        state: "add",
        employees: []
      });
  }

  editItem(item: Vehicle) {
      item.editing = true;
    /*this.originalItem = clone(item);*/
      this.originalItem = item;
  }

  cancelEdit(item: Vehicle) {
      item.editing = false;
      if (this.originalItem) {
          Object.assign(item, this.originalItem);
      }
      if (item.creating) {
          var index = this.vehicles.findIndex(x => x.id === item.id);
          if (index >= 0) {
              this.vehicles.splice(index, 1);
          }
      }
  }

  delete(item: Vehicle) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "80%",
      maxWidth: "650px",
      data: {
        title: "Bil",
        yesButton: "Slet bil",
        noButton: "Annuller",
        message: "Vil du slette denne bil?"
      }
    })

    dialogRef.afterClosed()
      .subscribe((dialogResult: boolean) => {
          if (dialogResult == true) {
            item.state = "remove";
            this.vehicleService.delete(item).subscribe(i => {
                const index: number = this.vehicles.findIndex(x => x.id == item.id);
                if (index !== -1) {
                  this.vehicles.splice(index, 1);
                }
            });
          }
        });
  }
}
