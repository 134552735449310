import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleGeocodingService {
  private backendUrl: string = "/api/geocode";

    // Radius of the Earth in kilometers
    private readonly earthRadiusKm: number = 6371;

  constructor(
    private http: HttpClient) {
    }

  getCoordinates(address: string): Observable<any> {
    return this.http.get(`${this.backendUrl}?address=${encodeURIComponent(address)}`);
  }

    /**
   * Calculate the distance between two coordinates in kilometers using Haversine formula
   * @param lat1 - Latitude of the first location
   * @param lon1 - Longitude of the first location
   * @param lat2 - Latitude of the second location
   * @param lon2 - Longitude of the second location
   * @returns Distance in kilometers
   */
    // calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    //   try {
    //     // Coordinates: [latitude, longitude]
    //     const coord1 = new google.maps.LatLng(lat1, lon1); 
    //     const coord2 = new google.maps.LatLng(lat2, lon1); 
        
    //     // Calculate distance
    //     const distance: number = google.maps.geometry.spherical.computeDistanceBetween(coord1, coord2);

    //     if (distance > 0) {
    //       return distance / 1000;  // kilometers
    //     }

    //     return distance;
    //   } catch {
    //       // Backup function, if googlemap fails.
    //       const lat1Rad = this.degreesToRadians(lat1);
    //       const lon1Rad = this.degreesToRadians(lon1);
    //       const lat2Rad = this.degreesToRadians(lat2);
    //       const lon2Rad = this.degreesToRadians(lon2);
  
    //       const dLat = lat2Rad - lat1Rad;
    //       const dLon = lon2Rad - lon1Rad;
      
    //       const a = Math.pow(Math.sin(dLat / 2), 2) +
    //                 Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.pow(Math.sin(dLon / 2), 2);
      
    //       const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      
    //       return this.earthRadiusKm * c;
    //   }
    // }
  
    /**
     * Convert degrees to radians
     * @param degrees - Value in degrees
     * @returns Value in radians
     */
    // private degreesToRadians(degrees: number): number {
    //   return degrees * Math.PI / 180;
    // }
}
