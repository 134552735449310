
<div class="newcasedetails">
    <div class="panelyellow">
        <h1>Opret assistancerapport</h1>
    </div> 
    <div class="button-list" *ngIf="!currentReportType">
        <button *ngFor="let t of reportTypes" (click)="createReport($event, t)">{{t.name}}</button>
    </div>
    <report-assistance [case]="case" mode="create" *ngIf="currentReportType && currentReportType.alias == 'assistance'" (created)="onReportCreated($event)" (cancelled)="onReportCancelled($event)"></report-assistance>
    <report-storm [case]="case" mode="create" *ngIf="currentReportType && currentReportType.alias == 'storm'" (created)="onReportCreated($event)"  (cancelled)="onReportCancelled($event)"></report-storm>
    <report-cloudburst [case]="case" mode="create" *ngIf="currentReportType && currentReportType.alias == 'cloudburst'" (created)="onReportCreated($event)" (cancelled)="onReportCancelled($event)"></report-cloudburst>
</div>
