import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnInit, Renderer2 } from '@angular/core';
import { Employee } from '../../../models/employee';
import { Vehicle } from '../../../interfaces/vehicles/vehicle';
import { VehiclePreviewPopupService } from '../../../services/vehicles/vehicle-preview-popup.service';
import { VehicleService } from '../../../services/vehicles/vehicle.service';
import { getScrollParent } from '../../../utils/getScrollParent';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-vehicle-preview-popup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './vehicle-preview-popup.component.html',
  styleUrl: './vehicle-preview-popup.component.scss'
})
export class VehiclePreviewPopupComponent implements OnInit, AfterViewInit {
  employees!: Employee[];
  vehicle!: Vehicle;

  private scrollParent!: HTMLElement;

  @Input() target!: ElementRef;

  @HostBinding("@fadeInOut")
  opened: boolean;

  constructor(
      private popupService: VehiclePreviewPopupService,
      private vehicleService: VehicleService,
      public el: ElementRef,
      private renderer: Renderer2) {
      this.opened = false;
  }


  ngOnInit(): void {

      this.updatePosition();

      if (this.target) {
          //This is pretty nasty, referencing the DOM directly in Angular, but this appears to be the only way
          //TODO: See if a better way is possible
          this.scrollParent = getScrollParent(this.target.nativeElement) as HTMLElement;
          if (this.scrollParent) {
              this.scrollParent.addEventListener("scroll", this.onScroll);
          }

      }
  }

  ngAfterViewInit(): void {
      this.updatePosition();
  }

  private updatePosition() {
      if (this.target && this.target.nativeElement) {
          if (!this.target.nativeElement.offsetParent) {
              //If the offsetParent is null, the target element has scrolled offscreen
              this.popupService.hidePopup();
              return;
          }

          // Get reference element position.
          let popupheight = this.el.nativeElement.offsetHeight;
          let rect = this.target.nativeElement.getBoundingClientRect();
          let targetheight = this.target.nativeElement.offsetHeight;

          // Set new top/left values.
          let left = rect.left;
          let top = rect.top + targetheight;


          if (window) {
              top += window.scrollY;
          }

          if (top + popupheight > window.innerHeight) {
              top -= popupheight + targetheight;
          }
          // Position element.
          //this.renderer.setElementStyle(this.el.nativeElement, 'top', top + 'px');
          this.renderer.setStyle(this.el.nativeElement, 'top', top + 'px');
          // this.renderer.setElementStyle(this.el.nativeElement, 'left', left + 'px');
          this.renderer.setStyle(this.el.nativeElement, 'left', left + 'px');
      }
  }

  private onScroll = () => {
      this.updatePosition();
  }
}
