import { Component, OnInit } from '@angular/core';
import { MeasurementLogListComponent } from "../measurement-log-list/measurement-log-list.component";
import { DamageCase } from '../../../models/damage-case';

@Component({
  selector: 'app-measurement',
  standalone: true,
  imports: [MeasurementLogListComponent],
  templateUrl: './measurement.component.html',
  styleUrl: './measurement.component.scss'
})
export class MeasurementComponent implements OnInit {
  case!: DamageCase

  ngOnInit(): void {
    this.case = history.state.caseObject;
  }
}
