import { DamageCase } from "./damage-case";

export class HouseholdEffect {
    id!: number;
    itemText!: string;
    itemPrice!: number;
    itemAcquisitionDate!: Date;
    customerId!: string;
    companyId!: number;
    damageCaseId!: number;
    addedToCase!: boolean;
    attachedCases!: DamageCase[];    
}
