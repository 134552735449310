import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InfoDialogData } from '../../../interfaces/InfoDialogData';

@Component({
  selector: 'app-info-dialog',
  standalone: true,
  imports: [CommonModule, MatCardModule],
  templateUrl: './info-dialog.component.html',
  styleUrl: './info-dialog.component.scss'
})
export class InfoDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InfoDialogData) {}


  onDismiss(): void {
    this.dialogRef.close(false);  
  } 

}
