import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CaseDetailSectionComponent } from '../case-detail-section/case-detail-section.component';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { CompensationDialogComponent } from './compensation-dialog/compensation-dialog.component';

@Component({
  selector: 'case-details-compensation',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './case-details-compensation.component.html',
  styleUrl: './case-details-compensation.component.scss'
})
export class CaseDetailsCompensationComponent extends CaseDetailSectionComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    authService: AuthenticationService) {
    super(authService)
  }

  showDialog(event: MouseEvent) {
    event.preventDefault();
    const dialogRef = this.dialog.open(CompensationDialogComponent, {
      width: "95%",
      height: "95%",
      // maxWidth: "90%",
      panelClass: 'dialog-no-scroll',
      disableClose: true,
      data: {
        title: "Rum",
        noButton: "NEJ",
        yesButton: "JA",
        message: "Vil du slette dette rum? Det sletter også alle punkter i dette rum.",
        case: this.case
      }
    })

    dialogRef.afterClosed()
      .subscribe((dialogResult: boolean) => {
          if (dialogResult == true) {
          } 
      });

    // this.modal.open(CompensationDialogComponent, overlayConfigFactory({ case: this.case, className: "large" }, DamageCaseModalContext));
  }
}
