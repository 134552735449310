import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/auth/authentication.service';
import { CaseDetailsComponent } from '../components/case/case-details/case-details.component';
import { Observable } from 'rxjs';
import { CreateReportComponent } from '../components/reports/create-report/create-report.component';
import { DamageCase } from '../models/damage-case';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from '../components/ui/info-dialog/info-dialog.component';

@Injectable()
export class CaseHasReportGuard {
    constructor(
        public authService: AuthenticationService,
        public dialog: MatDialog,  
    ) { }

    canDeactivate(
        component: CaseDetailsComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        var r = this.getButtomRoute(nextState.root);
        if (!(r.component === CreateReportComponent)) {
            return this.verifyReportStatusForUser(component.case);
        }
        return true;
    }

    private verifyReportStatusForUser(c: DamageCase): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            if (this.authService.isEmployee) {
                if (!c.hasReport && c.checkinEmployee == this.authService.userId) {
                    const dialogRef = this.dialog.open(InfoDialogComponent, {
                        width: "80%",
                        maxWidth: "450px",
                        disableClose: true,
                        data: {
                            title: "Information",
                            message: "Der skal først oprettes en skaderapport på sag: " + c.caseNumber + " navn: " + c.customer.fullName
                          }
                      });

                      dialogRef.afterClosed()
                          .subscribe(() => {
                            resolve(false);
                            return
                          });
                }

                resolve(true);
            }
        });
    };

    private getButtomRoute(s: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
        if (s.firstChild) {
            return this.getButtomRoute(s.firstChild);
        }

        return s;
    }
}


// export const caseHasReportGuard: CanDeactivateFn<unknown> = (component, currentRoute, currentState, nextState) => {
//   // return true;

// //   constructor(
// //     public authService: AuthenticationService,
// //     private modal: Modal
// // ) { }

// // canDeactivate(
// //     component: CaseDetailsComponent,
// //     currentRoute: ActivatedRouteSnapshot,
// //     currentState: RouterStateSnapshot,
// //     nextState: RouterStateSnapshot
// // ): Observable<boolean> | Promise<boolean> | boolean {
// //     var r = this.getButtomRoute(nextState.root);
// //     if (!(r.component === CreateReportComponent)) {
// //         return this.verifyReportStatusForUser(component.case);
// //     }
// //     return true;
// // }

// // private verifyReportStatusForUser(c: DamageCase): Promise<boolean> {
// //     return new Promise<boolean>((resolve, reject) => {
// //         if (this.authService.isEmployee) {
// //             if (!c.hasReport && c.checkinEmployee == this.authService.userId) {
// //                 this.modal.alert().message("Der skal først oprettes en skaderapport på sag: " + c.caseNumber + " navn: " + c.customer.fullName).open().then(r =>
// //                     resolve(false));
// //                     return;
// //             }
// //         }

// //         resolve(true);
// //     });

// // }

// // private getButtomRoute(s: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
// //     if (s.firstChild) {
// //         return this.getButtomRoute(s.firstChild);
// //     }

// //     return s;
// // }

// };
