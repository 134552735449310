<div *ngIf="case" class="case-note-list">
  <div *ngIf="case.damageStatusId != 3">
    <form (ngSubmit)="saveNote()" #f="ngForm" class="create-form">
      <textarea class="comment-input" autosize [minHeight]="40" [maxHeight]="200" name="notetxt" [(ngModel)]="newNoteText" (keyup)="onKey($event)"
                placeholder="Skriv en kommentar. Tryk Ctrl+Enter for at sende."></textarea>
      <button type="submit" title="Gem kommentar" [disabled]="!newNoteText || newNoteText.trim().length === 0"><i class="fa fa-send-o"></i></button>
    </form>
  </div>

  <ul class="item-list">
    <li *ngFor="let n of case.notes;" (click)="noteSelected(n)" [@slideUpDownList]="n.state" class="item">

      <ng-container *ngIf="!n.editing; else edit">
        <avatar [user]="n.employee" size="small"></avatar>
        <div class="content" [innerHTML]="n.note | newLine"></div>
        <time [attr.datetime]="n.created" pubdate>{{n.created | date: "dd-MM-YYYY hh:mm"}}</time>

        <div class="readby" *ngIf="!authService.isCustomer && n.readBy">
          Læst af <avatar [user]="n.readBy" size="mini"></avatar> {{n.readOn | date: 'dd-MM-YYYY hh:mm'}}
        </div>

        <div class="actions">
          <div *ngIf="!n.readById && authService.isAdmin">
            <button (click)="readNote($event, n)" title="Marker som læst"><i class="fa fa-envelope-o"></i></button>
          </div>
        </div>
      </ng-container>

      <ng-template #edit>
        <textarea type="text" autosize [(ngModel)]="n.note" [minHeight]="40" [maxHeight]="200"></textarea>
        <div class="actions">
          <button (click)="updateNote($event, n)" title="Gem"><i class="fa fa-check" aria-hidden="true"></i><span class="visuallyhidden"></span></button>
          <button (click)="deleteNote($event, n)" title="Slet"><i class="fa fa-trash-o" aria-hidden="true"></i><span class="visuallyhidden"></span></button>
        </div>
      </ng-template>
    </li>
  </ul>
</div>
