<h2>Assistancerapport</h2>
<form (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" class="report">
    <div class="spacing-top">
        <label for="damageCause">Årsag til skaden</label>
        <textarea class="text-area" [(ngModel)]="formModel.cause" name="damageCause" id="damageCause" required [disabled]="isdisabled"></textarea>
    </div>
    <div class="spacing-top">
        <label for="damageDescription">Beskrivelse af skadeomfang</label>
        <textarea class="text-area" [(ngModel)]="formModel.damageDescription" name="damageDescription" id="damageDescription" required [disabled]="isdisabled"></textarea>
    </div>
    <div class="spacing-top">
        <label for="actionsPerformed">Hvad er der foretaget på udkald</label>
        <textarea class="text-area" [(ngModel)]="formModel.actionsPerformed" name="actionsPerformed" id="actionsPerformed" required [disabled]="isdisabled"></textarea>
    </div>
    <div class="spacing-top">
        <label for="furtherActions">Hvad skal der foretages efter udkald</label>
        <textarea class="text-area" [(ngModel)]="formModel.furtherActions" name="furtherActions" id="furtherActions" required [disabled]="isdisabled"></textarea>
    </div>

    <div class="form-group spacing-top">
        <div class="form-item">
            <label for="homeTotalArea">Boligstørrelse</label>
            <div class="input-group">
                <input type="number" [(ngModel)]="formModel.homeTotalArea" name="homeTotalArea" id="homeTotalArea" required [disabled]="isdisabled" />
                <span class="input-group-addon unit">m<sup>2</sup></span>
            </div>
        </div>
        <div class="form-item">
            <label for="damagedArea">Skadet areal ca.</label>
            <div class="input-group">
                <input type="number" [(ngModel)]="formModel.damagedArea" name="damagedArea" id="damagedArea" [max]="formModel.homeTotalArea" required [disabled]="isdisabled" />
                <span class="input-group-addon unit">m<sup>2</sup></span>
            </div>
        </div>
    </div>

    <!-- <div class="row">
        <div class="col-m-6">
            <label for="homeTotalArea">Boligstørrelse</label>
            <div class="input-group">
                <input type="text" [(ngModel)]="formModel.homeTotalArea" name="homeTotalArea" id="homeTotalArea" required [disabled]="isdisabled" />
                <span class="input-group-addon supplement">m<sup>2</sup></span>
            </div>
        </div>
        <div class="col-m-6">
            <label for="damagedArea">Skadet areal ca.</label>
            <div class="input-group">
                <input type="text" [(ngModel)]="formModel.damagedArea" name="damagedArea" id="damagedArea" [max]="formModel.homeTotalArea" required [disabled]="isdisabled" />
                <span class="input-group-addon supplement">m<sup>2</sup></span>
            </div>
        </div>
    </div> -->
    <div *ngIf="!f.form.valid">
        Bemærk! Der er felter der mangler at blive udfyldt.
    </div>
    <div class="buttons" *ngIf="mode == 'create'">
        <button type="submit" class="primary" [disabled]="isdisabled">Opret rapport</button>
        <button (click)="cancel($event)">Annuller</button>
    </div>
    <div class="buttons" *ngIf="mode == 'edit'">
        <button type="submit" class="primary" [disabled]="isdisabled">Gem og luk</button>
        <button (click)="cancel($event)">Annuller</button>
    </div>
</form>