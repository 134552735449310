import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { slideUpDown } from '../../../animations/animations';
import { SelectItem } from '../../../interfaces/ui/iselect-item';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'multiselect-dropdown',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './multi-select-dropdown.component.html',
  styleUrl: './multi-select-dropdown.component.scss',
  animations: [slideUpDown]
})
export class MultiSelectDropdownComponent implements OnInit {
  @Input() items!: SelectItem[];
  @Input() emptyPlaceholder: string;

  @Output() selectionChanged: EventEmitter<SelectItem[]>;

  opened!: boolean;
  summary!: string;


  constructor(public el: ElementRef) {
      this.emptyPlaceholder = "Vælg";
      this.selectionChanged = new EventEmitter<SelectItem[]>();
  }

  ngOnInit(): void {
      this.updateSummary(false);

      if (typeof window !== 'undefined') {
          let body = document.querySelector('body');
          if (body !== null) {
            body.addEventListener('click', e => {
                if (!this.opened || !e.target) { return; };
                if (this.el.nativeElement !== e.target && !this.el.nativeElement.contains((<any>e.target))) {
                    this.close();
                }
            }, false);
          }
      }
  }

  updateSummary(emitEvent = true) {
      let selected = this.items.filter(x => x.selected);

      if (selected.length == 0) {
          this.summary = this.emptyPlaceholder;
      }
      else if (selected.length == 1) {
          this.summary = selected[0].title;
      }
      else if (selected.length === this.items.length) {
          this.summary = "alle";
      }
      else {
          this.summary = selected.length + " valgt";
      }
      if (emitEvent) {
          this.selectionChanged.emit(selected);
      }
  }

  public toggle() {
      if (this.opened) {
          this.close();
      }
      else {
          this.open();
      }
  }

  private open() {
      this.opened = true;
  }

  private close() {
      this.opened = false;
  }
}
