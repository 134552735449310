<div class="container">
<div class="panes">
    <div class="list">
        <header>
            <h1>Erstatningsliste</h1>
            <div class="status" *ngIf="!claim?.processedByAssesor && !claim?.approvedByCustomer">
                Status: <strong>åben</strong>
            </div>
            <div class="status" *ngIf="claim?.processedByAssesor && !claim?.approvedByCustomer">
                Status: <strong>klar til godkendelse</strong>
            </div>
            <div class="status" *ngIf="claim?.processedByAssesor && claim?.approvedByCustomer">
                Status: <strong>godkendt</strong>
            </div>
        </header>
        <div #listContainer class="content">
            <table *ngIf="claim && claim.rooms?.length; else empty">
                <thead>
                    <tr>
                        <th colspan="4"></th>
                        <th colspan="2" class="compensation first last">Erstatning</th>
                    </tr>
                    <tr>
                        <th class="name">Navn</th>
                        <th class="purchase-date">Købdato</th>
                        <th class="price original">Indkøbspris</th>
                        <th class="price current">Pris i dag</th>
                        <th class="compensation first">kr.</th>
                        <th class="compensation last">afskrivning</th>
                        <th></th>
                    </tr>

                </thead>
                <tbody>
                    <ng-container *ngFor="let r of claim.rooms">
                        <tr class="room-header" [ngClass]="{'editing': r.editing }">
                            <ng-container *ngIf="!r.editing; else editroom">
                                <td class="name" colspan="6" (click)="selectRoom(r)">
                                    <i class="fa fa-angle-right" *ngIf="r !== selectedRoom"></i>
                                    <i class="fa fa-angle-down" *ngIf="r === selectedRoom"></i>
                                    {{r.name}} <avatar [user]="r.createdBy!" size="small" title="Oprettet af" *ngIf="!authService.isCustomer && r.createdBy"></avatar>
                                </td>
                                <td class="actions">
                                    <button *ngIf="!readOnly" (click)="editRoom(r)" [disabled]="itemEditing" title="Rediger"><i class="fa fa-pencil fa-lg"></i><span class="visuallyhidden"></span></button>
                                    <button *ngIf="!readOnly" (click)="deleteRoom(r)" [disabled]="itemEditing" title="Slet"><i class="fa fa-trash fa-lg"></i><span class="visuallyhidden"></span></button>
                                </td>
                            </ng-container>
                            <ng-template #editroom>
                                <td class="name" colspan="6"><input type="text" [(ngModel)]="r.name" name="name" dr-autofocus (keyup.enter)="saveRoom(r)" /></td>
                                <td class="actions">
                                    <button (click)="cancelEditRoom(r)" title="Annuller"><i class="fa fa-times fa-lg"></i><span class="visuallyhidden"></span></button>
                                    <button (click)="saveRoom(r)" title="Gem"><i class="fa fa-save fa-lg"></i><span class="visuallyhidden"></span></button>
                                </td>
                            </ng-template>

                        </tr>
                        <ng-container *ngIf="r === selectedRoom && r.items!.length > 0">
                            <tr *ngFor="let c of r.items" [ngClass]="{'editing': c.editing, 'invalid': c.invalid, 'added-by-company': c.addedByCompany}">
                                <ng-container *ngIf="!c.editing; else edit">
                                    <td class="name">
                                        <div class="td-content">
                                            {{c.name}}
                                            <i *ngIf="c.addedByCompany" title="Tilføjet af virksomheden" class="fa fa-flag"></i> 
                                            <avatar [user]="c.createdBy" size="small" title="Oprettet af" *ngIf="!authService.isCustomer && c.createdBy"></avatar>
                                        </div>
                                    </td>
                                    <td class="purchase-date">{{c.purchaseDate ? (c.purchaseDate.toString() | moment:'DD-MM-YYYY') : ''}}</td>
                                    <td class="price original">{{c.originalPrice | currency:'DKK':true}}</td>
                                    <td class="price current">{{c.currentPrice | currency:'DKK':true}}</td>
                                    <td class="compensation first">{{c.compensationAmount | currency:'DKK':true}}</td>
                                    <td class="compensation last">{{c.writeDownPercentage ? (c.writeDownPercentage | percentage) : ''}}</td>
                                    <td class="actions">
                                        <avatar [user]="c.updatedBy!" size="small" title="Sidst opdateret af" *ngIf="!authService.isCustomer"></avatar>
                                        <button *ngIf="!readOnly" (click)="editItem(c)" [disabled]="itemEditing" title="Rediger"><i class="fa fa-pencil fa-lg"></i><span class="visuallyhidden"></span></button>
                                        <button *ngIf="!readOnly" (click)="deleteItem(c)" [disabled]="itemEditing" title="Slet"><i class="fa fa-trash fa-lg"></i><span class="visuallyhidden"></span></button>
                                    </td>
                                </ng-container>
                                <ng-template #edit>
                                    <td class="name"><input type="text" [(ngModel)]="c.name" name="name"  dr-autofocus /></td>

                                    <td class="purchase-date" *ngIf="authService.isCustomer"><dr-datepicker name="purchaseDate" [(ngModel)]="c.purchaseDate"></dr-datepicker></td>
                                    <td class="purchase-date" *ngIf="!authService.isCustomer">{{c.purchaseDate ? (c.purchaseDate.toString() | moment:'DD-MM-YYYY') : ''}}</td>

                                    <td class="price original" *ngIf="authService.isCustomer"><input type="number" [(ngModel)]="c.originalPrice" name="originalPrice" /></td>
                                    <td class="price original" *ngIf="!authService.isCustomer">{{c.originalPrice | currency:'DKK':true}}</td>

                                    <td class="price current" *ngIf="authService.isCustomer"><input type="number" [(ngModel)]="c.currentPrice" name="currentPrice" /></td>
                                    <td class="price current" *ngIf="!authService.isCustomer">{{c.currentPrice | currency:'DKK':true}}</td>

                                    <td class="compensation first" *ngIf="authService.isAdmin && c.id"><input type="number" [(ngModel)]="c.compensationAmount" (ngModelChange)="updateItemPercentage(c)" name="compensationAmount" /></td>
                                    <td class="compensation first" *ngIf="!authService.isAdmin || (authService.isAdmin && !c.id)">{{c.compensationAmount | currency:'DKK':true}}</td>

                                    <td class="compensation last" *ngIf="authService.isAdmin && c.id"><input type="number" [(ngModel)]="c.writeDownPercentage" (ngModelChange)="updateItemAmount(c)" name="writeDownPercentage" /></td>
                                    <td class="compensation last" *ngIf="!authService.isAdmin || (authService.isAdmin && !c.id)">{{c.writeDownPercentage ? (c.writeDownPercentage | percentage) :'0%'}}</td>

                                    <td class="actions">
                                        <button (click)="cancelEditItem(c)" title="Annuller"><i class="fa fa-times fa-lg"></i><span class="visuallyhidden"></span></button>
                                        <button (click)="saveItem(c)" title="Gem"><i class="fa fa-save fa-lg"></i><span class="visuallyhidden"></span></button>
                                    </td>
                                </ng-template>
                            </tr>
                        </ng-container>
                        <tr *ngIf="r === selectedRoom && r.items?.length === 0">
                            <td colspan="7" class="empty">Ingen genstande i dette rum</td>
                        </tr>
                    </ng-container>
                </tbody>
                <tfoot>
                    <tr class="spacer">
                        <td colspan="7"></td>
                    </tr>
                    <tr class="compensation-total">
                        <td colspan="2"></td>
                        <td colspan="2"><small>Samlet pris i dag</small><br />{{currentPriceTotal  | currency:'DKK':true}}</td>
                        <td colspan="2"><small>Erstatningssum</small><br />{{compensationTotal  | currency:'DKK':true}}</td>
                        <td colspan="1"></td>
                    </tr>
                    <tr *ngIf="claim.approvedByCustomer" class="approved-by">
                        <td colspan="4"></td>
                        <td colspan="3">Godkendt af <br /><strong>{{case.customer.fullName}}</strong><br />{{claim.customerApprovalDate ? (claim.customerApprovalDate.toString() | moment:'DD-MM-YYYY HH:mm') :''}}</td>
                    </tr>
                </tfoot>
            </table>
            <ng-template #empty>
                <div class="empty-placeholder">
                    Opret et rum for at komme i gang
                </div>
            </ng-template>
        </div>
        <button *ngIf="!readOnly" type="button" class="link" (click)="addRoom()" [disabled]="itemEditing"><i class="fa fa-plus fa-lg"></i> Tilføj rum</button>
        <button *ngIf="!readOnly" type="button" class="link" (click)="addBlankItem()" [disabled]="itemEditing || selectedRoom === null"><i class="fa fa-plus fa-lg"></i> Tilføj genstand</button>
    </div>
    <div class="picker content-container">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
            <div>
            <mat-tab label="Firma" *ngIf="authService.isCustomer">
                <div class="scrollbar">
                    <householdeffectlist [case]="case" (add)="addHouseholdEffect($event)"></householdeffectlist>
                </div>
            </mat-tab>
            <mat-tab label="Huskeliste">
                <div class="scrollbar">
                    <samplelist (add)="addSampleItem($event)"></samplelist>
                </div>
            </mat-tab>  
            </div>
        </mat-tab-group>
    </div>
</div>
<div class="dialog-buttons">
    <div class="left">
        <button (click)="approve()" *ngIf="authService.isCustomer && claim && claim.processedByAssesor" [disabled]="claim.approvedByCustomer">Godkend erstatningsliste</button>
        <button (click)="sendForApproval()" *ngIf="authService.isAdmin && claim" [disabled]="claim.processedByAssesor">Til godkendelse hos skadelidte</button>
        <button class="primary" (click)="onDismiss()">Luk</button>
    </div>
    <div class="right">
        <button class="link" (click)="openReport()"><i class="fa fa-file-pdf-o"></i> PDF</button>
    </div>
</div>
</div>
