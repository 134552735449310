import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DamageCase } from '../../../models/damage-case';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { FormMode } from '../../../types/mode.type';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReportBase } from '../../../models/report-base';

@Component({
  selector: 'app-report-form-base',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './report-form-base.component.html',
  styleUrl: './report-form-base.component.scss'
})
export class ReportFormBaseComponent<T extends ReportBase> implements OnInit {
  @Input() case!: DamageCase;
  @Input() mode!: FormMode;
  @Input() reportId!: number;

  @Output() created: EventEmitter<ReportBase>;
  @Output() saved: EventEmitter<ReportBase>;
  @Output() cancelled: EventEmitter<ReportBase>;

  formModel!: T;

  isdisabled: boolean = false;

  constructor(protected authService: AuthenticationService) {
      this.mode = "edit";
      this.created = new EventEmitter<ReportBase>();
      this.saved = new EventEmitter<ReportBase>();
      this.cancelled = new EventEmitter<ReportBase>();
  }

  ngOnInit() {
      //this.isdisabled = this.case.damageStatusId != '2' && this.case.damageStatusId != '4';
      this.isdisabled = this.case.damageStatusId == 3;
  }

  cancel(event: MouseEvent): void {
    console.log("Cancel Button", this.formModel);
    event.preventDefault();
    this.cancelled.emit(this.formModel);
  }

  onSubmit(): void {
  }
}
