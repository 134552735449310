import { Component, OnDestroy, OnInit } from '@angular/core';
import { Employee } from '../../../models/employee';
import { EmployeesService } from '../../../services/employees/employees.service';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { ActionService } from '../../../services/action.service';
import { ActivatedRoute, Router, RouterModule, RouterOutlet } from '@angular/router';
import { HeaderButton } from '../../../interfaces/header-button';
import { UserAvatarComponent } from '../../user-avatar/user-avatar.component';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from "../../ui/spinner/spinner.component";

@Component({
  selector: 'app-employees',
  standalone: true,
  imports: [UserAvatarComponent, RouterOutlet, CommonModule, SpinnerComponent],
  templateUrl: './employees.component.html',
  styleUrl: './employees.component.scss'
})
export class EmployeesComponent  implements OnInit, OnDestroy {
  employees!: Employee[];
  selectedEmployee!: Employee | null;
  employeeId!: string;
  router!: Router;
  loading: boolean = true;

  constructor(private employeesService: EmployeesService,
      public authService: AuthenticationService,
      private actionService: ActionService,
      private _router: Router,
      private route: ActivatedRoute) {
        this.actionService.onButtonClick
            .subscribe(a => this.onHeaderButtonClick(a));

      this.employeesService.employeeUpdated
        .subscribe(e => {
            console.log("onEmployeeUpdated: ", e)
            this.onEmployeeUpdated(e)
        });

      this.router = _router;
  }


  private onHeaderButtonClick(btn: HeaderButton) {
      if (btn.alias === "newEmployee") {
          this.router.navigate(["create"], { relativeTo: this.route });
      }
  }

  private onEmployeeUpdated(e: Employee) {
      this.getEmployees();
  }

  getEmployees(): void {
      this.employeesService.getEmployees()
        .subscribe(employees => {
            this.employees = employees;
            this.loading = false;
        });
  }

  ngOnInit(): void {
      this.getEmployees();

      this.actionService.addHeaderButton({
          alias: "newEmployee",
          text: "Opret medarbejder",
          iconClass: "fa-plus"
      });
  }

  ngOnDestroy(): void {
      this.actionService.removeHeaderButton("newEmployee");
  }

  onSelect(employee: Employee): void {
      if (this.selectedEmployee === employee) {
          this.selectedEmployee = null;
      }
      else {
          this.selectedEmployee = employee;
          this.router.navigate([employee.id], { relativeTo: this.route });
      }
  }
}
