import { EventEmitter, Injectable } from '@angular/core';
import { ServiceBase } from '../base/service-base.service';
import { Employee } from '../../models/employee';
import { AlertService } from '../alert.service';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeesService extends ServiceBase {
  private baseurl = '/api/admin/employee/';
  employeeUpdated: EventEmitter<Employee>;

  constructor(authhttp: HttpClient, alert: AlertService) {
      super(authhttp);
      this.employeeUpdated = new EventEmitter<Employee>();
  }
  
  getEmployees(): Observable<Employee[]> {
    return this.getJson<Employee[]>(this.baseurl + 'list');
  }
  
  getEmployee(id: string): Observable<Employee> {
    return this.getJson<Employee>(this.baseurl + 'get/' + id);
  }

  createEmployee(employee: Employee): Observable<Employee> {
    return this.postJson<Employee>(this.baseurl + 'create', employee);
  }

  updateEmployee(employee: Employee): Observable<Employee> {
    return this.postJson(this.baseurl + 'update', employee);
  }
}
