import { ListItem, ListItemState } from "../../interfaces/list-item";
import { Employee } from "../employee";
import { MeasurementValue } from "./measurement-value";

export class MeasurementTransaction  implements ListItem {
    id!: number;
    date!: Date;
    measurementLogId!: number;
    values: MeasurementValue[] = [];
    employee!: Employee;
    instrument!: string;
    referenceMeasurement!: number;
    state!: ListItemState;
}
