import { DatePickerOptions } from "../models/ui/date-picker-options";

export const datepickerOpts: DatePickerOptions = {
    autoApply: true,
    style: 'normal',
    locale: "da",
    minDate: undefined,
    maxDate: undefined,
    initialDate: undefined,
    firstWeekdaySunday: false,
    format: 'DD-MM-YYYY'
}