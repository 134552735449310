<div #parentContainer class="content-container">
    <h1>Indstillinger</h1>
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" dynamicHeight>
        <div>
        <mat-tab label="Firma">
            <div class="scrollbar">
                <companysettings *ngIf="adminSettings" [settings]="adminSettings"></companysettings>
            </div>
        </mat-tab>
        <mat-tab label="Huskeliste">
            <div class="scrollbar">
                <sampleitems *ngIf="adminSettings" [settings]="adminSettings"></sampleitems>
            </div>
        </mat-tab>  
        </div>
    </mat-tab-group>
</div>
