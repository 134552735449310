<div class="date-time">
    <label for="searchFromDate">Fra</label>
    <input type="text" class="styled-input-date" id="searchFromDate" name="date" placeholder="Vælg dato" required
        min="2010-01-01"
        onfocus="this.type='date'" 
        onblur="if(!this.value) { this.type='text'; }"
        (keydown)="disableTyping($event)"
        [(ngModel)]="fromDate"
        (ngModelChange)="modelChange()">   
    <i (click)="openDatePicker('searchFromDate')" class="fa fa-calendar fa-lg"></i>

    <label for="searchToDate">Til</label>
    <input type="text" class="styled-input-date" id="searchToDate" name="date" placeholder="Vælg dato" required
        onfocus="this.type='date'" 
        onblur="if(!this.value) { this.type='text'; }"
        (keydown)="disableTyping($event)"
        [(ngModel)]="toDate"
        (ngModelChange)="modelChange()">   
    <i (click)="openDatePicker('searchToDate')" class="fa fa-calendar fa-lg"></i>
</div>

<!-- <dr-datepicker [options]="dpOpts" [(ngModel)]="fromDate" fieldid="searchFromDate" (ngModelChange)="modelChange()"></dr-datepicker> -->
<!-- <dr-datepicker [options]="dpOpts" [(ngModel)]="toDate" fieldid="searchToDate" (ngModelChange)="modelChange()"></dr-datepicker> -->
<multiselect-dropdown [items]="types" (selectionChanged)="selectionChanged($event)"></multiselect-dropdown>