<div ng2FileDrop
     [ngClass]="{'file-over': hasBaseDropZoneOver}"
     (fileOver)="fileOverBase($event)"
     [uploader]="uploader"
     class="well file-upload">
    <span class="placeholder">{{placeholderText}}</span>
    <label class="browse-btn" for="uploadField">
        {{browseButtonText}}
    </label>
        <input type="file" class="hidden" ng2FileSelect [uploader]="uploader" id="uploadField" multiple />


    <progressbar *ngIf="uploader.isUploading" [value]="uploader.progress"></progressbar>
    <div *ngFor="let item of uploader.queue" class="file" [class.error]="item.isError">
        {{ item?.file?.name }} <i class="fa fa-check" *ngIf="item.isSuccess"></i> <i class="fa fa-times-circle" *ngIf="item.isError"></i>
    </div>
</div>