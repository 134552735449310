import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Settings } from '../../../interfaces/settings/settings';
import { SettingsService } from '../../../services/settings/settings.service';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CompanySettingsComponent } from '../company-settings/company-settings.component';
import { SampleItemsComponent } from '../sample-items/sample-items.component';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [CommonModule, FormsModule, CompanySettingsComponent, SampleItemsComponent, MatTabsModule],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss'
})
export class SettingsComponent implements OnInit {
  public adminSettings!: Settings;

  constructor(
    private adminSetService: SettingsService, 
    private authService: AuthenticationService) { }

  ngOnInit(): void {
      this.adminSetService.getAdminSettings()
        .subscribe(result => this.adminSettings = result.result);
  }

  save() {
      this.adminSetService.saveAdminSettings(this.adminSettings);
  }
}
