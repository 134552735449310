import { ListItem, ListItemState } from "../../interfaces/list-item";

export class MeasurementValue  implements ListItem {
    id!: number;
    pointId!: number;
    pointName!: string;
    transactionId!: number;
    value!: number;

    state!: ListItemState;
    date!: Date;
}
