import { Component, OnInit } from '@angular/core';
import { AssistanceReport } from '../../../models/report/assistance-report';
import { AssistanceReportService } from '../../../services/assistance-report.service';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { ReportFormBaseComponent } from '../report-form-base/report-form-base.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Employee } from '../../../models/employee';
import { EmployeesService } from '../../../services/employees/employees.service';

@Component({
  selector: 'report-assistance',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './assistance-report-form.component.html',
  styleUrl: './assistance-report-form.component.scss'
})
export class AssistanceReportFormComponent  extends ReportFormBaseComponent<AssistanceReport> implements OnInit {
  constructor(
    authService: AuthenticationService, 
    private employeesService: EmployeesService,
    private assistanceReportService: AssistanceReportService) {
      super(authService);
      this.formModel = new AssistanceReport();
  }

  override ngOnInit() {
      if (this.mode == "create") {
          this.formModel.damageCaseId = this.case.id;
      }
      else if (this.mode == "edit" && this.reportId) {
          //We are in edit mode and have the data we need to go load the report from the server
          this.assistanceReportService.get(this.reportId)
            .subscribe((report: any) => this.formModel = report.result);
      }
  }

  override onSubmit() {
    this.employeesService.getEmployee(this.authService.userId)
      .subscribe((result: Employee) => {
        if (this.mode == "create") {
          this.formModel.createdBy = result;
          this.formModel.updatedBy = result;
          this.formModel.created = new Date();
          this.formModel.updated = new Date();
          
          this.assistanceReportService.create(this.formModel)
            .subscribe((r: AssistanceReport) => this.created.emit(r));
        }
        else {
          this.formModel.updatedBy = result;
          this.formModel.updated = new Date();

          this.assistanceReportService.update(this.formModel)
            .subscribe((r: AssistanceReport) => this.saved.emit(r));
        }
      });
  }
}
