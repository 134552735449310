import { Injectable } from '@angular/core';
import { ServiceBase } from './base/service-base.service';
import { WelcomeData } from '../interfaces/welcome-data';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class WelcomeService extends ServiceBase {
  private baseurl = '/api/welcome/';

  constructor(http: HttpClient, alert: AlertService) {
      super(http);
  }

  get(companyId: number): Observable<WelcomeData> {
      return this.getJson<WelcomeData>(`${this.baseurl}${companyId}`);
  }
}
