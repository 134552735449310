import { Attachment } from "../attachment";
import { MeasurementTransaction } from "./measurement-transaction";
import { MeasuringPoint } from "./measuring-point";

export class MeasurementLog {
    id!: number;
    damageCaseId!: number;
    name!: string;
    attachments!: Attachment[];
    points!: MeasuringPoint[];
    transactions!: MeasurementTransaction[];
}
