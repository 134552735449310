import { Injectable } from '@angular/core';
import { ServiceBase } from '../base/service-base.service';
import { AlertService } from '../alert.service';
import { Observable } from 'rxjs';
import { CompensationClaim } from '../../interfaces/compensation-claim';
import { CompensationClaimItemRoom } from '../../interfaces/compensation-claim-item-room';
import { CompensationClaimItem } from '../../interfaces/compensation-claim-item';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CompensationClaimService extends ServiceBase {
  private rooturl = '/api/compensationclaim/';

  constructor(authhttp: HttpClient, alert: AlertService) {
      super(authhttp);
  }

  getOrCreateClaim(caseid: number): Observable<CompensationClaim> {
    return this.getJson(this.rooturl + caseid);
  }

  updateClaim(caseid: number, claim: CompensationClaim): Observable<CompensationClaim> {
    return this.patchJson(this.rooturl + caseid, claim);
  }

  createRoom(caseid: number, name: string): Observable<CompensationClaimItemRoom> {
    return this.putJson<CompensationClaimItemRoom>(this.rooturl + caseid + "/room", { claimId: caseid, name: name });
  }

  updateRoom(caseid: number, room: CompensationClaimItemRoom): Observable<CompensationClaimItemRoom> {
    return this.patchJson<CompensationClaimItemRoom>(this.rooturl + caseid + "/room", room);
  }

  deleteRoom(caseid: number, room: CompensationClaimItemRoom): Observable<CompensationClaimItemRoom> {
    return this.deleteJson<CompensationClaimItemRoom>(`${this.rooturl}${caseid}/room/${room.id}`);
  }

  create(caseid: number, roomid: number, item: CompensationClaimItem): Observable<CompensationClaimItem> {
    return this.putJson<CompensationClaimItem>(`${this.rooturl}${caseid}/${roomid}/items`, item);
  }

  update(caseid: number, roomid: number, item: CompensationClaimItem): Observable<CompensationClaimItem> {
    return this.patchJson<CompensationClaimItem>(`${this.rooturl}${caseid}/${roomid}/items`, item);
  }

  delete(caseid: number, roomid: number, item: CompensationClaimItem): Observable<CompensationClaimItem> {
    return this.deleteJson(`${this.rooturl}${caseid}/${roomid}/items/${item.id}`);
  }
}
