import { Injectable } from '@angular/core';
import { ServiceBase } from '../base/service-base.service';
import { AlertService } from '../alert.service';
import { MeasurementLog } from '../../models/measurement/measurement-log';
import { Attachment } from '../../models/attachment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MeasurementLogService extends ServiceBase {
  private baseUrl = '/api/measurement/log/';

  constructor(authhttp: HttpClient, alert: AlertService) {
      super(authhttp);
  }

  create(point: MeasurementLog): Observable<MeasurementLog> {
      return this.postJson<MeasurementLog>(this.baseUrl + "create", point);
  }

  get(pointId: number): Observable<MeasurementLog> {
      return this.getJson<MeasurementLog>(this.baseUrl + "get/" + pointId);
  }

  update(point: MeasurementLog): Observable<MeasurementLog> {
      return this.postJson<MeasurementLog>(this.baseUrl + "update", point);
  }

  // deleteAttachment(log: MeasurementLog, attachment: Attachment): Observable<MeasurementLog> {
  //     const data = {
  //         log: log,
  //         attachment: attachment
  //     };
  //     return this.postJson<MeasurementLog>(this.baseUrl + "deleteattachment", data);
  // }

  deleteAttachment(attachmentId: string, measurementLogId: string): Observable<MeasurementLog> {
    return this.deleteJson<MeasurementLog>(`${this.baseUrl}/${attachmentId}/${measurementLogId}/deleteattachment`);
  }


  getImage(companyId: string, attachmentId: string, imageName: string) {
    return this.http.get(`${this.baseUrl}/${companyId}/${attachmentId}/attachment/${imageName}`, { responseType: 'blob' });
  }
}
