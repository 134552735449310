import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'dr-spinner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss'
})
export class SpinnerComponent implements OnDestroy {
    private currentTimeout!: number;
    public isDelayedRunning: boolean = false;

    @Input() public delay: number = 300;
    @Input() style: string = "blue";
    @Input() public set isRunning(value: boolean) {
        if (!value) {
            this.cancelTimeout();
            this.isDelayedRunning = false;
            return;
        }

        if (this.currentTimeout) {
            return;
        }

        this.currentTimeout = setTimeout(() => {
            this.isDelayedRunning = value;
            this.cancelTimeout();
        }, this.delay) as unknown as number;
    }

    private cancelTimeout(): void {
        clearTimeout(this.currentTimeout);
        this.currentTimeout = 0;
    }

    ngOnDestroy(): any {
        this.cancelTimeout();
    }
}
