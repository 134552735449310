import { animate, style, transition, trigger } from "@angular/animations";

export const slideUpDown = trigger('slideUpDown', [
    transition('void => *', [
        style({ height: 0, opacity: 0, overflow: 'hidden' }),
        animate('150ms', style({ height: '*', opacity: 1 }))
    ]),
    transition('* => void', [
        style({ height: '*', opacity: 1, overflow: 'hidden' }),
        animate('150ms', style({ height: 0, opacity: 0 }))
    ])

]);

export const fadeInOut = trigger('fadeInOut', [
    transition('void => *', [
        style({  opacity: 0, }),
        animate('150ms ease-in-out', style({ opacity: 1 }))
    ]),
    transition('* => void', [
        style({ opacity: 1 }),
        animate('150ms ease-in-out', style({ opacity: 0 }))
    ])

]);

export const fadeUpDown = trigger('fadeUpDown', [
    transition('void => *', [
        style({ transform: 'translateY(-20px)', opacity: 0, overflow: 'hidden' }),
        animate('150ms ease-in-out', style({ transform: 'translateY(0)', opacity: 1 }))
    ]),
    transition('* => void', [
        style({ transform: 'translateY(0)', opacity: 1, overflow: 'hidden' }),
        animate('150ms ease-in-out', style({ transform: 'translateY(-20px)', opacity: 0 }))
    ])

]);

export const slideUpDownList = trigger('slideUpDownList', [
    transition('void => add', [
        style({ height: 0, opacity: 0, overflow: 'hidden' }),
        animate('150ms', style({ height: '*', opacity: 1 }))
    ]),
    transition('remove => void', [
        style({ height: '*', opacity: 1, overflow: 'hidden' }),
        animate('150ms', style({ height: 0, opacity: 0 }))
    ])

]);

export const slideLeftRightList = trigger('slideLeftRightList', [
    transition('void => add', [
        style({ width: 0, opacity: 0, overflow: 'hidden' }),
        animate('150ms', style({ width: '*', opacity: 1 }))
    ]),
    transition('remove => void', [
        style({ width: '*', opacity: 1, overflow: 'hidden' }),
        animate('150ms', style({ width: 0, opacity: 0 }))
    ])

]);