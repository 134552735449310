import { Component, OnInit } from '@angular/core';
import { EquipmentType } from '../../../interfaces/equipment/equipment-type';
import { EquipmentService } from '../../../services/equipment/equipment.service';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NotificationService } from '../../../services/notification.service';
import { EventNotification } from '../../../interfaces/event-notification';
import { filter, map } from 'rxjs/operators';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'equipment-statistics',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './equipment-statistics.component.html',
  styleUrl: './equipment-statistics.component.scss'
})
export class EquipmentStatisticsComponent implements OnInit {
  types: EquipmentType[] = [];

  constructor(
      private equipmentService: EquipmentService,
      public authService: AuthenticationService,
      public router: Router,
      private route: ActivatedRoute,
      private notificationService: NotificationService
  ) {
    //******************* JONAS - HARDCODET DATA 1 ********************************

    //this.types = this.getHardcodedData();
    equipmentService.getTypes()
      .pipe(filter((r: EquipmentType[]) => r != null))
      .subscribe((data: EquipmentType[]) => {
        // console.log("Result data: ", data);
        this.types = data;
      })
    //******************* JONAS - HARDCODET DATA 1-slut ********************************

    notificationService.notifications
    .pipe(
        map(n => <EventNotification<EquipmentType[], any>>n)
    )
    .subscribe(n => {
        if (n.Type === "equipmentCountUpdated") {
            for (let t of this.types) {
                var item = n.Data.find(x => x.id === t.id);
                Object.assign(t, item);
            }
        }
    });

      // notificationService.notifications
      //     .map(n => <EventNotification<EquipmentType[], any>>n)
      //     .subscribe(n => {
      //         if (n.type === "equipmentCountUpdated") {
      //             for (let t of this.types) {
      //                 var item = n.data.find(x => x.id === t.id);
      //                 if (item) {
      //                     Object.assign(t, item);
      //                 }
      //             }
      //         }
      //     });
  }




  //******************* JONAS - HARDCODET DATA 2 ********************************
  // private getHardcodedData(): any[] {
  //   return [
  //     {
  //       "id": 1,
  //       "name": "Affugter",
  //       "countAvailable": 1,
  //       "countTotal": 8,
  //       "countFailed": 1,
  //       "countPlaced": 6,
  //       "trackIndividually": true,
  //       "showStatusOnHome": true
  //     },
  //     {
  //       "id": 7,
  //       "name": "Blæser",
  //       "countAvailable": 2,
  //       "countTotal": 2,
  //       "countFailed": 0,
  //       "countPlaced": 0,
  //       "trackIndividually": true,
  //       "showStatusOnHome": true
  //     },
  //     {
  //       "id": 8,
  //       "name": "Flyttekasse",
  //       "countAvailable": 445,
  //       "countTotal": 1500,
  //       "countFailed": 0,
  //       "countPlaced": 0,
  //       "trackIndividually": false,
  //       "showStatusOnHome": true
  //     },
  //     {
  //       "id": 3,
  //       "name": "Genhusningsvogn",
  //       "countAvailable": 0,
  //       "countTotal": 0,
  //       "countFailed": 0,
  //       "countPlaced": 0,
  //       "trackIndividually": true,
  //       "showStatusOnHome": true
  //     },
  //     {
  //       "id": 6,
  //       "name": "Miljø-/Køkkenvogn",
  //       "countAvailable": 0,
  //       "countTotal": 0,
  //       "countFailed": 0,
  //       "countPlaced": 0,
  //       "trackIndividually": true,
  //       "showStatusOnHome": true
  //     },
  //     {
  //       "id": 4,
  //       "name": "Toiletvogn",
  //       "countAvailable": 2,
  //       "countTotal": 2,
  //       "countFailed": 0,
  //       "countPlaced": 0,
  //       "trackIndividually": true,
  //       "showStatusOnHome": true
  //     },
  //     {
  //       "id": 2,
  //       "name": "Trailer",
  //       "countAvailable": 0,
  //       "countTotal": 0,
  //       "countFailed": 0,
  //       "countPlaced": 0,
  //       "trackIndividually": true,
  //       "showStatusOnHome": true
  //     },
  //     {
  //       "id": 5,
  //       "name": "Vaskelinievogn",
  //       "countAvailable": 0,
  //       "countTotal": 0,
  //       "countFailed": 0,
  //       "countPlaced": 0,
  //       "trackIndividually": true,
  //       "showStatusOnHome": true
  //     }
  //   ]
  // }


  //******************* JONAS - HARDCODET DATA 2- slut ********************************


  ngOnInit(): void {
      this.equipmentService.getTypes()
        .pipe(filter((r: EquipmentType[]) => r != null))
        .subscribe(t => {
            this.types = t.filter(x => x.showStatusOnHome);
        })
  }
}
