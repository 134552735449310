<div class="measurementlog-image" *ngIf="log">
    <div class="images" *ngIf="log.attachments && log.attachments.length > 0">
        <div class="image" *ngFor="let a of imageSrcList; let i = index" >
            <img [src]="a" height="100" (click)="openImageInNewWindow(a)" title="Åbn billedet i fuld størrelse" />
            <button class="delete" (click)="deleteImage(i)" title="Slet billede"><i class="fa fa-remove"></i><span class="visuallyhidden"></span></button>
        </div>
    </div>
    <div class="upload">
        <file-upload [uploadUrl]="uploadUrl" (fileUploaded)="onFileUploaded($event)" [itemAlias]="'file'"></file-upload>
        <button (click)="onUpload($event)">Tilføj billeder</button>
    </div>
</div>