import { Component, Input, OnInit } from '@angular/core';
import { DamageCase } from '../../../models/damage-case';
import { Builder } from '../../../models/builder';
import { BuilderItem } from '../../../interfaces/builder-item';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { BuilderService } from '../../../services/builder.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'case-builder-list',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './case-builder-list.component.html',
  styleUrl: './case-builder-list.component.scss'
})
export class CaseBuilderListComponent implements OnInit {
  @Input() case!: DamageCase;

  selectedBuilder: Builder;
  public blankBuilder: Builder;
  allBuilders!: BuilderItem[];

  constructor(public authService: AuthenticationService,
      private builderService: BuilderService
  ) {
      this.blankBuilder = new Builder();
      this.blankBuilder.id = -1;
      this.blankBuilder.name = "Vælg håndværker";
      this.selectedBuilder = this.blankBuilder;
  }

  ngOnInit() {
    this.builderService.list()
      .subscribe((dcresult: any) => {
        this.allBuilders = dcresult.result;
        this.allBuilders.splice(0, 0, this.blankBuilder);
      });

    //   this.builderService.list().then(dcresult => this.allBuilders = dcresult).then(x => this.allBuilders.splice(0, 0, this.blankBuilder));
  }

  addBuilder() {
    //We first check whether we can find an existing employee in the list, if so, we do nothing
    if (this.case.builders.find(x => x.id == this.selectedBuilder.id) == undefined) {
        this.builderService.addToCase(this.case.id, this.selectedBuilder.id)
            .subscribe((e: Builder) => this.case.builders.push(e));
        // .then(e => this.case.builders.push(e));
    }
  }

  removeBuilder(event: Event, builder: Builder) {
      event.stopPropagation();

      this.builderService.removeFromCase(this.case.id, builder.id)
        .subscribe((e: Builder) => this.case.builders.splice(this.case.builders.indexOf(builder), 1));
    //   .then(e => this.case.builders.splice(this.case.builders.indexOf(builder), 1));
  }
}
