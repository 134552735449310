<h2>Udstyr</h2>
<ul>
    <li *ngFor="let t of types">
        <a [routerLink]="['equipment', t.id]" *ngIf="authService.isAdmin; else content">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </a>
        <ng-template #content>
            <span class="name">{{t.name}}</span>
            <span class="available" title="Tilgængelig"><!--<span class="visuallyhidden">Tilgængelig: </span>-->{{t.countAvailable}}</span>
            <span class="placed" title="Opsat"><!--<span class="visuallyhidden">Opsat: </span>--><span class="content" *ngIf="t.countPlaced! > 0">{{t.countPlaced}}</span></span>
            <span class="failed" title="Fejlmeldt"><!--<span class="visuallyhidden">Fejlmeldt: </span>--><span class="content" *ngIf="t.countFailed! > 0">{{t.countFailed}}</span></span>
        </ng-template>
    </li>
</ul>
