import { Injectable } from '@angular/core';
import { DamageCase } from '../models/damage-case';
import { BehaviorSubject, Observable } from 'rxjs';
import { DamageCaseCounts } from '../interfaces/damage-case-counts';
import { ApiError } from '../models/api-error';
import { Employee } from '../models/employee';
import { DamageCaseNote } from '../models/damage-case-note';
import { DamageCaseAppointment } from '../interfaces/damage-case-appointment';
import { CustomerSatisfactionAvg } from '../models/customer-satisfaction-avg';
import { CustomerSatisfactionUpdate } from '../models/customer-satisfaction-update';
import { ServiceBase } from './base/service-base.service';
import { AlertService } from './alert.service';
import { SearchCriteria } from '../interfaces/search-criteria';
import { HttpClient } from '@angular/common/http';
import { Vehicle } from '../interfaces/vehicles/vehicle';
import { AuthenticationService } from './auth/authentication.service';
import { EventSourcePolyfill} from 'event-source-polyfill';

@Injectable({
  providedIn: 'root'
})
export class DamageCaseService extends ServiceBase {
  private baseurl = "/api/damagecase/";
  private dcurl = '/api/damagecase/list';
  private dcurlStream = '/api/damagecase/liste';
  private dcurlSearchStream = '/api/damagecase/search';
  private createurl = '/api/damagecase/create';
  private employeeUrl = '/api/damagecase/employee/';
  private noteurl = '/api/notes/';
  private dcbaseurl = '/api/damagecase/';
  private vehicleUrl = '/api/damagecase/vehicle/';

  private caseItemsSubject: BehaviorSubject<DamageCase[]> = new BehaviorSubject<DamageCase[]>([]); // Holds the current list of items
  private caseItemsSearchSubject: BehaviorSubject<DamageCase[]> = new BehaviorSubject<DamageCase[]>([]); // Holds the current list of items

  damageCasesSubject: Observable<DamageCase[]> = this.caseItemsSubject.asObservable(); // Expose the observable for components to subscribe
  damageCasesSearchSubject: Observable<DamageCase[]> = this.caseItemsSearchSubject.asObservable(); // Expose the observable for components to subscribe

    constructor(
        authhttp: HttpClient, 
        private authenticationService: AuthenticationService,
        alert: AlertService) {
        super(authhttp);
        //  alert, ["checkinDate", "reopenDate", "damageDate", "appointments.appointmentDate"]
        }

    addDamageCaseItem(item: DamageCase): void {
        // Get the current items from the BehaviorSubject
        const currentItems = this.caseItemsSubject.value;
        this.caseItemsSubject.next([...currentItems, item]);
    }

    removeDamageCaseItem(item: DamageCase): void {
        const currentItems = this.caseItemsSubject.value;
        const index: number = currentItems.findIndex(obj => obj === item);

        if (index !== -1) {
            currentItems.splice(index, 1);  // Removes the item in place
            this.caseItemsSubject.next(currentItems);  // Emits the updated array
        }
    }

    replaceDamageCaseItem(item: DamageCase): void {
        this.removeDamageCaseItem(item);
        this.addDamageCaseItem(item);
    }

  // Method to add a single item to the BehaviorSubject
  addDamageCaseItemSearch(item: DamageCase): void {    
    // Get the current items from the BehaviorSubject
    let currentItems = this.caseItemsSubject.value;

    // Check if the item already exists in the list
    const exists = currentItems.some(item => item.id === item.id);

    // If the item doesn't exist, add it to the list
    if (!exists) {
        console.log("item: ", item)
        currentItems = [...currentItems, item];
    }

    // Update the BehaviorSubject with the new list
    this.caseItemsSearchSubject.next([...currentItems]);
  }

  // Method to remove items not fetched (e.g., not in the list anymore)
  removeUnfetchedItems(): void {
    // Get the current items from the BehaviorSubject
    const currentItems = this.caseItemsSubject.value;
    const currentItemsSearch = this.caseItemsSearchSubject.value; 
    const fetchedIds = new Set(currentItemsSearch.map(item => item.id));

    // Get the items that are not in updatedItems (i.e., those that were removed)
    const removedItems = currentItems.filter(item => !fetchedIds.has(item.id));

    removedItems.forEach(element => {
        const index = currentItems.findIndex(item => item === element);
  
        if (index !== -1) {
            currentItems.splice(index, 1);  // Removes the item in place
            this.caseItemsSubject.next(currentItems);  // Emits the updated array
        }
    });

    this.caseItemsSearchSubject.next([]);
  }

  // Method to clear all items (optional)
  clearDamageCaseItems(): void {
    this.caseItemsSubject.next([]);
  }

  fetchStreamedItems(): void {
    this.clearDamageCaseItems();

    const eventSource: EventSource = new EventSourcePolyfill(`${this.dcurlStream}`, {
        headers: {
            'Authorization': `Bearer ${this.authenticationService.token}`
        },
    });

    eventSource.onmessage = (event) => {
        const item: DamageCase = JSON.parse(event.data);      
        // console.log("Event: ", item);
        // console.log("Length: ", this.caseItemsSubject.value.length);
        this.addDamageCaseItem(item);
    };

    eventSource.onerror = (error) => {
        // Check if the error is due to a stream closure (for example, 204, 410, or a manual server close)
        if (eventSource.readyState === 0) {
          console.log('Stream closed');
          // Optionally, you can trigger UI changes here (e.g., stop loader or notify the user)
        }
        eventSource.close(); // Ensure the stream is closed in case of error or closure
    };
  }

  fetchStreamedSearchItems(criteria: SearchCriteria): void {
    if (criteria.caseTypes.length === 0) {
        return;
    }
    
    // Serialize the search criteria into query parameters
    const queryParams = new URLSearchParams({
        fromDate: criteria.fromDate?.toString() || '',
        toDate: criteria.toDate?.toString() || '',
        caseTypes: criteria.caseTypes?.join(',') || '',
    }).toString(); 

    const eventSource: EventSource = new EventSourcePolyfill(`${this.dcurlSearchStream}?${queryParams}`, {
        headers: {
            'Authorization': `Bearer ${this.authenticationService.token}`
        },
    });

    eventSource.onmessage = (event) => {
        const item: DamageCase = JSON.parse(event.data);      
        // console.log("Event: ", item);
        // console.log("Length: ", this.caseItemsSubject.value.length);
        this.addDamageCaseItem(item);
    };

    eventSource.addEventListener('complete', (event) => {
        console.log('Stream complete:', event.data);

        if (event.data == "No data found") {
            console.log("clear");
            this.clearDamageCaseItems();
        }

        eventSource.close(); // Close the connection when the stream is done
    });

    eventSource.onerror = (error) => {
        // Check if the error is due to a stream closure (for example, 204, 410, or a manual server close)
        if (eventSource.readyState === 0) {
          console.log('Stream closed');
          // Optionally, you can trigger UI changes here (e.g., stop loader or notify the user)
        }
        eventSource.close(); // Ensure the stream is closed in case of error or closure
    };
  }

//   getDamageCases(): Observable<DamageCase[]> {
//       return this.getJson<DamageCase[]>(this.dcurl);
//   }

  search(criteria: SearchCriteria): Observable<DamageCase[]> {
    return this.postJson<DamageCase[]>(this.baseurl + "search", criteria);
  }

  counts(): Observable<DamageCaseCounts> {
      return this.getJson<DamageCaseCounts>(this.baseurl + "counts");
    }

  create(damageCase: DamageCase): Observable<DamageCase> {
      return this.postJson(this.createurl, damageCase);
  }

  /**
   * Load details for the given DamageCase, including all related data
   * @param id
   */
  details(id: number): Observable<DamageCase> {
      return this.getJson<DamageCase>(this.baseurl + "details/" + id);
  }

  update(damageCase: DamageCase): Observable<DamageCase> {
      return this.postJson(this.dcbaseurl + 'update', damageCase);
  }

  delete(damageCase: DamageCase): Observable<void | ApiError[]> {
    return this.deleteJson(this.dcbaseurl + "delete/" + damageCase.id);
  }

  addVehicle(damageCase: DamageCase, vehicle: Vehicle): Observable<Vehicle> {
      return this.postJson(this.vehicleUrl + "add", { damageCaseId: damageCase.id, vehicleId: vehicle.id });
  }

  removeVehicle(damageCase: DamageCase, vehicle: Vehicle): Observable<Vehicle> {
      return this.postJson(this.vehicleUrl + "remove", { damageCaseId: damageCase.id, vehicleId: vehicle.id });
  }

  addEmployee(damageCase: DamageCase, employee: Employee): Observable<Employee> {
      return this.postJson(this.employeeUrl + "add", { damageCaseId: damageCase.id, employeeId: employee.id });
  }

  removeEmployee(damageCase: DamageCase, employee: Employee): Observable<Employee> {
      return this.postJson(this.employeeUrl + "remove", { damageCaseId: damageCase.id, employeeId: employee.id });
  }

  addNote(note: DamageCaseNote): Observable<DamageCaseNote> {
      return this.postJson<DamageCaseNote>(this.noteurl + "create", note);
  }

  updateNote(note: DamageCaseNote): Observable<DamageCaseNote> {
      return this.postJson<DamageCaseNote>(this.noteurl + "update", note);
  }

  deleteNote(note: DamageCaseNote): Observable<DamageCaseNote> {
      return this.postJson<DamageCaseNote>(this.noteurl + "delete", note);
  }

  addAppointment(appointment: DamageCaseAppointment): Observable<DamageCaseAppointment> {
      return this.postJson<DamageCaseAppointment>(`${this.baseurl}${appointment.damageCaseId}/appointments/createappointment`, appointment);
  }

  updateAppointment(appointment: DamageCaseAppointment): Observable<DamageCaseAppointment> {
      return this.patchJson<DamageCaseAppointment>(`${this.baseurl}${appointment.damageCaseId}/appointments`, appointment);
  }

  deleteAppointment(appointment: DamageCaseAppointment): Observable<DamageCaseAppointment> {
      return this.deleteJson<DamageCaseAppointment>(`${this.baseurl}${appointment.damageCaseId}/appointments/delete/${appointment.id}`);
  }

  checkCanClose(dc: DamageCase): Observable<boolean> {
      return this.getJson<boolean>(this.dcbaseurl + "checkcanclose/" + dc.id)
  }

  updateRating(rating: CustomerSatisfactionUpdate): Observable<boolean> {
      return this.postJson<boolean>(this.dcbaseurl + "customer/updatesatisfaction", rating);
  }

  getRatings(dateFrom: Date, dateTo: Date): Observable<CustomerSatisfactionAvg[]> {
      return this.postJson<CustomerSatisfactionAvg[]>(this.dcbaseurl + "customer/avgsatisfaction", { dateFrom: dateFrom, dateTo: dateTo });
  }

  checkin(dc: DamageCase): Observable<DamageCase> {
      return this.postJson<DamageCase>(this.dcbaseurl + "checkin/" + dc.id, null);
  }
}
