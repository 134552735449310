import { MeasuringPointLocation } from "../../enums/measurement/measuring-point-location";
import { ListItem, ListItemState } from "../../interfaces/list-item";
import { MeasurementValue } from "./measurement-value";

export class MeasuringPoint  implements ListItem {
    id!: number;
    measurementLogId!: number;
    name!: string;
    location!: MeasuringPointLocation;
    hollowName!: string;
    values!: MeasurementValue[];
    state!: ListItemState;
}
