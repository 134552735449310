import { Component, ComponentRef, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { VehiclePreviewPopupComponent } from '../vehicle-preview-popup/vehicle-preview-popup.component';
import { Vehicle } from '../../../interfaces/vehicles/vehicle';
import { VehicleService } from '../../../services/vehicles/vehicle.service';
import { VehiclePreviewPopupService } from '../../../services/vehicles/vehicle-preview-popup.service';
import { DomService } from '../../../services/ui/dom.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vehicle-preview',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './vehicle-preview.component.html',
  styleUrl: './vehicle-preview.component.scss'
})
export class VehiclePreviewComponent implements OnInit, OnChanges {
  private popupRef!: ComponentRef<VehiclePreviewPopupComponent>;

  @Input('vehicle') vehicle!: Vehicle;

  isOpen!: boolean;
  timeout!: number | null;

  constructor(
      private vehicleService: VehicleService,
      private popupService: VehiclePreviewPopupService,
      private domService: DomService,
      public el: ElementRef) {
  }

  ngOnInit(): void {
      this.refreshDisplay();
  }

  ngOnChanges(changes: SimpleChanges): void {
      for (let c in changes) {
          if (c === "vehicle") {
              this.refreshDisplay();
          }
      }
  }

  private refreshDisplay() {
  }

  mouseenter() {
    const timeoutId: any = setTimeout(() => {
      // Your existing logic here
      if (this.vehicle) {
          if (this.vehicle.employees && this.vehicle.employees.length > 0) {
              //We have already loaded employees, so just show the popup
              this.popupService.showPopup(this.el, this.vehicle);
          } else {
            if (this.vehicle.id !== undefined) {
              this.vehicleService.getDetails(this.vehicle.id)
                .subscribe(r => {
                  this.vehicle.employees = r.employees;
                  this.popupService.showPopup(this.el, this.vehicle);
                });
              }
          }
      }
    }, 100);

    this.timeout = timeoutId;
  }

  mouseleave() {
      if (this.timeout) {
          clearTimeout(this.timeout);
      }
      this.popupService.hidePopup();
  }
}
