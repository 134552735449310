import { Company } from "../interfaces/company";
import { DamageCaseAppointment } from "../interfaces/damage-case-appointment";
import { Vehicle } from "../interfaces/vehicles/vehicle";
import { Address } from "./address";
import { Attachment } from "./attachment";
import { Builder } from "./builder";
import { Customer } from "./customer";
import { DamageCaseNote } from "./damage-case-note";
import { DamageCaseStatus } from "./damagecase-status";
import { Employee } from "./employee";
import { HouseholdEffect } from "./household-effect";
import { MeasurementLog } from "./measurement/measurement-log";
import { WorkJournalItem } from "./work-journal-item";

export class DamageCase {
    id!: number;
    caseNumber!: string;
    queueNumber!: number;
    company!: Company | null;
    damageAddress!: Address;
    insurancePolicyNumber!: string;
    damageTypeName!: string;
    damageTypeId!: string;
    employeeId!: string;
    employeeName!: string;
    customer!: Customer;
    customerId!: string;
    insuranceCompanyName!: string;
    damageStatus!: DamageCaseStatus;
    damageStatusName!: string;
    damageStatusId!: number;
    damageDate!: Date;
    shortDescription!: string;
    notes!: DamageCaseNote[];
    employees!: Employee[];
    attachments!: Attachment[];
    measurementLogs!: MeasurementLog[];
    builders!: Builder[];
    workJournalItems!: WorkJournalItem[];
    householdEffects!: HouseholdEffect[];
    customerSatisfactionRating!: number;
    customerSatisfactionDate!: Date;
    workAddress!: Address;
    nextAppointment!: DamageCaseAppointment;

    requestedByName!: string;
    requestedByAddress!: Address;

    hasNotes!: boolean;
    hasReport!: boolean;
    hasAppointments!: boolean;
    canClose!: boolean;

    checkinEmployee!: string;
    checkinDate!: Date;
    reopenDate!: Date;
    appointments!: DamageCaseAppointment[];

    equipmentLogMaterials!: string;
    vehicles!: Vehicle[];
}
