import { Component, OnInit } from '@angular/core';
import { Customer } from '../../../models/customer';
import { UserService } from '../../../services/user.service';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Address } from '../../../models/address';

@Component({
  selector: 'app-add-customer',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './add-customer.component.html',
  styleUrl: './add-customer.component.scss'
})
export class AddCustomerComponent implements OnInit {
  public customer: Customer =  new Customer();

  constructor(private userService: UserService,
      public authService: AuthenticationService,
      private router: Router,
      private route: ActivatedRoute) {
  }
    
    ngOnInit(): void {
        this.customer = {
        userName: '',
        password: '',
        fullName: '',
        id: '',
        address: new Address(),
        phoneNumber: '',
        cellphoneNumber: '',
        email: '',
        customerIsIndustry: false,
        customerCompanyName: '',
        companyId: 0,
        secondEmail: ''
        };
        
    }
    
    onSubmitUpdateCustomer() {
        console.log("Customer: ", this.customer);
        this.userService.create(this.customer)
            .subscribe(dc => {
                this.router.navigate(["/customers"]);
            });
  }


  onCancel() {
    this.router.navigate(['/customers']);
  }

  goToList() {
      this.router.navigate(["/customers"]);
  }
}
